import { useState } from 'react'
import { Button, Spinner } from 'reactstrap'

const CustomButton = ({ onClick, children, disabled, ...props }) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = async (e) => {
    if (isClicked) {
      return
    }

    setIsClicked(true)

    try {
      await onClick?.(e)
    } finally {
      setIsClicked(false)
    }
  }

  return (
    <Button {...props} onClick={handleClick} disabled={isClicked || disabled}>
      {isClicked ? <Spinner /> : children}
    </Button>
  )
}

export default CustomButton
