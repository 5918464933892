import { createApiSlice } from 'app/createApiSlice'

export const settingApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'settings',
  endpoints(builder) {
    return {
      fetchSettings: builder.query({
        query() {
          return `/api/v1/bussines/profile`
        },
      }),
      fetchTeamList: builder.query({
        query(payload) {
          let url = `/api/v1/bussines/team`

          return {
            url,
            params: {
              page: payload.page,
              size: 10,
              sortBy: payload.sortBy,
              sortDir: payload.sortDir,
              pmbSearch: payload.search,
            },
          }
        },
      }),
      uploadImagePublic: builder.mutation({
        query(img) {
          const image = new FormData()
          image.append('file', img)
          return {
            url: `/api/v1/upload/publicfile`,
            method: 'POST',
            body: image,
          }
        },
      }),
      updateAvatarUser: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/profile`,
            method: 'PATCH',
            body: payload,
          }
        },
      }),
      getUserAvatar: builder.query({
        query(userIds) {
          return {
            url: `/api/v1/bussines/profilepics`,
            method: 'POST',
            body: {
              userIds: [...userIds],
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchSettingsQuery,
  useFetchTeamListQuery,
  useUploadImagePublicMutation,
  useGetUserAvatarQuery,
  useUpdateAvatarUserMutation,
} = settingApiSlice
