import CustomButton from 'components/Buttons/CustomButton'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Modal, ModalBody } from 'reactstrap'
import { usePostInstantWithdrawMutation } from './withdrawApiSlice'
import { setIsProcess, setProcessMsg } from './withdrawSlice'

const ModalConfirmation = ({ isOpen, toggle }) => {
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const isProcess = (e) => dispatch(setIsProcess(e))
  const setMsg = (e) => dispatch(setProcessMsg(e))
  const { selectedPricing } = useSelector((e) => e.withdraw)

  const [postInstantWithdraw] = usePostInstantWithdrawMutation()

  const handleSubmit = async () => {
    try {
      const obj = {
        disbursementpricingId: selectedPricing,
        wallet3: true,
      }
      const res = await postInstantWithdraw(obj).unwrap()

      if (res?.status === 'OPEN') {
        isProcess(true)
        setMsg('Penarikan dalam proses')
        window.location.href = '/'
        toggle()
      }
      if (res?.status === 'Menunggu Approval Admin') {
        isProcess(true)
        setMsg(res?.status)
        window.location.href = '/'
        toggle()
      }
    } catch (error) {
      setErrMsg(error?.data?.message)
    }
  }

  return (
    <>
      <Modal
        className="p-5"
        isOpen={isOpen}
        toggle={() => {
          toggle()
          setErrMsg('')
        }}
        centered
      >
        <ModalBody className="p-4">
          <div>
            <h5 className="fw-bold">Konfirmasi Penarikan</h5>
            <p>
              Penarikan instan yang sudah kamu ajukan tidak bisa dibatalkan.
              Apakah kamu yakin untuk melanjutkan pengajuan penarikan?
            </p>
          </div>
          {errMsg && (
            <FormGroup>
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            </FormGroup>
          )}
          <div className="d-flex flex-row gap-3">
            <CustomButton
              onClick={() => {
                toggle()
                setErrMsg('')
              }}
              block
              outline
              color="primary"
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleSubmit()
              }}
              block
              color="primary"
              disabled={postInstantWithdraw?.isLoading}
            >
              Lanjut
            </CustomButton>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalConfirmation
