import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  isOpenBanner: false,
  details: null,
  detailVessel: {},
  isOpenModalPemetaan: false,
  isSuccess: false,
  alertMessage: null,
  alertType: 'success',
}

export const vesselsListSlice = createAppSlice({
  name: 'vesselsList',
  initialState,
  reducers: (create) => ({
    setDetailVessel: create.reducer((state, { payload }) => {
      state.detailVessel = payload
    }),
    setIsSuccess: create.reducer((state, { payload }) => {
      state.isSuccess = !state.isSuccess
    }),
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleModalPemetaan: create.reducer((state, { payload }) => {
      state.isOpenModalPemetaan = !state.isOpenModalPemetaan
    }),
    setAlert: create.reducer((state, { payload }) => {
      state.alertMessage = payload.message
      state.alertType = payload.type
    }),
    clearAlert: create.reducer((state) => {
      state.alertMessage = null
      state.alertType = 'success'
    }),
  }),
})

export const {
  setIsSuccess,
  setDetailVessel,
  toggleSidebarWithdraw,
  setFetchOption,
  toggleBanner,
  toggleModalPemetaan,
  setAlert,
  clearAlert,
} = vesselsListSlice.actions

export default vesselsListSlice.reducer
