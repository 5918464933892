import { Search } from 'react-feather'
import { Input, InputGroup, InputGroupText } from 'reactstrap'

const PMBSearchInputGroup = ({
  placeholder = 'Search...',
  value,
  onChange,
  maxWidth = '200px',
  isInvalid = false,
  iconSize = 16,
  label,
  ...props
}) => {
  const inputStyle = {
    borderColor: isInvalid ? '#dc3545' : '#d1d5db',
    boxShadow: 'none',
    '&:hover': {
      borderColor: isInvalid ? '#dc3545' : '#d1d5db',
    },
    borderRadius: '0 8px 8px 0',
    height: '42px',
    maxWidth: maxWidth,
  }

  const inputGroupTextStyle = {
    backgroundColor: 'white',
    borderRightColor: 'white',
    borderRadius: '8px 0 0 8px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <div>
      {/* Conditionally render the label */}
      {label && <label className="d-block mb-1">{label}</label>}
      <InputGroup style={{ borderColor: '#D0D5DD', ...props.style }}>
        <InputGroupText style={inputGroupTextStyle}>
          <Search color="#667085" size={iconSize} />
        </InputGroupText>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={inputStyle}
          {...props}
        />
      </InputGroup>
    </div>
  )
}

export default PMBSearchInputGroup
