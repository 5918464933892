import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import { toggleModal } from './staffListSlice'
import { getRolesFarmers, getRolesPMB } from '../farmers-list/farmersListAction'
import {
  useSetRoleFarmersMutation,
  useSetRolePMBMutation,
} from '../farmers-list/farmersListApiSlice'

const ModalChangeRole = ({ row, isSubGroup, handleFinishedChangeRole }) => {
  const dispatch = useDispatch()
  const { isOpenModal } = useSelector((s) => s.staffList)
  const { rolesFarmers, rolesPMB } = useSelector((s) => s.farmersList)
  const [role, setRole] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [changeRolePMB, resultPMB] = useSetRolePMBMutation()
  const [changeRoleFarmers, result] = useSetRoleFarmersMutation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const data = isSubGroup
        ? await changeRoleFarmers({
            userId: row?.userId,
            role: role?.id,
          })
        : await changeRolePMB({ userId: row?.userId, role: role?.id })
      if (data?.error) {
        throw data
      } else {
        handleFinishedChangeRole()
        dispatch(toggleModal())
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.meta?.message ?? error?.message)
    }
  }

  useEffect(() => {
    if (rolesFarmers.length < 1 && isSubGroup && isOpenModal) {
      dispatch(getRolesFarmers())
    } else if (rolesPMB.length < 1 && isOpenModal) {
      dispatch(getRolesPMB())
    }
  }, [rolesFarmers, rolesPMB, isSubGroup, isOpenModal])

  useEffect(() => {
    if (isOpenModal) {
      if (isSubGroup && row?.roleName && rolesFarmers.length > 0) {
        setRole(rolesFarmers.find((e) => e.label === row?.roleName))
      } else if (row?.rolesName && rolesPMB.length > 0) {
        setRole(rolesPMB.find((e) => e.label === row?.rolesName))
      }
    }
    if (!isOpenModal) {
      setRole(null)
    }
  }, [row, isOpenModal, isSubGroup, rolesFarmers, rolesPMB])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal())
        setErrMsg('')
      }}
    >
      <ModalBody>
        <h5>Ubah peran</h5>
        <p>Pilih peran untuk {row?.name ?? row?.fullName ?? 'kmdlasmd'}</p>
        <FormGroup>
          <Label>Peran</Label>
          <Select
            name="role"
            placeholder="Pilih peran"
            options={isSubGroup ? rolesFarmers : rolesPMB}
            onChange={(e) => {
              setRole(e)
              setErrMsg('')
            }}
            value={role ?? ''}
          />
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-2">
          <Button
            onClick={() => {
              dispatch(toggleModal())
              setErrMsg('')
            }}
            disabled={result?.isLoading || resultPMB?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={
              result?.isLoading ||
              resultPMB?.isLoading ||
              !role ||
              role?.label === row?.rolesName ||
              role?.label === row?.roleName
            }
            onClick={handleSubmit}
            color="primary"
            block
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalChangeRole
