import { createApiSlice } from 'app/createApiSlice'
import { API_COMPANIES } from 'utils/apiList'

export const formCompaniesApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'formCompanies/api',
  endpoints(builder) {
    return {
      creationCompanies: builder.mutation({
        query(payload) {
          return {
            url: API_COMPANIES,
            method: 'POST',
            body: payload,
          }
        },
      }),
      updateCompanies: builder.mutation({
        query({ payload, id }) {
          return {
            url: `${API_COMPANIES}/${id}`,
            method: 'POST',
            body: payload,
          }
        },
      }),
    }
  },
})

export const {
  useCreationCompaniesMutation,
  useUpdateCompaniesMutation,
  useCreationCompaniesUsersMutation,
} = formCompaniesApiSlice
