import CustomButton from 'components/Buttons/CustomButton'
import { useIntl } from 'react-intl'
import { Container } from 'reactstrap'

const FooterRollover = ({
  text,
  handleClick,
  isDisable = false,
  buttonOnly = false,
  total,
}) => {
  const intl = useIntl()
  return (
    <div>
      <div className="bg-white border-top border-2 p-3">
        <Container className="custom-container">
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ visibility: buttonOnly ? 'hidden' : '' }}>
              <span>Total pembayaran: </span>
              <span className="fw-bold">
                Rp
                {intl.formatNumber(isNaN(total) ? 0 : total, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </div>
            <CustomButton
              disabled={isDisable}
              onClick={handleClick}
              color="primary"
            >
              {text ?? 'Lanjutkan'}
            </CustomButton>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default FooterRollover
