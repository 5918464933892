import { createApiSlice } from 'app/createApiSlice'
import { API_USERS_TIM_LIST, API_WHISTLE_BLOWINGS } from 'utils/apiList'

export const whistleBlowingsListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'whistleBlowingsList/api',
  endpoints(builder) {
    return {
      fetchWhistleBlowings: builder.query({
        query({
          page,
          size = 10,
          searchvalue,
          assigneduserid,
          sortby = '',
          sortdir = '',
        }) {
          return {
            url: API_WHISTLE_BLOWINGS,
            params: {
              page,
              size,
              searchvalue,
              assigneduserid,
              sortby,
              sortdir,
            },
          }
        },
      }),
      fetchUserTimsBlowings: builder.query({
        query({ page, status, size = 10, groupCode }) {
          return {
            url: API_USERS_TIM_LIST,
            params: {
              groupCode,
              page,
              status,
              size,
            },
          }
        },
      }),
      updateWhistleBlowingStatus: builder.mutation({
        query({ id, statusType, notes, assignedUserId }) {
          let body = {}

          switch (statusType) {
            case 'ASSIGNED':
              body = {
                assignedUserId,
                status: 'ASSIGNED',
                assignNotes: notes,
              }
              break
            case 'RESOLVED':
              body = {
                status: 'RESOLVED',
                resolveNotes: notes,
              }
              break
            case 'UNRESOLVED':
              body = {
                status: 'UNRESOLVED',
                unresolveNotes: notes,
                assignedUserId,
              }
              break
            default:
              throw new Error('Invalid statusType')
          }

          return {
            url: `${API_WHISTLE_BLOWINGS}/${id}`,
            method: 'POST',
            body,
          }
        },
      }),
    }
  },
})

export const {
  useFetchWhistleBlowingsQuery,
  useLazyFetchWhistleBlowingsQuery,
  useFetchUserTimsBlowingsQuery,
  useUpdateWhistleBlowingStatusMutation,
} = whistleBlowingsListApiSlice
