import ICThunder from 'assets/icons/thunder-icon.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { toggleModal } from '../purchaseCommoditySlice'

function ModalInfo() {
  const { isOpenModal } = useSelector((s) => s.purchaseCommmodity)
  const dispatch = useDispatch()
  const toggle = () => dispatch(toggleModal())

  const navigate = useNavigate()

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div className="text-center">
          <img src={ICThunder} alt="icon" width={48} height={48} />
          <h5 className="fw-bold text-md">Transaksi berhasil dibuat</h5>
          <p className="text-sm">
            Sebelum melanjutkan pembayaran, transaksi harus dikonfirmasi oleh
            admin terlebih dahulu.
          </p>
        </div>
        <CustomButton
          color="primary"
          onClick={() => {
            toggle()
            navigate('/')
          }}
          block
        >
          <span className="text-normal">Kembali Ke Beranda</span>
        </CustomButton>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfo
