import { createApiSlice } from 'app/createApiSlice'
import { API_FISHERIES } from 'utils/apiList'

export const fisheriesListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'fisheriesList/api',
  endpoints(builder) {
    return {
      fetchFisheries: builder.query({
        query({ page, size = 10, searchvalue, sortby = '', sortdir = '' }) {
          return {
            url: API_FISHERIES,
            params: {
              page,
              size,
              searchvalue,
              sortby,
              sortdir,
            },
          }
        },
      }),
      deleteFisheryById: builder.mutation({
        query({ id }) {
          return {
            url: `${API_FISHERIES}/${id}`,
            method: 'DELETE',
          }
        },
      }),
    }
  },
})

export const {
  useFetchFisheriesQuery,
  useLazyFetchFisheriesQuery,
  useDeleteFisheryByIdMutation,
} = fisheriesListApiSlice
