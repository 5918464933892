import CustomButton from 'components/Buttons/CustomButton'
import { useDispatch } from 'react-redux'
import { confirmTrx } from '../requestTransactionAction'

const ButtonAction = ({ amount, code }) => {
  const dispatch = useDispatch()
  return (
    <div className="d-flex align-items-center gap-2 mb-4">
      <CustomButton
        style={{ width: 220 }}
        color="secondary"
        outline
        onClick={() => dispatch(confirmTrx({ amount, code, status: 'CANCEL' }))}
      >
        Tolak
      </CustomButton>
      <CustomButton
        style={{ width: 220 }}
        color="primary"
        onClick={() =>
          dispatch(confirmTrx({ amount, code, status: 'CONFIRM' }))
        }
      >
        Terima dan Bayar
      </CustomButton>
    </div>
  )
}

export default ButtonAction
