import logoCancel from 'assets/icons/topup-cancel.svg'
import LogoPasmik from 'assets/icons/PasarMIKRO-Primary-Logogram-3.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Failed = () => {
  const location = useLocation()
  const dataState = location.state
  const [countdown, setCountdown] = useState(10)
  const navigate = useNavigate()

  useEffect(() => {
    if (dataState?.purpose === 'PAYMENT') {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [dataState])

  useEffect(() => {
    if (countdown === 0) {
      navigate(`/public-payment?token=${dataState?.token}`, {
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown])

  return (
    <div className="flex-centered flex-column" style={{ minHeight: '100vh' }}>
      <div className="flex-centered py-3">
        {' '}
        <img
          style={{ width: '100%', maxWidth: '80px', height: 'auto' }}
          src={LogoPasmik}
          alt="PasarMIKRO logo"
        />{' '}
      </div>
      <div className="flex-centered py-3">
        {' '}
        <img
          style={{ width: '100%', maxWidth: '120px', height: 'auto' }}
          src={logoCancel}
          alt="PasarMIKRO logo"
        />{' '}
      </div>
      <div className="flex-centered flex-column gap-2  py-3">
        <h3 className="fw-600 text-center">Pembayaran Anda telah dibatalkan</h3>
        {dataState?.purpose === 'PAYMENT' ? (
          ` Anda akan diarahkan untuk memilih pembayaran kembali dalam ${countdown}`
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Failed
