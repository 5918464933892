import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiEndpoint } from 'configs'

export const loginNewApiSlice = createApi({
  reducerPath: 'login-new/api',
  baseQuery: fetchBaseQuery({ baseUrl: apiEndpoint }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    loginUserNew: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/public/authentication/login`,
        method: 'POST',
        body: payload,
      }),
    }),
    precheckLogin: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/public/authentication/check`,
        method: 'POST',
        body: payload,
      }),
    }),
    checkDevice: builder.query({
      query: ({ deviceHash }) => ({
        url: `/api/v1/public/device/check`,
        method: 'GET',
        params: { deviceHash },
      }),
    }),
    createDevice: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/public/device`,
        method: 'POST',
        body: payload,
      }),
    }),
    sendOtpLogin: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/public/authentication/otp/send`,
        method: 'POST',
        body: payload,
      }),
    }),
    verifyOtpLogin: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/public/authentication/otp/verify`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})

export const {
  useLoginUserNewMutation,
  usePrecheckLoginMutation,
  useCreateDeviceMutation,
  useSendOtpLoginMutation,
  useVerifyOtpLoginMutation,
  useLazyCheckDeviceQuery,
} = loginNewApiSlice
