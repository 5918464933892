import { Navigate } from 'react-router-dom'
import HeaderTransaction from 'components/HeaderTransaction'
import UserInfo from './UserInfo'
import ItemInfo from './ItemInfo'
import NoteInfo from './NoteInfo'
import TotalAmountInfo from './TotalAmountInfo'
import { Container } from 'reactstrap'

import './style.scss'
import ModalInfo from './ModalInfo'
import { useFetchSettingsQuery } from '../settings/settingApiSlice'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  CREATE,
  MAKE,
} from 'utils/subjectActions'
import { URL_406 } from 'utils/pathUrl'
import BreadCrumbs from 'components/breadcrumbs'

const PurchaseCommodity = () => {
  const { isLoading, isSuccess, data } = useFetchSettingsQuery()

  const createPurchase = useAbilityAccess({
    action: CREATE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })
  const makePurchase = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  if (makePurchase || createPurchase) {
    return (
      <div className="w-100">
        <Container className="custom-container">
          <div className="d-flex align-items-start gap-2 pt-3">
            <BreadCrumbs breadCrumbActive={'Pembelian'} />
          </div>
          <HeaderTransaction />
          <div className="my-3"></div>
          <UserInfo />
          <div className="my-3"></div>
          <ItemInfo />
          <div className="my-3"></div>
          <NoteInfo />
          <div className="my-3 mb-5" style={{ height: 15 }}></div>
        </Container>
        <TotalAmountInfo
          isLoading={isLoading}
          isSuccess={isSuccess}
          data={data}
        />
        <ModalInfo />
      </div>
    )
  }

  return <Navigate to={URL_406} replace={true} />
}

export default PurchaseCommodity
