import ICWarning from 'assets/icons/warning-icon.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import { useDeleteRelationMutation } from './payableNotesApiSlice'

const ModalDeleteContact = ({ row, isOpen, toggle, handleDelete }) => {
  const [errMsg, setErrMsg] = useState('')
  const [deleteRelation, result] = useDeleteRelationMutation()

  const handleSubmit = async () => {
    try {
      const body = {
        id: row?.id,
      }
      const resp = await deleteRelation(body).unwrap()
      if (!resp?.data?.error) {
        toggle()
        handleDelete()
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal centered toggle={toggle} isOpen={isOpen}>
      <ModalBody>
        <img src={ICWarning} alt="Warning Icon" />
        <h6 className="mt-4 fw-600">Hapus dari daftar tersimpan?</h6>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <CustomButton
            onClick={toggle}
            disabled={result?.isLoading}
            block
            outline
            color="secondary"
          >
            Batal
          </CustomButton>
          <CustomButton
            onClick={handleSubmit}
            disabled={result?.isLoading || !row}
            block
            color="danger"
          >
            Hapus
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalDeleteContact
