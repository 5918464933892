import { createBrowserRouter } from 'react-router-dom'
import App from '../App'

// Public Router

import OrderInfoView from 'features/public-views/order'
import SuccessVerifyAccount from '../public-views/success-verify-account'
import TncView from '../public-views/tnc'

// Setting Pages
import PageForbidden from 'components/PageForbidden'
import RequestTransaction from 'features/private-views/request-transaction'

import DetailLearning from 'features/private-views/learning/DetailLearning'
import FinishPage from 'features/private-views/learning/FinishPage'
import LandPlotView from 'features/public-views/plot'
import Spatial from 'features/public-views/spatial'
import { URL_FINISH_KURSUS, URL_KURSUS_DETAIL } from 'utils/pathUrl'
import { authRouter } from './authRouter'
import { errorRouter } from './errorRouter'
import { privateRouter } from './privateRouter'
import { publicRouter } from './publicRouter'

export const routes = createBrowserRouter([
  {
    element: <App />,
    children: [...privateRouter],
    errorElement: <PageForbidden />,
  },
  {
    path: '/request-transaction',
    element: <RequestTransaction />,
  },
  {
    path: '/syarat-dan-ketentuan',
    exact: true,
    element: <TncView />,
  },
  {
    path: '/sukses-verifikasi',
    exact: true,
    element: <SuccessVerifyAccount />,
  },
  {
    path: '/public/order',
    element: <OrderInfoView />,
  },
  {
    path: '/plot/view/:code',
    element: <LandPlotView />,
  },
  {
    path: '/plot/view/pin/:lat/:long',
    element: <LandPlotView />,
  },
  {
    path: '/spatial',
    element: <Spatial />,
  },
  {
    path: URL_KURSUS_DETAIL,
    element: <DetailLearning />,
  },
  {
    path: URL_FINISH_KURSUS,
    element: <FinishPage />,
  },
  ...publicRouter,
  ...errorRouter,
  ...authRouter,
])
