import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardTitle, Container } from 'reactstrap'
import { URL_LOGIN } from 'utils/pathUrl'

const StepFourForgetPassword = () => {
  const nav = useNavigate()
  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: '600px', width: '100%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Atur ulang password berhasil!
            </CardTitle>
            <CardTitle tag="p">
              Password Anda berhasil diatur ulang. Silakan login untuk masuk ke
              akun Anda.
            </CardTitle>

            <CustomButton
              className="my-1 mb-2"
              block
              color="primary"
              type="button"
              onClick={() => nav(URL_LOGIN, { replace: true })}
            >
              Ke Halaman Login
            </CustomButton>
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default StepFourForgetPassword
