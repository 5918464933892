import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'
import { API_UPLOAD_IMAGE_PUBLIC } from 'utils/apiList'

const uploadImage = createAsyncThunk(
  'formVessels/upload',
  async ({ img, fileType }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const image = new FormData()
      image.append('file', img)
      const { data } = await axios.post(
        `${apiEndpoint}/${API_UPLOAD_IMAGE_PUBLIC}`,
        { file: img, fileType },
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { uploadImage }
