import { Card, CardBody, CardText, Col, Form, Row } from 'reactstrap'

import './style.scss'
import { X } from 'react-feather'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { uploadFile } from '../sellCommodityAction'
import { setRemoveDoc } from '../sellCommoditySlice'

const InfoDocument = () => {
  const [fileNames, setFileNames] = useState([])
  const dispatch = useDispatch()

  return (
    <Card body>
      <Row>
        <Col>
          <Card className="border-0">
            <CardBody>
              <CardText tag="h5">Dokumen tambahan</CardText>
              <Form
                encType="multipart/form-data"
                className="flex-centered border rounded"
                style={{ overflow: 'hidden' }}
              >
                <fieldset className="fieldset">
                  <input
                    className="input-file"
                    id="transactions"
                    name="transactions"
                    onChange={(e) => {
                      let name = []
                      for (let i = 0; i < e.target.files.length; i++) {
                        name.push({
                          id: i,
                          fileName: e.target.files[i].name,
                        })
                        dispatch(uploadFile(e.target.files[i]))
                      }
                      setFileNames(name)
                    }}
                    type="file"
                    accept=".png, .jpeg, .jpg, .pdf, .xlsx, .xls, .doc, .docx"
                    multiple
                  />
                  <label htmlFor="transactions">Mengunggah</label>
                </fieldset>
                <div className="w-100" style={{ padding: '10px 14px' }}>
                  {fileNames?.length > 0
                    ? fileNames?.map((e, i) => (
                        <span className="ms-3 badge-line" key={i}>
                          {e.fileName}
                          <X
                            size={16}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let data = fileNames.filter(
                                (e) => e !== fileNames[i],
                              )
                              setFileNames(data)
                              dispatch(setRemoveDoc(fileNames[i]))
                            }}
                          />
                        </span>
                      ))
                    : 'Pilih Dokumen'}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

export default InfoDocument
