import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { CheckCircle, ChevronDown, Menu } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert, Card, CardBody, CardFooter, Spinner } from 'reactstrap'
import { useFetchStaffListQuery } from '../farmers-list/farmersListApiSlice'
import { clearState } from '../forms/formSlice'
import ModalChangeRole from './ModalChangeRole'
import { setFetchOption, toggleBanner, toggleModal } from './staffListSlice'
import IPhoto from 'assets/icons/Avatar.svg'
import { Can } from 'context/Acl'
import {
  BUSINESS_COMPANY,
  BUSINESS_FARMERS,
  MANAGE,
} from 'utils/subjectActions'
import { toggleSidebarFarmer } from '../groups-list/groupsListSlice'

const StaffList = () => {
  const { tag, isOpenBanner } = useSelector((s) => s.staffList)
  const { ownerData } = useSelector((s) => s.dashboard)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const [selectedRow, setSelectedRow] = useState(null)

  const { data, isLoading, refetch } = useFetchStaffListQuery(
    {
      page: page?.page,
    },
    { skip: !page },
  )
  const columns = [
    {
      name: <span>Nama</span>,
      width: '40%',
      grow: 2,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <>
              <img
                src={row?.sellerOrBuyAvatar ?? IPhoto}
                className="rounded-circle"
                alt="pak tani"
                width={32}
                height={32}
              />
              <span>{row.name}</span>
            </>
          </div>
        )
      },
    },
    {
      name: <span>Email</span>,
      grow: 2,
      cell: (row) => {
        return <span>{row?.email}</span>
      },
    },
    {
      name: <span>Peran</span>,
      grow: 2,
      cell: (row) => {
        return <span>{row?.rolesName}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <Can I={MANAGE} this={BUSINESS_COMPANY}>
            <span
              style={{ cursor: 'pointer' }}
              className="fw-semibold text-center"
              onClick={() => {
                dispatch(toggleModal())
                setSelectedRow(row)
              }}
            >
              Ubah Peran
            </span>
          </Can>
        )
      },
    },
  ]

  const handleFinishedChangeRole = () => {
    dispatch(toggleBanner())
    refetch()
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  useEffect(() => {
    dispatch(clearState())
  }, [])

  return (
    <div className="w-100 px-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <Menu
          onClick={() => dispatch(toggleSidebarFarmer())}
          className="pointer"
        />
        <BreadCrumbs
          breadCrumbActive={
            ownerData?.companyName ?? data?.items[0]?.partnershipName
          }
          breadCrumbParent={'Daftar Anggota'}
        />
      </div>
      <ModalChangeRole
        row={selectedRow}
        handleFinishedChangeRole={handleFinishedChangeRole}
      />
      <Card>
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={<Spinner />}
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada pengguna yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <CardFooter
                  className="border border-top-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-sm">
                      {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                    </div>
                    <div className="d-flex flex-centered gap-1">
                      <ButtonIcon
                        btnTitle="Sebelumnya"
                        btnProps={{
                          disabled: page.page === 0,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page - 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page - 1,
                              }),
                            )
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                      <ButtonIcon
                        btnTitle="Selanjutnya"
                        btnProps={{
                          disabled:
                            page.page ===
                            Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page + 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page + 1,
                              }),
                            )
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                    </div>
                  </div>
                </CardFooter>
              )
            }}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <h5 className="fw-semibold">
                      {ownerData?.companyName ??
                        data?.items[0]?.partnershipName}
                    </h5>
                  )}
                </div>
                <div>
                  <Alert
                    isOpen={isOpenBanner}
                    toggle={() => dispatch(toggleBanner())}
                    color="success"
                    className="d-flex align-items-center mt-4 w-100"
                  >
                    <span>
                      <CheckCircle size={17} /> Peran berhasil diubah.
                    </span>
                  </Alert>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default StaffList
