import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useFetchSettingsQuery } from '../settings/settingApiSlice'
import InfoDocument from './InfoDoc'
import ItemInfo from './InfoItem'
import InfoSeller from './InfoSeller'
import InfoTotalAmount from './InfoTotalAmount'
import ModalInfo from './ModalTrx'
import { Navigate } from 'react-router-dom'
import { URL_406 } from 'utils/pathUrl'
import { BUSINESS_TRANSACTION_SELL, CREATE, MAKE } from 'utils/subjectActions'
import { Card, Container } from 'reactstrap'
import BreadCrumbs from 'components/breadcrumbs'

const SellCommodityView = () => {
  const { isLoading, isSuccess, data } = useFetchSettingsQuery()

  const createSell = useAbilityAccess({
    action: CREATE,
    subject: BUSINESS_TRANSACTION_SELL,
  })
  const makeSell = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_SELL,
  })

  if (createSell || makeSell) {
    return (
      <div className="w-100">
        <Container className="custom-container">
          <div className="d-flex align-items-start gap-2 pt-3">
            <BreadCrumbs breadCrumbActive={'Penjualan'} />
          </div>
          <InfoSeller />
          <div className="my-3"></div>
          <ItemInfo />
          <div className="my-3"></div>
          <InfoDocument />
          <div className="my-3" style={{ height: 60 }}></div>
        </Container>

        <InfoTotalAmount
          isLoading={isLoading}
          isSuccess={isSuccess}
          data={data}
        />
        <ModalInfo />
      </div>
    )
  }
  return <Navigate to={URL_406} replace={true} />
}

export default SellCommodityView
