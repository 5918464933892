import { CheckCircle, XCircle } from 'react-feather'
import { Alert, Label } from 'reactstrap'

const CustomAlertMessage = ({ type = 'success', message }) => {
  if (!message) return null

  const isSuccess = type === 'success'

  return (
    <Alert color={type} className="d-flex align-items-center gap-2">
      {isSuccess ? (
        <CheckCircle size={20} className="mr-2" />
      ) : (
        <XCircle size={20} className="mr-2" />
      )}
      <Label className="m-0">{message}</Label>
    </Alert>
  )
}

export default CustomAlertMessage
