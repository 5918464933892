import {
  ArrowDownCircle,
  ArrowLeftCircle,
  ArrowRightCircle,
} from 'react-feather'
import { Card, CardText, Col, Container, List, Row } from 'reactstrap'

// css
import './style.scss'
import { useSelector } from 'react-redux'
import { tags } from 'utils/tags'

import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  URL_406,
  URL_APPROVAL_BUY,
  URL_APPROVAL_SELL,
  URL_APPROVAL_WITHDRAWAL,
} from 'utils/pathUrl'
import { BUSINESS_MYAPPROVAL, READ } from 'utils/subjectActions'
import { useEffect, useState } from 'react'
import BreadCrumbs from 'components/breadcrumbs'

const ApprovalPage = () => {
  const nav = useNavigate()
  let location = useLocation()
  const access = useAbilityAccess({
    action: READ,
    subject: BUSINESS_MYAPPROVAL,
  })
  const { countData } = useSelector((s) => s.approval)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!access) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <>
      {screenWidth < 800 ? (
        <div className="w-100 px-3">
          <Row>
            <Col md="3">
              <div className="d-flex align-items-start gap-2 pt-3">
                <BreadCrumbs breadCrumbActive={'Persetujuan'} />
              </div>
              <Card className="mb-3">
                <List type="unstyled" className="py-3">
                  <li
                    className={`text-sm menu-categories-approval hover ${
                      location.pathname === `/${URL_APPROVAL_BUY}` && 'active'
                    }`}
                    onClick={() => nav(`/${URL_APPROVAL_BUY}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <ArrowLeftCircle size={16} className="ms-2 me-1" />
                    Pembelian {`(${countData[tags.request_buy] ?? 0})`}
                  </li>

                  <li
                    className={`text-sm menu-categories-approval hover ${
                      location.pathname ===
                        `/${URL_APPROVAL_BUY}/${URL_APPROVAL_SELL}` && 'active'
                    }`}
                    onClick={() =>
                      nav(`/${URL_APPROVAL_BUY}/${URL_APPROVAL_SELL}`)
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <ArrowRightCircle size={16} className="ms-2 me-1" />
                    Penjualan {`(${countData[tags.request_sell] ?? 0})`}
                  </li>

                  <li
                    className={`text-sm menu-categories-approval hover ${
                      location.pathname ===
                        `/${URL_APPROVAL_BUY}/${URL_APPROVAL_WITHDRAWAL}` &&
                      'active'
                    }`}
                    onClick={() =>
                      nav(`/${URL_APPROVAL_BUY}/${URL_APPROVAL_WITHDRAWAL}`)
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <ArrowDownCircle size={16} className="ms-2 me-1" />
                    Penarikan Uang{' '}
                    {`(${countData[tags.request_withdrawal] ?? 0})`}
                  </li>
                </List>
              </Card>
            </Col>
            <Col md="9">
              <Outlet />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="w-100 d-flex">
          <Card
            className="h-100 rounded-0 border border-left-0"
            style={{ marginLeft: 0, width: '220px' }}
          >
            <List type="unstyled" className="py-3">
              <li
                className={`text-sm menu-categories-approval hover ${
                  location.pathname === `/${URL_APPROVAL_BUY}` && 'active'
                }`}
                onClick={() => nav(`/${URL_APPROVAL_BUY}`)}
                style={{ cursor: 'pointer' }}
              >
                <ArrowLeftCircle size={16} className="ms-2 me-1" />
                Pembelian {`(${countData[tags.request_buy] ?? 0})`}
              </li>

              <li
                className={`text-sm menu-categories-approval hover ${
                  location.pathname ===
                    `/${URL_APPROVAL_BUY}/${URL_APPROVAL_SELL}` && 'active'
                }`}
                onClick={() => nav(`/${URL_APPROVAL_BUY}/${URL_APPROVAL_SELL}`)}
                style={{ cursor: 'pointer' }}
              >
                <ArrowRightCircle size={16} className="ms-2 me-1" />
                Penjualan {`(${countData[tags.request_sell] ?? 0})`}
              </li>

              <li
                className={`text-sm menu-categories-approval hover ${
                  location.pathname ===
                    `/${URL_APPROVAL_BUY}/${URL_APPROVAL_WITHDRAWAL}` &&
                  'active'
                }`}
                onClick={() =>
                  nav(`/${URL_APPROVAL_BUY}/${URL_APPROVAL_WITHDRAWAL}`)
                }
                style={{ cursor: 'pointer' }}
              >
                <ArrowDownCircle size={16} className="ms-2 me-1" />
                Penarikan Uang {`(${countData[tags.request_withdrawal] ?? 0})`}
              </li>
            </List>
          </Card>
          <div className="align-items-start px-2 w-100">
            <div className="d-flex align-items-start gap-2 pt-3">
              <BreadCrumbs breadCrumbActive={'Persetujuan'} />
            </div>
            <Outlet />
          </div>
        </div>
      )}
    </>
  )
}

export default ApprovalPage
