import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  payload: {},
  urlImageVessel: '',
  isOpenModalDeleteFile: false,
}

export const formVesselsSlice = createAppSlice({
  name: 'vesselsForm',
  initialState,
  reducers: (create) => ({
    setPayloadSlice: create.reducer((state, { payload }) => {
      state.payload = payload
    }),
    setUrlImageVessel: create.reducer((state, { payload }) => {
      state.urlImageVessel = payload
    }),
    toggleModalUpload: create.reducer((state, { payload }) => {
      state.isOpenModalUpload = !state.isOpenModalUpload
    }),
    clearState: create.reducer((state) => {
      state.payload = {}
      state.urlImageVessel = ''
    }),
  }),
})

export const {
  setPayloadSlice,
  clearState,
  setUrlImageVessel,
  toggleModalUpload,
} = formVesselsSlice.actions

export default formVesselsSlice.reducer
