import NavbarDashboard from 'components/Navbar'
import NavbarSide from 'components/NavbarSide'
import {
  setAlertMsg,
  toggleAlert,
} from 'features/public-views/login/loginSlice'
import { useAuthAccess } from 'hooks/useAuthAccess'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'
import { SnackbarProvider } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isEffectCompleted, setIsEffectCompleted] = useState(false)
  const { token, alreadychangepass, userNotPartnership, isV3 } = useAuthAccess()
  const profile = useGetMyProfileAvaQuery({ skip: !isEffectCompleted })

  const version = process.env.REACT_APP_VERSION
  const versionLocalStorage = localStorage.getItem('PMB_version')

  useEffect(() => {
    const alertStatus = localStorage.getItem('PMB_alert')

    if (alertStatus === 'true') {
      dispatch(toggleAlert(true))
      dispatch(
        setAlertMsg(
          'Sesi otomatis berakhir karena ada pembaharuan versi aplikasi. Masuk kembali ke akun Anda untuk melanjutkan.',
        ),
      )
      localStorage.removeItem('PMB_alert')
    }
  }, [dispatch])

  useEffect(() => {
    if (versionLocalStorage !== null && versionLocalStorage !== version) {
      localStorage.clear()
      localStorage.setItem('PMB_version', version)
      localStorage.setItem('PMB_alert', 'true')
      window.location.reload()
    } else if (versionLocalStorage === null) {
      localStorage.setItem('PMB_version', version)
    } else if (
      token === null ||
      (token !== null && !userNotPartnership && alreadychangepass === 0)
    ) {
      navigate(isV3 ? '/login-v2' : '/login', { replace: true })
    } else {
      setIsEffectCompleted(true)
    }
  }, [
    token,
    userNotPartnership,
    alreadychangepass,
    versionLocalStorage,
    version,
    isV3,
    navigate,
    dispatch,
  ])

  if (!isEffectCompleted) {
    return null
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{
        marginTop: '20px',
      }}
    >
      <div
        style={{ minHeight: '100vh', width: '100%', overflow: 'auto' }}
        className="bg-light d-flex flex-column"
      >
        <NavbarDashboard color="white" expand="md" profile={profile} />
        <div style={{ flex: 1 }} className="bg-light d-flex">
          <NavbarSide />
          <Outlet context={profile} />
        </div>
      </div>
    </SnackbarProvider>
  )
}

export default App
