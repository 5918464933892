import CustomButton from 'components/Buttons/CustomButton'
import React from 'react'
import { Button, Container, Navbar, NavbarBrand } from 'reactstrap'
import trophyIC from 'assets/icons/trophy.svg'
import { X } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { URL_KURSUS } from 'utils/pathUrl'

const FinishPage = () => {
  const navigate = useNavigate()

  return (
    <div
      className="d-flex flex-column justify-content-between pb-3"
      style={{ minHeight: '100vh' }}
    >
      <Navbar className="border" color="white" expand="md">
        <NavbarBrand>
          <div className="d-flex gap-3 align-items-center my-2">
            <X onClick={() => navigate(-1)} size={24} className="text-muted" />
          </div>
        </NavbarBrand>
      </Navbar>
      <div className="p-3">
        <div className="flex-centered">
          <img alt="trophy" src={trophyIC} />
        </div>
        <h2 className="text-center fw-bold">Selamat!</h2>
        <p className="text-center">
          Kamu telah menyelesaikan semua materi di bagian ini.
        </p>
      </div>

      <div className="p-3">
        <CustomButton
          onClick={() => navigate(URL_KURSUS)}
          size="lg"
          color="primary"
          block
        >
          Lanjut
        </CustomButton>
      </div>
    </div>
  )
}

export default FinishPage
