import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'
import { API_GROUP_LIST, API_ROLES_FARMERS, API_ROLES_PMB } from 'utils/apiList'

const getRolesPMB = createAsyncThunk(
  'farmersList/role-pmb',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_ROLES_PMB}`,
        config,
      )
      return data?.data.map((e) => ({
        ...e,
        label: e.name,
        value: e.id,
      }))
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getRolesFarmers = createAsyncThunk(
  'farmersList/role-farmers',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_ROLES_FARMERS}`,
        config,
      )
      return data?.data.map((e) => ({
        ...e,
        label: e.name,
        value: e.id,
      }))
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getRolesPMB, getRolesFarmers }
