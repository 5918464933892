import { useEffect, useMemo, useState } from 'react'
import {
  useGetSizeCommodityQuery as getSize,
  useGetCommodityQuery as getCommodity,
  useGetFrequentCommodityQuery as getFrequentCommodity,
} from 'features/private-views/commodity-repo/commodityRepoApiSlice'

const useGetCommodityQuery = (arg) => {
  const [size, setSize] = useState(null)
  const storedFrequentItems =
    JSON.parse(localStorage.getItem('lastPickedCommoditiesBuy')) || []

  const teamResult = getSize(arg)

  const result = getCommodity(size, {
    skip: size === null || size === undefined,
  })

  const frequents = getFrequentCommodity()

  let frequentItems = [...storedFrequentItems, ...(frequents?.data || [])]

  frequentItems = frequentItems.filter(
    (item, index, self) => index === self.findIndex((i) => i.id === item.id),
  )
  frequentItems = frequentItems.slice(0, 5)

  const allMappedItems =
    result?.data?.items?.filter(
      (item) => !frequentItems.some((frequent) => frequent.id === item.id),
    ) || []

  useEffect(() => {
    localStorage.setItem(
      'lastPickedCommoditiesBuy',
      JSON.stringify(frequentItems),
    )
  }, [frequentItems])

  const data = useMemo(() => {
    return [
      {
        label: 'TERAKHIR/SERING DIPILIH',
        options: frequentItems,
      },
      {
        label: 'SEMUA KOMODITAS',
        options: allMappedItems,
      },
    ]
  }, [frequentItems, allMappedItems])

  useEffect(() => {
    if (teamResult?.data?.totalItems !== undefined) {
      setSize(teamResult.data.totalItems)
    }
  }, [teamResult?.data?.totalItems])

  if (result.isFetching || !result.data) {
    return { data: [], isLoading: true }
  }

  return { data, isLoading: false }
}

export default useGetCommodityQuery
