import { createApiSlice } from 'app/createApiSlice'
import { API_GROUP_LIST } from 'utils/apiList'

export const groupListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'groupList/api',
  endpoints(builder) {
    return {
      createGroup: builder.mutation({
        query({ name }) {
          return {
            url: API_GROUP_LIST,
            method: 'POST',
            body: {
              name,
            },
          }
        },
      }),
    }
  },
})

export const { useCreateGroupMutation } = groupListApiSlice
