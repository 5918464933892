import { createApiSlice } from 'app/createApiSlice'
import {
  API_COMPANIES,
  API_FISHERIES,
  API_GEARS,
  API_PORTS,
  API_UPLOAD_IMAGE_PUBLIC,
  API_VESSELS_ADD,
  API_VESSELS_USERS,
} from 'utils/apiList'

export const formVesselsApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'formVessels/api',
  endpoints(builder) {
    return {
      uploadImage: builder.mutation({
        query({ img, fileType }) {
          const image = new FormData()
          image.append('file', img)
          return {
            url: API_UPLOAD_IMAGE_PUBLIC,
            method: 'POST',
            body: {
              file: image,
              fileType,
            },
          }
        },
      }),
      creationVessel: builder.mutation({
        query(payload) {
          return {
            url: API_VESSELS_ADD,
            method: 'POST',
            body: payload,
          }
        },
      }),
      updateVessel: builder.mutation({
        query({ payload, id }) {
          return {
            url: `${API_VESSELS_ADD}/${id}`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      creationVesselUsers: builder.mutation({
        query(payload) {
          return {
            url: `${API_VESSELS_USERS}`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      // GEARS LIST
      fetchAllGearsVessel: builder.query({
        query() {
          return {
            url: API_GEARS,
          }
        },
      }),
      // COMPANIES LIST
      fetchAllCompaniesVessel: builder.query({
        query({ page, size = 10, searchvalue }) {
          return {
            url: API_COMPANIES,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
      // FISHERIES LIST
      fetchAllFisheriesVessel: builder.query({
        query({ page, size = 999, searchvalue }) {
          return {
            url: API_FISHERIES,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
      // PORTS LIST
      fetchAllPortsVessel: builder.query({
        query({ page, size = 999, searchvalue }) {
          return {
            url: API_PORTS,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchBanksQuery,
  useUploadImageMutation,
  useCreationVesselMutation,
  useUpdateVesselMutation,
  useCreationVesselUsersMutation,
  useFetchAllGearsVesselQuery,
  useFetchAllCompaniesVesselQuery,
  useFetchAllFisheriesVesselQuery,
  useFetchAllPortsVesselQuery,
} = formVesselsApiSlice
