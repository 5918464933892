import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import LoadingV2 from 'components/LoadingV2'
import { useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Search } from 'react-feather'
import {
  Card,
  CardBody,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { useFetchGearsQuery } from './gearsListApiSlice'

const GearsTable = () => {
  const [tag, setTag] = useState({
    size: 10,
    page: 0,
  })
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const debounceTimer = useRef(0)

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch, isSuccess } = useFetchGearsQuery({
    page: tag?.page,
    size: tag?.size,
    searchvalue: searchTemp,
    sortby: sortColumn,
    sortdir: sortDirection,
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction?.toLowerCase() === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: 'Gear Category',
      selector: 'category',
      sortable: true,
      cell: (row) => {
        return <span>{row?.category ?? '-'}</span>
      },
    },
    {
      name: 'Sub',
      selector: 'sub',
      sortable: true,
      cell: (row) => {
        return <span>{row?.sub ?? '-'}</span>
      },
    },
    {
      name: 'Type',
      selector: 'typeVal',
      sortable: true,
      cell: (row) => {
        return <span>{row?.typeVal ?? '-'}</span>
      },
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: 'MMAF',
      selector: 'mmaf',
      sortable: true,
      cell: (row) => {
        return <span>{row?.mmaf ?? '-'}</span>
      },
    },
    {
      name: 'ISSCF',
      selector: 'isscf',
      sortable: true,
      cell: (row) => {
        return <span>{row?.isscf ?? '-'}</span>
      },
    },
    {
      name: 'FAO',
      selector: 'fao',
      sortable: true,
      cell: (row) => {
        return <span>{row?.fao ?? '-'}</span>
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const onHandleSearchChange = (text) => {
    setSearch(text)
    setTag({ page: 0, size: 10 })
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        setSearchTemp(text)
        refetch()
      } else {
        setSearch('')
        setSearchTemp('')
        refetch()
      }
    }, 3000)
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Data Gears" />
      </div>

      <Card className="mb-3">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || !isSuccess}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada daftar gears yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${tag?.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: tag?.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setTag({ page: tag?.page - 1 })
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          tag?.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setTag({ page: tag.page + 1 })
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Gears</h5>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari nama gear..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default GearsTable
