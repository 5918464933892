import { Spinner } from 'reactstrap'

const LoadingV2 = () => {
  return (
    <div
      style={{
        opacity: 1,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 1000,
      }}
    >
      <Spinner color="primary" />
    </div>
  )
}

export default LoadingV2
