import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import LoadingV2 from 'components/LoadingV2'
import PMBProgressBarExportFile from 'components/PMBProgressBarExportFile'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import PMBSelect from 'components/PMBSelect'
import PMBTooltip from 'components/PMBTooltip'
import { FILTER_CATCHES_LIST_OPTIONS, PROVINCES_OPTIONS } from 'constants'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Check, ChevronDown, Eye, Filter } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
} from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { BUSINESS_TRIPS, MANAGE } from 'utils/subjectActions'
import IconApprove from '../../../../assets/icons/icon-approve.svg'
import IconNotApprove from '../../../../assets/icons/icon-unapprove.svg'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import { useFetchCatchesListQuery } from './catchesListApiSlice'
import {
  setFetchOption,
  setIsOpenModalApprove,
  setToggleSidebarFilter,
} from './catchesListSlice'
import { handleDownloadCSV, handleDownloadJSON } from './DownloadReport'
import ModalApprove from './ModalApprove'
import SidebarFilter from './SidebarFilter'

const CatchesTable = () => {
  const { tag, isOpenSidebarFilter, isOpenModalApprove } = useSelector(
    (s) => s.catchesList,
  )

  const manageActionApproveBusinessTrips = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_TRIPS,
  })

  const menuItems = [
    {
      label: 'Download as Excel',
      action: () => {
        handleDownloadCSV(data, setIsDialogShow, setFirstRows, setTotalRows)
      },
    },
    {
      label: 'Download as JSON',
      action: () => {
        handleDownloadJSON(data, setIsDialogShow, setFirstRows, setTotalRows)
      },
    },
  ]

  // const manageActionApproveBusinessVessel = useAbilityAccess({
  //   action: MANAGE,
  //   subject: BUSINESS_VESSELS,
  // })

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)

  const [search, setSearch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState(
    FILTER_CATCHES_LIST_OPTIONS[0],
  )
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedProvince, setSelectedProvince] = useState(PROVINCES_OPTIONS[0])
  const [selectedDate, setSelectedDate] = useState([
    startDateDefault,
    endDateDefault,
  ])
  const [selectedWPP, setSelectedWPP] = useState(null)
  // STATE DOWNLOAD REPORT
  const [isDialogShow, setIsDialogShow] = useState(false)
  const [firstRows, setFirstRows] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [detailRow, setDetailRow] = useState(null)

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('DESC')

  const debounceTimer = useRef(0)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data, isLoading, refetch } = useFetchCatchesListQuery({
    page: tag?.page ?? 0,
    s: search ?? '',
    size: tag?.size ?? 10,
    from: moment(selectedDate[0]).format('YYYY-MM-DD') ?? '',
    to: moment(selectedDate[1])?.format('YYYY-MM-DD') ?? '',
    // filterwpp: selectedWPP?.map((wpp) => wpp?.value).join(',') ?? '', // this code for multiple wpp, currently just one wpp
    filterwpp: selectedWPP ?? '',
    filterapprove: selectedStatus?.value ?? '',
    filterdistrict: selectedProvince?.value ?? '',
    sortby: sortColumn ?? '',
    sortdir: sortDirection ?? '',
  })

  const handleSearchChange = (text) => {
    dispatch(setFetchOption({ page: 0 }))
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        refetch()
      } else {
        setSearch('')
        refetch()
      }
    }, 3000)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState)
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: 'Tanggal Perjalanan',
      selector: 'startfishing',
      sortable: true,
      cell: (row) => {
        const startDate = moment(row?.data?.startFishing).format('DD MMMM YYYY')
        const endDate = moment(row?.data?.endFishing).format('DD MMMM YYYY')
        return (
          <span>
            {startDate ?? ''} - {endDate ?? ''}
          </span>
        )
      },
    },
    {
      name: 'Kapal',
      selector: 'shipname',
      sortable: true,
      cell: (row) => {
        return (
          <Row className="align-items-center mb-2 d-flex flex-grow-1">
            <Col xs="3">
              <img
                src={row?.data?.vessel?.linkImage ?? VesselNotFound}
                alt={row?.data?.vessel?.vesselName}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '4px',
                }}
                loading="lazy"
              />
            </Col>

            <Col className="align-items-center" xs="8">
              <p className="fw-bold text-primary m-0">
                {row?.data?.vessel?.shipName}
              </p>
            </Col>
          </Row>
        )
      },
    },
    {
      name: 'Nama Nelayan',
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.profile?.name ?? ''}</span>
      },
    },
    {
      name: 'Hasil Tangkapan',
      selector: 'fishname',
      sortable: true,
      cell: (row) => {
        const fishes = row?.data?.fishes ?? []

        const firstFish = fishes[0]
        const firstFishName = firstFish?.selectedFish?.nameItem
        const firstFishCode = firstFish?.selectedFish?.attribute?.code ?? ''
        const firstFishAmount = firstFish?.amount ?? 0

        const additionalFishesCount =
          fishes?.length > 1 ? fishes?.length - 1 : 0

        const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'

        return (
          <div>
            <div className="my-1">
              <span className="fw-semibold">
                {firstFishName} <em>({firstFishCode})</em>
              </span>
            </div>
            <div className="my-1">
              <span className="text-primary">
                {newFormatThousandSeparator(firstFishAmount)} {unit}
              </span>
            </div>

            {additionalFishesCount > 0 && (
              <Badge className="badge-gray my-1">
                + {additionalFishesCount} tangkapan lainnya
              </Badge>
            )}
          </div>
        )
      },
    },
    {
      name: 'Total Penangkapan (kg)',
      cell: (row) => {
        const unit = row?.data?.fishes?.[0]?.selectedFish?.unit ?? 'kg'
        const totalAmount = row.data.fishes.reduce(
          (sum, fish) => sum + fish.amount,
          0,
        )

        return (
          <span>
            {newFormatThousandSeparator(totalAmount)} {unit}
          </span>
        )
      },
    },
    {
      name: 'WPP',
      selector: 'wpp',
      sortable: true,
      cell: (row) => {
        const catchAreas = [
          ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
        ].join(', ')

        return <span>{catchAreas}</span>
      },
    },
    {
      name: 'Status',
      cell: (row) => {
        // HANDLE ABILITY AND APPROVE
        if (row?.data?.approved === true) {
          return (
            <>
              <img
                src={IconApprove}
                id={`approve-${row?.id}`}
                alt={`approve-${row?.id}`}
                loading="lazy"
              />
              <PMBTooltip target={`approve-${row?.id}`} text="Terverfikasi" />
            </>
          )
        }

        return (
          <>
            <img
              src={IconNotApprove}
              id={`not-approve-${row?.id}`}
              alt={`not-approve-${row?.id}`}
              loading="lazy"
            />
            <PMBTooltip
              target={`not-approve-${row?.id}`}
              text="Belum Terverifikasi"
            />
          </>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-4">
            <Eye
              size={20}
              onClick={() => {
                navigate(`/catches/${row?.id}`)
              }}
              style={{
                cursor: 'pointer',
              }}
              id={`icon-lihat-detail-${row?.id}`}
            />
            <PMBTooltip
              target={`icon-lihat-detail-${row?.id}`}
              text="Lihat Detail"
            />

            {manageActionApproveBusinessTrips && !row?.data?.approved && (
              <>
                <Check
                  size={20}
                  onClick={() => {
                    dispatch(setIsOpenModalApprove())
                    setDetailRow(row)
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                  id={`icon-verifikasi-${row?.id}`}
                />
                <PMBTooltip
                  target={`icon-verifikasi-${row?.id}`}
                  text="Verifikasi"
                />
              </>
            )}
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        // marginTop: '8px',
        // marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Daftar Perjalanan" />
      </div>

      <SidebarFilter
        open={isOpenSidebarFilter}
        handleFilter={() => {
          dispatch(setToggleSidebarFilter())
          dispatch(setFetchOption({ page: 0 }))
        }}
        toggle={() => dispatch(setToggleSidebarFilter())}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleSearchChange={handleSearchChange}
        selectedWPP={selectedWPP}
        setSelectedWPP={setSelectedWPP}
      />
      {/* MODAL APPROVE */}
      <ModalApprove
        open={isOpenModalApprove}
        toggle={() => dispatch(setIsOpenModalApprove())}
        row={detailRow}
      />

      <Card className="mb-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada daftar perjalanan yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${tag?.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: tag?.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          dispatch(setFetchOption({ page: tag?.page - 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          tag?.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          dispatch(setFetchOption({ page: tag?.page + 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                {/* Header section */}
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Perjalanan</h5>

                  {/* Dropdown for the download options */}
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      caret
                      className="btn btn-primary btn-outline btn-sm"
                      color="primary"
                    >
                      Download
                    </DropdownToggle>
                    <DropdownMenu>
                      {menuItems.map((item, index) => (
                        <DropdownItem key={index} onClick={item.action}>
                          <span className="text-sm">{item.label}</span>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="row my-4">
                  {/* Group the inputs */}
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="row g-3">
                      {/* SEARCH */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <PMBSearchInputGroup
                          label={'Cari'}
                          value={search}
                          placeholder="Cari nelayan, kapal, ikan, dll..."
                          onChange={(e) => handleSearchChange(e.target.value)}
                          maxWidth="100%"
                        />
                      </div>

                      {/* STATUS */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <PMBSelect
                          title="Status"
                          value={selectedStatus}
                          options={FILTER_CATCHES_LIST_OPTIONS}
                          onChange={(item) => {
                            dispatch(setFetchOption({ page: 0 }))
                            setSelectedStatus(item)
                          }}
                          style={{ width: '100%' }}
                        />
                      </div>

                      {/* PROVINCE */}
                      {/* TEMPORARY HIDE (WAITING BE) */}
                      {/* <div className="col-lg-4 col-md-4 col-sm-12">
                        <PMBSelect
                          title="Provinsi"
                          value={selectedProvince}
                          options={PROVINCES_OPTIONS}
                          onChange={(item) => {
                            dispatch(setFetchOption({ page: 0 }))
                            setSelectedProvince(item)
                          }}
                          style={{ width: '100%' }}
                        />
                      </div> */}
                    </div>
                  </div>

                  {/* FILTER BUTTON */}
                  <div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-lg-end justify-content-start align-items-center mt-3 mt-lg-4">
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        border: '1px solid #0b6e99',
                        color: '#0b6e99',
                        backgroundColor: 'white',
                        padding: '8px',
                      }}
                      size="sm"
                      outline
                      onClick={() => dispatch(setToggleSidebarFilter())}
                    >
                      <Filter size={20} />
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      {/* Progress Bar Modal */}
      <PMBProgressBarExportFile
        isDialogShow={isDialogShow}
        firstRows={firstRows}
        totalRows={totalRows}
        onCancel={() => {
          setIsDialogShow(false)
        }}
      />
    </div>
  )
}

export default CatchesTable
