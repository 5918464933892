import CustomButton from 'components/Buttons/CustomButton'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmTrx } from '../requestTransactionAction'

const ButtonAction = ({ amount, code, data }) => {
  const dispatch = useDispatch()
  const { linkApproved, isApproved } = useSelector((s) => s.reqTrxSell)

  useEffect(() => {
    if (isApproved) {
      window.open(linkApproved, '_blank', 'noopener,noreferrer')
    }
  }, [isApproved, linkApproved])
  return (
    <div className="d-flex align-items-center gap-2 mb-4">
      <CustomButton
        style={{ width: 220 }}
        color="secondary"
        outline
        onClick={() => dispatch(confirmTrx({ amount, code, status: 'CANCEL' }))}
        disabled={data?.approvedAt || isApproved}
      >
        Tolak
      </CustomButton>
      <CustomButton
        disabled={data?.approvedAt || isApproved}
        style={{ width: 220 }}
        color="primary"
        onClick={() =>
          dispatch(confirmTrx({ amount, code, status: 'CONFIRM' }))
        }
      >
        Terima dan Bayar
      </CustomButton>
    </div>
  )
}

export default ButtonAction
