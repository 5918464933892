import { createAppSlice } from 'app/createAppSlice'
import { getRolesFarmers, getRolesPMB } from './farmersListAction'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  tagGroups: {
    size: 10,
    page: 0,
  },
  tags: {
    123: {
      size: 10,
      page: 0,
    },
  },
  isOpenBanner: false,
  isOpenBannerGroups: false,
  details: null,
  isOpenModal: false,
  loading: false,
  isOpenModalDelete: false,
  bannerMsg: '',
  rolesFarmers: [],
  rolesPMB: [],
}

export const farmersListSlice = createAppSlice({
  name: 'farmersList',
  initialState,
  reducers: (create) => ({
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setFetchOptionGroups: create.reducer((state, { payload }) => {
      state.tagGroups.size = payload.size
      state.tagGroups.page = payload.page
    }),
    setFetchOptionSubGroup: create.reducer((state, { payload }) => {
      state.tags[payload.id].size = payload.size
      state.tags[payload.id].page = payload.page
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleBannerGroups: create.reducer((state, { payload }) => {
      state.isOpenBannerGroups = !state.isOpenBannerGroups
    }),
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
    toggleModalDelete: create.reducer((state, { payload }) => {
      state.isOpenModalDelete = !state.isOpenModalDelete
    }),
    setBannerMsg: create.reducer((state, { payload }) => {
      state.bannerMsg = payload
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getRolesFarmers.pending, (state) => {
        state.loading = true
      })
      .addCase(getRolesFarmers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.rolesFarmers = payload
      })
      .addCase(getRolesFarmers.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getRolesPMB.pending, (state) => {
        state.loading = true
      })
      .addCase(getRolesPMB.fulfilled, (state, { payload }) => {
        state.loading = false
        state.rolesPMB = payload
      })
      .addCase(getRolesPMB.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {
  setFetchOption,
  toggleSidebarWithdraw,
  toggleBanner,
  toggleModal,
  setFetchOptionGroups,
  setFetchOptionSubGroup,
  toggleBannerGroups,
  toggleModalDelete,
  setBannerMsg,
} = farmersListSlice.actions

export default farmersListSlice.reducer
