import CustomButton from 'components/Buttons/CustomButton'
import QRCode from 'react-qr-code'
import { Card, CardText, UncontrolledTooltip } from 'reactstrap'

export default function IDStockModal({ isOpen, onClose, selectedStock }) {
  const inventoryUrl = process.env.REACT_APP_INVENTORY_URL
  let url = `${inventoryUrl}?productId=${selectedStock?.traceCode}`

  const onOpenCommodityOnWeb = () => {
    window.open(url, '_blank')
  }

  const onCopyCommodityLink = () => {
    navigator.clipboard.writeText(url)
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div
          style={{
            width: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Card className="small-padding mt-4 mb-4">
            <div>
              <QRCode value={url} />
            </div>
            <CustomButton
              block
              style={{
                borderColor: '#2A7D9A',
                backgroundColor: '#F3FEFF',
                color: 'black',
                alignItems: 'center',
                marginRight: 10,
                marginTop: 15,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              className="text-align-center"
              // onClick={onOpenCommodityOnWeb}
            >
              <CardText className="text-align-center" style={{ fontSize: 12 }}>
                Scan QR code di atas dari handphone Anda.
              </CardText>
            </CustomButton>
            <CustomButton
              id="tooltipCopy"
              block
              style={{
                borderColor: '#D0D5DD',
                backgroundColor: 'white',
                color: 'black',
                alignItems: 'center',
                marginRight: 10,
              }}
              className="text-align-center mt-3"
              onClick={onOpenCommodityOnWeb}
            >
              <CardText
                className="text-align-center fw-semibold"
                style={{ fontSize: 12 }}
              >
                Buka Link
              </CardText>
            </CustomButton>
            <UncontrolledTooltip
              target={'tooltipCopy'}
              placement="bottom"
              trigger="click"
              autohide={true}
            >
              Berhasil disalin
            </UncontrolledTooltip>
          </Card>
        </div>
      </div>
    </>
  )
}
