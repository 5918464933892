import { Trash } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'

import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import { useFetchUnitsDevQuery } from 'features/public-api/publicApiSlice'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { getComodities } from '../purchaseCommodityAction'
import { removeItem, setOrdersPayload } from '../purchaseCommoditySlice'

const Item = ({ fieldNum, idx }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const {
    isLoading: loadingPubDev,
    data: dataPubDev,
    isSuccess: successPubDev,
  } = useFetchUnitsDevQuery()

  const { orders, addItemCount, comodities } = useSelector(
    (state) => state.purchaseCommmodity,
  )
  const [units, setUnits] = useState([])
  const [listComodities, setListComodities] = useState([])

  useEffect(() => {
    if (!loadingPubDev) {
      if (successPubDev) {
        const newData = dataPubDev?.unitList?.split(',')
        const newestData = newData?.map((e) => ({
          value: e,
          label: e,
        }))
        setUnits(newestData)
      } else {
        setUnits([])
      }
    }
  }, [dataPubDev, successPubDev, loadingPubDev])

  const handleChange = (name, e, i) => {
    let key = Object.prototype.hasOwnProperty.call(e, 'sku') ? 'nameItem' : name
    let value = key === 'nameItem' ? e.label : e
    let imageUrl =
      key === 'nameItem' ? (e.img ?? e?.linkFoto) : orders.item[i].imageUrl
    let sku = key === 'nameItem' ? (e.sku ?? e?.SKU) : orders.item[i].SKU
    let newData = [...orders.item]

    newData[i] = {
      ...newData[i],
      orderItemId: addItemCount[i],
      [key]: value,
      SKU: sku,
      imageUrl: imageUrl,
      totalPrice:
        key === 'price' && orders.item[i]?.amount
          ? Number(
              value
                ?.replace?.(/\./g, '')
                ?.replace?.(/\,/g, '.')
                ?.slice?.(0, 15),
            ) *
            Number(
              newData[i]?.amount
                ?.replace?.(/\./g, '')
                ?.replace?.(/\,/g, '.')
                ?.slice?.(0, 15),
            )
          : key === 'amount' && orders.item[i]?.price
            ? Number(
                newData[i]?.price
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/\,/g, '.')
                  ?.slice?.(0, 15),
              ) *
              Number(
                value
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/\,/g, '.')
                  ?.slice?.(0, 15),
              )
            : newData[i]?.totalPrice,
    }
    dispatch(setOrdersPayload(newData))
  }

  const handleChangeInputSeparator = (data, name, idx) => {
    if (data !== orders?.item?.[idx]?.[name]) {
      handleChange(name, data, idx)
    }
  }

  const handleLastPick = (e) => {
    const newListComodities = [...listComodities]

    const updatedOptions = newListComodities[0]?.options?.filter(
      (item) => item.id !== e.id,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities[1] = {
        ...newListComodities[1],
        options: [...newListComodities[1].options, removedItem],
      }
    }

    newListComodities[0] = {
      ...newListComodities[0],
      options: updatedOptions,
    }

    newListComodities[1] = {
      ...newListComodities[1],
      options: newListComodities[1].options.filter((item) => item.id !== e.id),
    }

    localStorage.setItem(
      'lastPickedCommoditiesBuy',
      JSON.stringify(updatedOptions),
    )

    setListComodities(newListComodities)
  }

  useEffect(() => {
    if (comodities?.length < 1) {
      dispatch(getComodities())
    }
    setListComodities(comodities)
  }, [comodities])

  return (
    <CardBody>
      <FormGroup>
        <Label for="nameItem">Komoditas</Label>
        <Select
          id="nameItem"
          name="nameItem"
          placeholder="Pilih komoditas"
          options={listComodities}
          onChange={(e) => {
            handleChange('nameItem', e, idx)
            handleLastPick(e)
          }}
          value={orders[idx]?.nameItem}
          defaultValue={orders[idx]?.nameItem}
        />
      </FormGroup>
      {/* section 1 */}
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="amount">Jumlah</Label>
            <AmountInput
              name="amount"
              idx={idx}
              cb={handleChangeInputSeparator}
              placeholder="0"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="unit">Satuan</Label>
            <Select
              id="unit"
              name="unit"
              placeholder="Pilih unit"
              options={units ?? []}
              onChange={(e) => handleChange('unit', e, idx)}
              value={orders?.item[idx]?.unit}
              defaultValue={orders?.item[idx]?.unit}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* section 2 */}
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="price">Harga per satuan</Label>
            <InputGroup>
              <InputGroupText>Rp</InputGroupText>
              <AmountInput
                name="price"
                idx={idx}
                cb={handleChangeInputSeparator}
                placeholder="0"
                dotOnly
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="total">Total harga</Label>
            <InputGroup>
              <InputGroupText>Rp</InputGroupText>
              <Input
                id="total"
                name="total"
                type="text"
                disabled
                placeholder={intl.formatNumber(
                  isNaN(orders?.item[idx]?.totalPrice)
                    ? 0
                    : orders?.item[idx]?.totalPrice,
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <div className="">
          {fieldNum !== 1 && (
            <CustomButton
              color="danger"
              outline
              className="float-end"
              onClick={() => dispatch(removeItem(fieldNum))}
              size="sm"
            >
              <Trash size={16} /> Hapus
            </CustomButton>
          )}
        </div>
      </Row>
      <hr style={{ height: '2px' }} />
    </CardBody>
  )
}

export default Item
