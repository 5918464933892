import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import LoadingV2 from 'components/LoadingV2'
import PMBModalRemove from 'components/PMBModalRemove'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Edit, MoreVertical, Search, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import {
  useDeleteFisheryByIdMutation,
  useFetchFisheriesQuery,
} from './fisheriesListApiSlice'
import { setFetchOption } from './fisheriesListSlice'
import ModalEdit from './ModalEdit'

const FisheriesTable = () => {
  const { tag } = useSelector((s) => s.fisheriesList)

  const { enqueueSnackbar } = useSnackbar()

  const [deleteFisheryById, { isLoading: isLoadingDelete }] =
    useDeleteFisheryByIdMutation()

  let [page, setPage] = useState({ page: tag.page })
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  let [dataRow, setDataRow] = useState(null)
  const debounceTimer = useRef(0)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [dropdownOpenMap, setDropdownOpenMap] = useState({})
  const dropdownRef = useRef(null)

  const menuItems = [
    {
      label: 'Ubah',
      icon: <Edit size={16} />,
      action: (row) => {
        setDataRow(row)
        onHandleOpenCloseModalEdit()
      },
    },
    {
      label: 'Hapus',
      icon: <Trash2 size={16} />,
      action: (row) => {
        setDataRow(row)
        onHandleOpenCloseModalDelete()
      },
    },
  ]

  const toggleDropdown = (id) => {
    setDropdownOpenMap((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenMap(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch, isSuccess } = useFetchFisheriesQuery({
    page: page?.page,
    size: 10,
    searchvalue: searchTemp,
    sortby: sortColumn,
    sortdir: sortDirection,
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction?.toLowerCase() === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: 'Id',
      width: '80px',
      selector: 'id',
      sortable: true,
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: 'Fishery Code',
      selector: 'code',
      sortable: true,
      cell: (row) => {
        return <span>{row?.code ?? '-'}</span>
      },
    },
    {
      name: 'Fishery Name',
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: 'Target Species',
      width: '140px',
      selector: 'targetSpecies',
      sortable: true,
      cell: (row) => {
        const dataTargetSpecies = row?.targetSpecies ?? null
        const targetSpeciesSplit = dataTargetSpecies?.split(',') ?? '-'

        return (
          <span>
            {dataTargetSpecies
              ? targetSpeciesSplit?.map((item) => (
                  <li className="mt-1">{item}</li>
                ))
              : '-'}
          </span>
        )
      },
    },
    {
      name: 'Bait Species',
      cell: (row) => {
        const baitsListValue = row?.baitsList?.length
          ? row?.baitsList
              ?.map(
                (item) => `${item?.baitData?.code} - ${item?.baitData?.name}`,
              )
              ?.join(', ')
          : '-'
        return (
          <span>
            {row?.baitsList?.length > 0 ? (
              <>
                {row?.baitsList?.map((item) => (
                  <li className="mt-1">{`${item?.baitData?.code} - ${item?.baitData?.name}`}</li>
                ))}
              </>
            ) : (
              '-'
            )}
          </span>
        )
      },
    },
    {
      name: 'Gear',
      width: '200px',
      selector: 'gears',
      sortable: true,
      cell: (row) => {
        return <span>{row?.gears ?? '-'}</span>
      },
    },
    {
      name: 'Supporting Office',
      selector: 'supportingOffice',
      sortable: true,

      cell: (row) => {
        const dataSupportingOffice = row?.supportingOffice ?? null
        const targetSpeciesSplit = dataSupportingOffice?.split(',') ?? '-'

        return (
          <span>
            {dataSupportingOffice
              ? targetSpeciesSplit?.map((item) => (
                  <li className="mt-1">{item}</li>
                ))
              : '-'}
          </span>
        )
      },
    },
    {
      name: '',
      width: '80px',
      cell: (row) => {
        return (
          <div
            style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
          >
            <Dropdown
              isOpen={dropdownOpenMap[row.id]}
              toggle={() => toggleDropdown(row.id)}
              direction="down"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpenMap[row.id]}
                style={{ cursor: 'pointer' }}
              >
                <MoreVertical size={20} />
              </DropdownToggle>
              <DropdownMenu className="m-0 p-0" container="body">
                {menuItems.map((item, index) => (
                  <DropdownItem
                    className="my-1"
                    key={index}
                    onClick={() => item.action(row)}
                  >
                    <span>{item.icon}</span>
                    <span className="ms-2 text-sm">{item.label}</span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const onHandleSearchChange = (text) => {
    setPage({ page: 0 })
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        setSearchTemp(text)
        refetch()
      } else {
        setSearch('')
        setSearchTemp('')
        refetch()
      }
    }, 3000)
  }

  const onHandleOpenCloseModalEdit = () => {
    setIsOpenModalEdit((prev) => !prev)
  }

  const onHandleOpenCloseModalDelete = () => {
    setIsOpenModalDelete((prev) => !prev)
  }

  const onRemoveDataFisheryById = async (id) => {
    try {
      if (!id) {
        enqueueSnackbar('Fishery gagal dihapus!', {
          variant: 'error',
        })

        return
      }

      const res = await deleteFisheryById({ id }).unwrap()

      if (res) {
        enqueueSnackbar('Fishery berhasil di hapus!', {
          variant: 'success',
        })
        refetch()
        onHandleOpenCloseModalDelete()
      }
    } catch (error) {
      console.warn('Error saat menghapus vessel', error)
      enqueueSnackbar('Fishery gagal dihapus!', {
        variant: 'error',
      })
      onHandleOpenCloseModalDelete()
    }
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Data Fisheries" />
      </div>

      {/* MODAL EDIT */}
      <ModalEdit
        row={dataRow}
        isOpenModalEdit={isOpenModalEdit}
        onHandleOpenCloseModalEdit={onHandleOpenCloseModalEdit}
      />

      {/* MODAL REMOVE */}
      <PMBModalRemove
        isOpen={isOpenModalDelete}
        onClose={() => onHandleOpenCloseModalDelete()}
        dataName={dataRow?.name}
        dataId={dataRow?.id}
        isLoading={isLoadingDelete}
        onRemoveData={(id) => onRemoveDataFisheryById(id)}
      />

      <Card className="mb-3">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || !isSuccess}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada daftar fisheries yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page - 1 }))
                          dispatch(setFetchOption({ page: page.page - 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page + 1 }))
                          dispatch(setFetchOption({ page: page.page + 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Fisheries</h5>
                  <Button
                    onClick={() => navigate('/fisheries/add')}
                    size="sm"
                    color="primary"
                  >
                    + Tambah Fishery
                  </Button>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari nama fishery..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default FisheriesTable
