import { Card, Container } from 'reactstrap'
import LogoPasmik from 'assets/icons/PasarMIKRO-Primary-Logogram-3.svg'

const Expired = () => {
  return (
    <div className="flex-centered" style={{ minHeight: '100vh' }}>
      <Container className="custom-container rounded">
        <Card style={{ backgroundColor: '#F7F7F7' }}>
          <div className="flex-centered py-3">
            {' '}
            <img
              style={{ width: '100%', maxWidth: '80px', height: 'auto' }}
              src={LogoPasmik}
              alt="PasarMIKRO logo"
            />{' '}
          </div>
          <p className="fw-500 text-center">
            Link ini sudah kedaluwarsa dan tidak bisa digunakan.
          </p>
        </Card>
      </Container>
    </div>
  )
}

export default Expired
