import moment from 'moment-timezone'

export const filterLatLong = (data) => {
  return data
    .map((location) => {
      if (location.plottingPoint && location.plottingPoint.coordinates) {
        return {
          lat: location.plottingPoint.coordinates[1],
          lng: location.plottingPoint.coordinates[0],
        }
      } else {
        console.error('Invalid location data:', location)
        return null
      }
    })
    .filter(Boolean)
}

export function getFileExtension(url) {
  const dotIndex = url.lastIndexOf('.')
  const queryIndex = url.indexOf('?', dotIndex)

  if (dotIndex === -1) {
    return null
  }
  if (queryIndex === -1) {
    return url.substring(dotIndex)
  } else {
    return url.substring(dotIndex, queryIndex)
  }
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const PMBFormatDate = (dateString) =>
  dateString ? moment(dateString).format('Do MMMM YYYY, HH:mm') : '-'

export function getActionDescription(log) {
  let data = {
    vessel_name:
      log?.parsedExtraData?.resp?.data?.vessel?.shipName ||
      log?.parsedExtraData?.resp?.vesselName,
    fishermen: [],
    fisherman_name: log?.user?.name,
    fishery_name: log?.parsedExtraData?.resp?.name,
    port_name: log?.parsedExtraData?.resp?.port,
    company_name: log?.parsedExtraData?.resp?.name,
    gear_name: log?.parsedExtraData?.resp?.gearData?.name,
    name: log?.parsedExtraData?.before?.name,
    status: log?.parsedExtraData?.resp?.status,
    receiver_name: log?.parsedExtraData?.resp?.assignedUserName,
    assignment_note: log?.parsedExtraData?.resp?.assignNotes,
    resolved_note: log?.parsedExtraData?.resp?.resolveNotes,
    unresolved_note: log?.parsedExtraData?.resp?.unresolveNotes,
  }
  switch (log?.action) {
    case 'ap2himastervessels_update':
      return `Updated a vessel named '${data.vessel_name}'`

    case 'ap2hi_mastervessels_create':
      return `Created a vessel named '${data.vessel_name}'`

    case 'ap2himastervessels_delete':
      return `Removed a vessel named '${data.vessel_name}'`

    case 'ap2hi_mastervessels_setusers':
      return `Linked a vessel named to fishermen`

    // case 'ap2hi_mastervessels_setusers':
    //   return data.fishermen.length > 1
    //     ? `Linked a vessel named '${data.vessel_name}' to fishermen`
    //     : `Linked a vessel named '${data.vessel_name}' to a fisherman`

    case 'ap2hi_mastervessels_unsetusers':
      return `Unlinked a vessel named '${data.vessel_name}' from '${data.fisherman_name}'`

    case 'ap2hi_fisheries_upsert':
      return `Added a fishery named '${data.fishery_name}'`

    case 'ap2hi_ports_upsert':
      return `Added a port named '${data.port_name}'`

    case 'ap2hi_companies_upsert':
      return `Added a company named '${data.company_name}'`

    case 'ap2hi_gears_upsert':
      return `Added a fishing gear named '${data.gear_name}'`

    case 'ap2hi_fisheries_update':
      return `Updated a fishery named '${data.fishery_name}'`

    case 'ap2hi_ports_update':
      return `Updated a port named '${data.port_name}'`

    case 'ap2hi_companies_update':
      return `Updated a company named '${data.company_name}'`

    case 'ap2hi_gears_update':
      return `Updated a fishing gear named '${data.gear_name}'`

    case 'ap2hi_fisheries_delete':
      return `Removed a fishery named '${data.fishery_name}'`

    case 'ap2hi_ports_delete':
      return `Removed a port named '${data.port_name}'`

    case 'ap2hi_companies_delete':
      return `Removed a company named '${data.company_name}'`

    case 'ap2hi_gears_delete':
      return `Removed a fishing gear named '${data.gear_name}'`

    case 'ap2hi_whistleblowings_create':
      return `Submitted a whistleblowing issue titled '${data.name}'`

    case 'ap2hi_whistleblowings_update':
      if (data.status === 'ASSIGNED') {
        return `Assigned whistleblowing issue titled '${data.name}' to ${data.receiver_name} with note: '${data.assignment_note}'`
      } else if (data.status === 'RESOLVED') {
        return `Marked whistleblowing issue titled '${data.name}' as resolved with note: '${data.resolved_note}'`
      } else if (data.status === 'UNRESOLVED') {
        return `Reassign whistleblowing issue titled '${data.name}' to ${data.receiver_name} with note: '${data.unresolved_note}'`
      }
      return `action ${log?.action}`

    case 'traceability_ap2hi_save':
      return `Trip has been created`
    case 'ap2hi_updateusercatchesfishes':
      return 'Update Berat tangkapan on Informasi hasil tangkapan'
    case 'ap2hi_approveusercatches':
      return 'Changed trip status to APPROVE'
    default:
      return `action ${log?.action}`
  }
}

export const parseExtraData = (extraData) => {
  try {
    if (!extraData) {
      return null
    }

    const cleanedExtraData = extraData
      .replace(/\\\"/g, '"')
      .replace(/\\\\/g, '\\')
      .replace(/^"|"$/g, '')

    const parsedData = JSON.parse(cleanedExtraData)

    return parsedData
  } catch (error) {
    return null
  }
}
