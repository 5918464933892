import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  isOpenModalAssign: false,
  alertMessage: null,
  alertType: 'success',
}

export const whistleBlowingsListSlice = createAppSlice({
  name: 'whistleBlowingsList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setAlert: create.reducer((state, { payload }) => {
      state.alertMessage = payload.message
      state.alertType = payload.type
    }),
    clearAlert: create.reducer((state) => {
      state.alertMessage = null
      state.alertType = 'success'
    }),
    setIsOpenModalAssign: create.reducer((state, { payload }) => {
      state.isOpenModalAssign = !state.isOpenModalAssign
    }),
  }),
})

export const { setFetchOption, setAlert, clearAlert, setIsOpenModalAssign } =
  whistleBlowingsListSlice.actions

export default whistleBlowingsListSlice.reducer
