import { Outlet } from 'react-router-dom'
import { Container } from 'reactstrap'
import NavigationBar from './NavigationBar'
import BreadCrumbs from 'components/breadcrumbs'

const Transactions = () => {
  return (
    <div className="w-100">
      <Container fluid className="custom-container">
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs breadCrumbActive={'Pembelian'} />
        </div>
        <NavigationBar />
      </Container>
      <Outlet />
    </div>
  )
}

export default Transactions
