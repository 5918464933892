import BreadCrumbs from 'components/breadcrumbs'
import { useMemo, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { useFetchCompaniesQuery } from '../list-companies/companiesListApiSlice'
import { clearAlert, setAlert } from '../list-companies/companiesListSlice'
import {
  useCreationCompaniesMutation,
  useUpdateCompaniesMutation,
} from './formApiSlice'
import { setPayloadSlice } from './formSlice'
import './style.css'

const FormAddCompanies = ({ row, onHandleOpenCloseModalEdit }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tag } = useSelector((s) => s.companiesList)

  const [creationCompanies, { isLoading: isLoadingCreate }] =
    useCreationCompaniesMutation()
  const [updateCompanies, { isLoading: isLoadingUpdate }] =
    useUpdateCompaniesMutation()

  let [page, setPage] = useState({ page: tag.page })

  const { refetch } = useFetchCompaniesQuery({
    page: page?.page,
    size: 10,
    searchvalue: '',
  })

  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState({})
  const [isClick, setIsClick] = useState(false)

  const handleHasAnswered = () => {
    const fieldRequireds = ['companyCode', 'companyName', 'abbr']

    const fieldRequired = fieldRequireds

    const data = fieldRequired?.map((e) => {
      return Object.prototype.hasOwnProperty.call(payload, e) && payload[e]
    })
    return data.every((e) => e)
  }

  const isEdit = row?.id

  const handleSubmit = async () => {
    try {
      const adjustedPayload = {
        code: payload.companyCode ?? null,
        name: payload.companyName ?? null,
        abbr: payload.abbr ?? null,
        profile: null, // NOT FOR AT THE MOMENT
        iuuList: payload.iuuList ?? false,
        codeOfConduct: payload.codeOfConduct ?? false,
      }

      const result = row
        ? await updateCompanies({ payload: adjustedPayload, id: row?.id })
        : await creationCompanies(adjustedPayload)

      if (result?.error) {
        dispatch(
          setAlert({
            type: 'danger',
            message: result?.error?.data?.meta?.message,
          }),
        )
        setErrMsg(result?.error?.data?.meta?.message)
      } else if (!result?.error) {
        if (isEdit) {
          onHandleOpenCloseModalEdit()
        }
        await refetch()

        dispatch(
          setAlert({
            type: 'success',
            message: `Perusahaan berhasil  ${isEdit ? 'dirubah' : 'ditambahkan'}!`,
          }),
        )

        setTimeout(() => {
          dispatch(clearAlert())
        }, 3000)

        navigate('/companies')
      }
    } catch (error) {
      console.warn(error)
    }
  }

  useMemo(() => {
    if (row) {
      setPayload(() => ({
        companyCode: row?.code ?? '',
        companyName: row?.name ?? '',
        abbr: row?.abbr ?? '',
        iuuList: row?.iuuList ?? false,
        codeOfConduct: row?.codeOfConduct ?? false,
      }))
    }
  }, [row])

  return (
    <Container className="custom-container d-flex flex-column gap-3 py-3">
      {!isEdit && (
        <div className="mt-3">
          <BreadCrumbs
            breadCrumbTitle="Master Perusahaan"
            breadCrumbParent2="Manajemen Perusahaan"
            breadCrumbParent2Link={'/companies'}
            breadCrumbActive={isEdit ? 'Ubah Perusahaan' : 'Tambah Perusahaan'}
          />
        </div>
      )}

      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <h5 className="mb-2">
            {isEdit ? 'Ubah Perusahaan' : 'Tambah Perusahaan'}
          </h5>

          <FormGroup>
            <Label className="text-sm">Kode Perusahaan</Label>
            <Input
              placeholder="Masukkan kode perusahaan"
              autoComplete="off"
              name="companyCode"
              id="companyCode"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.companyCode ?? ''}
              invalid={!payload?.companyCode && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Nama Perusahaan</Label>
            <Input
              placeholder="Masukkan nama perusahaan"
              autoComplete="off"
              name="companyName"
              id="companyName"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.companyName ?? ''}
              invalid={!payload?.companyName && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">ABBR</Label>
            <Input
              placeholder="Masukkan nama abbr"
              autoComplete="off"
              name="abbr"
              id="abbr"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.abbr ?? ''}
              invalid={!payload?.abbr && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="12" md="6">
                <div className="d-flex justify-content-between align-items-center p-2 rounded border">
                  <Label className="text-sm m-0">IUU</Label>
                  <Input
                    className="m-0"
                    type="checkbox"
                    name="iuuList"
                    id="iuuList"
                    checked={payload?.iuuList}
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }}
                  />
                </div>
              </Col>
              <Col xs="12" md="6" className="mt-4 mt-md-0">
                <div className="d-flex justify-content-between align-items-center p-2 rounded border">
                  <Label className="text-sm m-0">Code of Conduct</Label>
                  <Input
                    type="checkbox"
                    name="codeOfConduct"
                    id="codeOfConduct"
                    checked={payload?.codeOfConduct}
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }}
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
      </Card>

      <div className="d-flex gap-3">
        {!row && (
          <Button
            onClick={() => navigate('/companies')}
            outline
            color="primary"
            block
          >
            Batal
          </Button>
        )}
        <Button
          disabled={isLoadingCreate || isLoadingUpdate}
          onClick={() => {
            const res = handleHasAnswered()
            setIsClick(true)
            if (res) {
              setIsClick(false)
              dispatch(setPayloadSlice(payload))
              handleSubmit()
            }
          }}
          color="primary"
          block
        >
          Simpan
        </Button>
      </div>

      {errMsg && (
        <small className="text-danger">
          <AlertCircle size={15} /> {errMsg}
        </small>
      )}
    </Container>
  )
}

export default FormAddCompanies
