//farmers
export const API_FARMERS_LOGIN = 'api/v1/bussines/page/login-group'
export const API_FARMERS_REGISTER = 'api/v1/bussines/group/register-farmer'
export const API_FARMERS_LIST = 'api/v1/bussines/group/register-farmer'
export const API_FARMERS_PRECHECK = 'api/v1/bussines/group/precheck-farmer'
export const API_FARMERS_UPLOAD_FILE =
  'api/v1/bussines/group/upload-private-file-farmer'
export const API_FARMERS_OCR = 'api/v1/bussines/group/ocr-ktp-farmer'
export const API_FARMERS_PLOTTING = 'api/v1/bussines/group/landplot'
export const API_FARMERS_BANK_LIST = 'api/v1/bussines/group/banks'
export const API_FARMERS_PROVINCE_LIST = 'api/v1/public/wilayah/provinsi'
export const API_FARMERS_CITY_LIST = 'api/v1/public/wilayah/kabkota'
export const API_FARMERS_DISTRICT_LIST = 'api/v1/public/wilayah/kecamatan'
export const API_FARMERS_SUBDISTRICT_LIST = 'api/v1/public/wilayah/kelurahan'
export const API_FARMERS_POSTALCODE_LIST = 'api/v1/public/wilayah/kodepos'
export const API_CHECK_FARMERS = 'api/v1/bussines/group/precheck-farmer'
export const API_INVITE_FARMERS = 'api/v1/bussines/group/invite-farmer'
export const API_CHANGE_NAME = 'api/v1/bussines/group/management/:code/update'
export const API_GROUP_LIST = 'api/v1/bussines/group/management'
export const API_GROUP_COUNT = 'api/v1/bussines/group/management-count'
export const API_DELETE_GROUP = 'api/v1/bussines/group/management/:code'
export const API_ROLES_PMB = 'api/v1/bussines/role'
export const API_ROLES_FARMERS = 'api/v1/bussines/role?type=group'
export const API_SET_ROLE_PMB = 'api/v1/bussines/team/:userId/set-role'
export const API_SET_ROLE_FARMERS =
  'api/v1/bussines/group/register-farmer/:userId/set-role'

// VESSELS
export const API_VESSELS_LIST = 'api/v1/bussines/ap2hi/mastervessels'
export const API_VESSELS_ADD = 'api/v1/bussines/ap2hi/mastervessels'
export const API_VESSELS_DELETE = 'api/v1/bussines/ap2hi/mastervessels'
export const API_VESSELS_USERS = 'api/v1/bussines/ap2hi/mastervesselsusers'

// UPLOAD IMAGE PUBLIC
export const API_UPLOAD_IMAGE_PUBLIC = 'api/v1/upload/publicfile'

// CATCHES
export const API_USER_DETAIL_VESSEL = 'api/v1/bussines/ap2hi/uservessels'
export const API_CATCHES = 'api/v1/cms/ap2hi/usercatches'
export const API_BUSINESS_CATCHES = 'api/v1/bussines/ap2hi/usercatches'

// GEARS
export const API_GEARS = 'api/v1/bussines/ap2hi/gears'

// PORTS
export const API_PORTS = 'api/v1/bussines/ap2hi/ports'

// COMPANIES
export const API_COMPANIES = 'api/v1/bussines/ap2hi/companies'

// FISHERIES
export const API_FISHERIES = 'api/v1/bussines/ap2hi/fisheries'

// SPECIES
export const API_SPECIES_LIST = 'api/v1/bussines/ap2hi/config'

// BAITS
export const API_BAITS_LIST = 'api/v1/bussines/ap2hi/baits'

// WHISTLE BLOWINGS
export const API_WHISTLE_BLOWINGS = 'api/v1/bussines/ap2hi/whistleblowings'

// USERS TIM LIST
export const API_USERS_TIM_LIST = 'api/v1/bussines/team'
