import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { routes } from './routes'

import moment from 'moment-timezone'
import 'moment/locale/id'; // Untuk lokal Bahasa Indonesia

// Redux
import { Provider } from 'react-redux'
import { persistor, store } from './app/store'

// css
import './assets/scss/bootstrap.scss'
import reportWebVitals from './reportWebVitals'

import { AbilityProvider } from 'context/Acl'
import { AuthProvider } from 'context/Auth'

// App
// const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
moment.tz.setDefault('Asia/Jakarta')
const container = document.getElementById('root')

if (container) {
  const root = ReactDOM.createRoot(container)

  root.render(
    // <React.StrictMode>
    <IntlProvider locale="id" defaultFormats="id">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <AbilityProvider>
              <RouterProvider router={routes} />
            </AbilityProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>,
    // </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
