import { ButtonIcon } from 'components/Buttons'
import { useEffect } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import ICFilter from 'assets/icons/filter-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setFetchOption, toggleSidebarFilter } from '../dashboardSlice'
import { Filter } from 'react-feather'

const HeaderTable = ({ type, abilityBuy, abilitySell }) => {
  const dispatch = useDispatch()
  const { tag } = useSelector((s) => s.dashboard)

  useEffect(() => {
    if (type === undefined) {
      dispatch(
        setFetchOption({
          type: abilityBuy ? 'BUY' : abilitySell ? 'SELL' : '',
        }),
      )
    }
  }, [type, abilityBuy, abilitySell])

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <ButtonGroup>
          {abilityBuy && (
            <ButtonIcon
              btnTitle="Pembelian"
              btnTitleProps={{
                className: 'text-dark fw-medium fs-sm',
              }}
              btnProps={{
                size: 'sm',
                className: 'w-auto btn-dashboard-secondary',
                color: 'secondary',
                active: type === 'BUY',
                onClick: () => {
                  dispatch(
                    setFetchOption({
                      type: 'BUY',
                    }),
                  )
                },
              }}
            />
          )}

          {abilitySell && (
            <ButtonIcon
              btnTitle="Penjualan"
              btnTitleProps={{
                className: 'text-dark fw-medium fs-sm',
              }}
              btnProps={{
                size: 'sm',
                className: 'w-auto btn-dashboard-secondary',
                color: 'secondary',
                active: type === 'SELL',
                onClick: () => {
                  dispatch(
                    setFetchOption({
                      type: 'SELL',
                    }),
                  )
                },
              }}
            />
          )}
        </ButtonGroup>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => dispatch(toggleSidebarFilter())}
        >
          <div className="d-flex gap-2 align-items-center">
            <Filter size={20} />
            Filter
            {tag?.payment || tag?.status ? (
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2px',
                }}
                className="text-primary bg-primary-50"
              >
                <span>
                  {tag?.status && tag?.payment
                    ? '2'
                    : tag?.payment || tag?.status
                      ? '1'
                      : ''}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Button>
      </div>
      {/* <Col md="5">
    <div className="d-flex align-items-center  mb-1">
      <ButtonIcon
        btnTitle="Filter"
        icon={
          <img
            width="15px"
            height="10px"
            src={ICFilter}
            alt="icon"
            className="ml-2"
          />
        }
        btnProps={{
          className: 'w-auto d-flex me-2',
          size: 'sm',
          disabled: true,
        }}
      />
      <InputGroup className="input-group-merge" size="sm">
        <InputGroupText className="bg-transparent border-right-0">
          <Search size={18} />
        </InputGroupText>
        <Input
          className="d-flex align-items-center float-right border-left-0"
          type="text"
          bsSize="sm"
          id="search-input"
          placeholder="Cari"
          disabled
        />
      </InputGroup>
    </div>
  </Col> */}
    </div>
  )
}

export default HeaderTable
