import LoadingAnimation from 'components/LoadingAnimation'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Card, CardBody, Progress } from 'reactstrap'
import { generateGreetings } from 'utils/generateGreetings'
import { URL_KURSUS_DETAIL } from 'utils/pathUrl'
import {
  useFetchLearningQuery,
  useLazyFetchProgressQuery,
} from './learningApiSlice'

const Learning = () => {
  const profile = useOutletContext()
  const navigate = useNavigate()
  const { data, isLoading } = useFetchLearningQuery()
  const [getProgress, res] = useLazyFetchProgressQuery({ tutorialId: 0 })
  const [progressList, setProgressList] = useState([])
  const [combinedData, setCombinedData] = useState([])

  const getTutorialProgress = (tutorialId) => {
    const progressItem = progressList.find((p) => p.tutorialId === tutorialId)
    return progressItem ? progressItem?.overallProgress : 0
  }

  useEffect(() => {
    const fetchProgressData = async () => {
      if (data?.items?.length > 0) {
        const updatedCombinedData = []
        const updatedProgressList = []

        await Promise.all(
          data?.items?.map(async (item) => {
            try {
              const progressData = await getProgress({
                tutorialId: item.id,
              }).unwrap()

              const progress =
                progressData?.items?.length === 0
                  ? 0
                  : (() => {
                      const totalDuration =
                        progressData?.items?.reduce(
                          (total, progressItem) =>
                            total + progressItem?.duration,
                          0,
                        ) || 0

                      const totalContentDuration =
                        item?.tutorialcontent?.reduce(
                          (total, contentItem) =>
                            total + contentItem?.contentDuration,
                          0,
                        ) || 0

                      const tolerance = 3
                      const adjustedContentDuration =
                        totalContentDuration - tolerance

                      if (adjustedContentDuration <= 0) return 100

                      return Math.min(
                        (totalDuration / adjustedContentDuration) * 100,
                        100,
                      )
                    })() || 0

              const combinedTutorialContent = item?.tutorialcontent?.map(
                (contentItem) => {
                  const progressItem = progressData?.items?.find(
                    (progressItem) =>
                      progressItem?.tutorialContentId === contentItem?.id,
                  )

                  return {
                    ...contentItem,
                    progress: progressItem,
                  }
                },
              )

              updatedProgressList.push({
                tutorialId: item.id,
                overallProgress: progress.toFixed(0),
              })

              updatedCombinedData.push({
                ...item,
                tutorialcontent: combinedTutorialContent,
                overallProgress: progress.toFixed(0),
              })
            } catch (error) {
              console.log(error)

              updatedProgressList.push({
                tutorialId: item.id,
                overallProgress: 0,
              })

              updatedCombinedData.push({
                ...item,
                tutorialcontent: item?.tutorialcontent?.map((contentItem) => ({
                  ...contentItem,
                  progress: 0,
                })),
                overallProgress: 0,
              })
            }
          }),
        )

        setCombinedData(updatedCombinedData)
        setProgressList(updatedProgressList)
      }
    }

    if (data?.items?.length > 0) {
      fetchProgressData()
    }
  }, [data, getProgress])

  return (
    <div className="w-100 d-flex gap-2 flex-column">
      <Card body>
        <h4>
          {generateGreetings()}
          {profile?.isLoading ? (
            <LoadingAnimation size="sm" />
          ) : profile?.isSuccess ? (
            <>
              {`, `}
              {profile?.data?.makerData?.name}!
            </>
          ) : (
            <LoadingAnimation size="sm" />
          )}
        </h4>
        <p>
          Sudah siap luangkan waktu untuk belajar finansial? Ayo cek materi yang
          bisa kamu pelajari berikut!
        </p>
      </Card>
      <Card className="d-flex gap-2" body>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          data?.items?.map((e, i) => {
            const tutorialProgress = getTutorialProgress(e?.id)
            return (
              <div
                key={i}
                onClick={() =>
                  navigate(URL_KURSUS_DETAIL, {
                    state: combinedData.find((z) => z?.id === e?.id),
                  })
                }
              >
                <Card className="rounded">
                  <img
                    className="rounded-top"
                    style={{ height: '120px' }}
                    alt="logo"
                    src={e?.thumbnail}
                  />
                  <CardBody>
                    <div className="d-flex flex-column mb-2">
                      <span className="fw-semibold">{e?.title}:</span>
                      <span className="fw-semibold">{e?.description}</span>
                    </div>
                    <div className="d-flex gap-2 flex-column">
                      <small className="text-muted">
                        {e?.tutorialcontent?.length} materi
                      </small>
                      <Progress
                        color="primary"
                        value={tutorialProgress ?? 0}
                        style={{
                          height: '8px',
                        }}
                      />
                      <small className="text-end">
                        {tutorialProgress}% selesai
                      </small>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )
          })
        )}
      </Card>
    </div>
  )
}

export default Learning
