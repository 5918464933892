import { getActionDescription } from 'helpers'
import moment from 'moment-timezone'
import React from 'react'

export default function HistoryLine({ logs, showBorder = true }) {
  return (
    <div className="d-flex flex-column">
      {logs?.map((log, index) => {
        return (
          <div
            key={index}
            className={`d-flex timeline-item ${!showBorder ? 'no-border' : ''}`}
          >
            <div className="timeline-icon">
              <span className="circle-icon-wrap">
                <span className="circle-icon"></span>
              </span>
            </div>

            <div className="timeline-content">
              <div className="text-sm flex align-items-center gap-2">
                <span>{log.user?.name}</span>
                {' | '}
                <span style={{ color: '#475467' }}>
                  {moment(log.createdAt).format('DD MMM YYYY HH:mm')}
                </span>
              </div>
              <div className="mt-1">
                {getActionDescription(log)
                  .split(/(Berat tangkapan|Informasi hasil tangkapan|APPROVE)/)
                  .map((part, index) =>
                    part === 'APPROVE' ? (
                      <span key={index} style={{ color: '#05603A' }}>
                        {part}
                      </span>
                    ) : part === 'Berat tangkapan' ||
                      part === 'Informasi hasil tangkapan' ? (
                      <span key={index} style={{ fontWeight: 'bold' }}>
                        {part}
                      </span>
                    ) : (
                      <span key={index}>{part}</span>
                    ),
                  )}
              </div>
              {log?.action === 'ap2hi_updateusercatchesfishes' &&
                log?.parsedExtraData?.before?.data?.fishes?.map((el, i) => {
                  if (
                    el?.amount !==
                    log?.parsedExtraData?.resp?.data?.fishes[i]?.amount
                  ) {
                    return (
                      <div className="text-xs" style={{ color: 'grey' }}>
                        <p className="mt-2 m-0">
                          - Berat tangkapan {el?.id} is changing from{' '}
                          {el?.amount} kg to{' '}
                          {log?.parsedExtraData?.resp?.data?.fishes[i]?.amount}{' '}
                          kg
                        </p>
                      </div>
                    )
                  }
                })}
              {log?.action === 'ap2hi_approveusercatches' &&
              log?.parsedExtraData?.req?.notes ? (
                <div
                  className="text-xs mt-1"
                  style={{
                    backgroundColor: '#F2F4F7',
                    padding: 15,
                    borderRadius: 7,
                  }}
                >
                  <span>{log?.parsedExtraData?.req?.notes}</span>
                </div>
              ) : null}
            </div>
          </div>
        )
      })}
    </div>
  )
}
