import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { useEditRelationMutation } from './payableNotesApiSlice'

const ModalAdjustSavedContact = ({ isOpen, toggle, row, handleEdit }) => {
  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState(null)

  const [editRelation, result] = useEditRelationMutation()

  const handleSubmit = async () => {
    try {
      const body = {
        relationName: payload?.relationName,
        relationPhoneNumber: payload?.relationPhoneNumber,
        id: payload?.id,
      }
      const resp = await editRelation(body).unwrap()

      if (!resp?.data?.error) {
        toggle()
        handleEdit()
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (row && isOpen) {
      setPayload(row)
    } else {
      setPayload(null)
    }
  }, [row, isOpen])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpen}
      toggle={() => {
        toggle()
        setErrMsg('')
      }}
    >
      <ModalBody>
        <h5 className="mb-4">Daftar Tersimpan</h5>
        <FormGroup>
          <Label>
            Nama
            <span className="text-danger">*</span>
          </Label>
          <Input
            id="relationName"
            name="relationName"
            placeholder="Masukkan nama"
            value={payload?.relationName ?? ''}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                [e.target.name]: e.target?.value,
              }))
              setErrMsg('')
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Nomor handphone</Label>
          <Input
            placeholder="Masukkan nomor handphone"
            value={payload?.relationPhoneNumber ?? ''}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                [e.target.name]: e.target?.value,
              }))
              setErrMsg('')
            }}
            name="relationPhoneNumber"
            id="relationPhoneNumber"
          />
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <CustomButton
            onClick={() => {
              toggle()
              setErrMsg('')
            }}
            disabled={result?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={!payload?.relationName || result?.isLoading}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Simpan
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalAdjustSavedContact
