import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  payload: {},
}

export const formCompaniesSlice = createAppSlice({
  name: 'companiesForm',
  initialState,
  reducers: (create) => ({
    setPayloadSlice: create.reducer((state, { payload }) => {
      state.payload = payload
    }),
    clearState: create.reducer((state) => {
      state.payload = {}
    }),
  }),
})

export const { setPayloadSlice, clearState } = formCompaniesSlice.actions

export default formCompaniesSlice.reducer
