import { createApiSlice } from 'app/createApiSlice'
import {
  API_BUSINESS_CATCHES,
  API_CATCHES,
  API_VESSELS_LIST,
} from 'utils/apiList'

export const detailCatchesApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'catches/detail',

  endpoints(builder) {
    return {
      fetchUserCatchDetail: builder.query({
        query({ id }) {
          return {
            url: API_CATCHES,
            params: {
              id,
            },
          }
        },
      }),
      fetchVesselByShipNumber: builder.query({
        query({ shipNumber }) {
          return `${API_VESSELS_LIST}/${shipNumber}`
        },
      }),
      updateFishById: builder.mutation({
        query({ payload, id }) {
          return {
            url: `${API_BUSINESS_CATCHES}/fishes/${id}`,
            method: 'POST',
            body: payload,
          }
        },
      }),
    }
  },
})

export const {
  useFetchUserCatchDetailQuery,
  useFetchVesselByShipNumberQuery,
  useUpdateFishByIdMutation,
} = detailCatchesApiSlice
