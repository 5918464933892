import CheckIcon from 'assets/icons/check-circle-success.svg'
import ICThunderDisable from 'assets/icons/thunder-disable.svg'
import ICThunder from 'assets/icons/thunder-icon.svg'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { useFetchSaldoQuery } from 'features/private-views/dashboard/dashboardApiSlice'
import { addBalance } from 'features/private-views/dashboard/dashboardSlice'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Collapse,
  Container,
  FormGroup,
  Input,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap'
import uangBelanja from '../../../assets/icons/uang-belanja.png'
import uangUsaha from '../../../assets/icons/uang-usaha.png'
import { apiEndpoint } from '../../../utility/Utils'
import { setNumberStep, setTotal } from '../commodityPurchaseSlice'
import { getPricingDisbursment } from '../purchaseAction'
import Total from '../Total'
import ModalPassword from './ModalPassword'
import ModalReceive from './ModalReceive'

const ConfirmPayment = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const location = useLocation()
  const dataState = location.state
  const setNumber = (e) => dispatch(setNumberStep(e))
  const { token } = useSelector((e) => e.auth)
  const { balance } = useSelector((s) => s.dashboard)
  const { pricing, isLoading } = useSelector((s) => s.comodityPurchase)
  const [isInstant, setIsInstant] = useState(false)
  const [selectedPricing, setSelectedPricing] = useState(null)
  const [userData, setUserData] = useState({})
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dataProfile, setDataProfile] = useState({})
  const [isOpenModalReceive, setIsOpenModalReceive] = useState(false)
  const { isSuccess, data, isError } = useFetchSaldoQuery()
  const toggleModalReceive = () => {
    setIsOpenModalReceive(!isOpenModalReceive)
  }
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const handleSubmit = () => {
    toggleModal()
  }

  const getProfile = async () => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/profile`,
        { headers: { Authorization: `Bearer ${token}` } },
      )
      setDataProfile(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleIsInstant = () => {
    setIsInstant(!isInstant)
    setSelectedPricing(null)
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const isInstant = JSON.parse(localStorage.getItem('PMB_isInstant'))
    if (isInstant && userData?.alreadyReceived) {
      setIsInstant(true)
      setSelectedPricing(isInstant)
    }
    setUserData(userData)
    setNumber(3)
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userData?.alreadyReceived) {
      dispatch(
        getPricingDisbursment({
          sellerUserId: userData?.dataSeller?.userId,
          amount: userData?.finalTotalAmount,
        }),
      )
    }
    dispatch(
      setTotal(
        userData?.repo?.useAutorepo
          ? userData?.totalAmount +
              (userData?.feeTx?.haveFreeTx > 0 ? 0 : userData?.feeTx?.feeTx)
          : userData?.finalTotalAmount,
      ),
    )
  }, [userData])

  useEffect(() => {
    if (selectedPricing) {
      dispatch(
        setTotal(
          (userData?.repo?.useAutorepo
            ? userData?.totalAmount +
              (userData?.feeTx?.haveFreeTx > 0 ? 0 : userData?.feeTx?.feeTx)
            : userData?.finalTotalAmount) + selectedPricing?.fee,
        ),
      )
    } else {
      dispatch(
        setTotal(
          userData?.repo?.useAutorepo
            ? userData?.totalAmount +
                (userData?.feeTx?.haveFreeTx > 0 ? 0 : userData?.feeTx?.feeTx)
            : userData?.finalTotalAmount,
        ),
      )
    }
  }, [selectedPricing])

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(addBalance(data))
    }
  }, [isSuccess, data])

  return (
    <div>
      <ModalPassword
        toggle={toggleModal}
        isOpen={isOpenModal}
        dataProfile={dataProfile}
        userData={userData}
        dataState={userData}
        selectedPricing={selectedPricing}
      />
      <ModalReceive
        toggle={toggleModalReceive}
        isOpen={isOpenModalReceive}
        handleSubmit={handleSubmit}
      />
      <Container fluid className="custom-container">
        <Card className="p-2">
          <CardBody>
            <div className="mb-5">
              <div className="mb-3 d-flex justify-content-between">
                <span className="fw-bold">Daftar Pembelian</span>
                {userData?.alreadyReceived ? (
                  <>
                    {' '}
                    <div className="text-success-700 rounded bg-success-50 p-1">
                      {' '}
                      <small className="fw-500 mx-1">
                        <img src={CheckIcon} alt="check-icon" /> Barang sudah
                        diterima
                      </small>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {userData?.item?.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex flex-row gap-3 align-items-center mb-3"
                  >
                    <img
                      src={e?.comodity?.img ?? e?.comodity?.linkFoto}
                      className="rounded float-left"
                      style={{ width: '60px', height: '60px' }}
                      alt="komoditas"
                    />
                    <div className="d-flex flex-column">
                      <span>
                        {e?.comodity?.label} {e?.amount} {e?.unit}
                      </span>
                      <span style={{ fontWeight: 'bold' }}>
                        Rp{' '}
                        {intl.formatNumber(
                          userData?.vat?.enableVAT
                            ? String(e?.priceAfterVAT)?.includes('.') ||
                              String(e?.priceAfterVAT)?.includes(',')
                              ? Number(
                                  e?.priceAfterVAT
                                    ?.replace?.(/\./g, '')
                                    ?.replace?.(/\,/g, '.')
                                    ?.slice?.(0, 15),
                                )
                              : e?.priceAfterVAT
                            : (String(e?.price)?.includes('.') ||
                              String(e?.price)?.includes(',')
                                ? Number(
                                    e?.price
                                      ?.replace?.(/\./g, '')
                                      ?.replace?.(/\,/g, '.')
                                      ?.slice?.(0, 15),
                                  )
                                : e?.price) *
                                (String(e?.amount)?.includes('.') ||
                                String(e?.amount)?.includes(',')
                                  ? Number(
                                      e?.amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    )
                                  : e?.amount),
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            {userData?.alreadyReceived ? (
              <Card body className="mb-3">
                <div className="d-flex justify-content-between">
                  <CardText tag="h6" className="fw-bold">
                    Pembayaran Instan
                  </CardText>
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <FormGroup switch>
                      <Input
                        type="switch"
                        role="switch"
                        onChange={handleIsInstant}
                        checked={isInstant}
                      />
                    </FormGroup>
                  )}
                </div>
                <Collapse isOpen={isInstant}>
                  <div className="flex-centered justify-content-between gap-1">
                    <div className="d-flex flex-column gap-3">
                      {pricing?.items?.map((e, i) => {
                        if (e?.fee > 0) {
                          return (
                            <div key={i}>
                              <Card
                                style={{
                                  backgroundColor:
                                    selectedPricing?.id === e?.id
                                      ? '#ECFAFF'
                                      : e?.disable
                                        ? '#F9FAFB'
                                        : '',
                                }}
                                className={`${
                                  selectedPricing?.id === e?.id
                                    ? 'border-primary'
                                    : ''
                                }`}
                                onClick={() => {
                                  if (!e?.disable) {
                                    setSelectedPricing(e)
                                  }
                                }}
                              >
                                <CardBody className="pb-0">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row gap-2">
                                      <div>
                                        <img
                                          src={
                                            e?.disable
                                              ? ICThunderDisable
                                              : ICThunder
                                          }
                                          alt="kilat"
                                          width={40}
                                          height={40}
                                        />
                                      </div>
                                      <div>
                                        <span style={{ fontWeight: '500' }}>
                                          Instan {e?.title} (Rp{' '}
                                          {intl.formatNumber(e?.fee, {
                                            useGrouping: 'always',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                          })}
                                          )
                                        </span>
                                        <p
                                          style={{
                                            color:
                                              selectedPricing?.id === e?.id
                                                ? '#006386'
                                                : '',
                                          }}
                                        >
                                          {e?.disable ? (
                                            <span style={{ color: '#B42318' }}>
                                              {e?.disableReason}
                                            </span>
                                          ) : (
                                            <span>{e.description}</span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <Input
                                      type="radio"
                                      disabled={e?.disable}
                                      checked={selectedPricing?.id === e?.id}
                                      onChange={() => {
                                        if (!e?.disable) {
                                          setSelectedPricing(e)
                                        }
                                      }}
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                </Collapse>
              </Card>
            ) : (
              <></>
            )}
            <Card>
              <CardBody>
                <CardTitle tag="h6" className="fw-bold">
                  Ringkasan Pembelian
                </CardTitle>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <p>Harga Komoditas</p>
                    <p>
                      Rp
                      {intl.formatNumber(
                        userData?.vat?.enableVAT
                          ? userData?.item?.reduce(
                              (sum, e) => sum + e.priceAfterVAT,
                              0,
                            )
                          : userData?.totalAmount,
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Biaya Transaksi</p>
                    <div className="d-flex justify-content-around gap-2">
                      {userData?.feeTx?.haveFreeTx > 0 && (
                        <p className="text-secondary text-decoration-line-through">
                          Rp
                          {intl.formatNumber(
                            userData?.feeTx?.haveFreeTx > 0
                              ? userData?.feeTx?.feeTx -
                                  userData?.feeTx?.feeTxVAT
                              : userData?.feeTx?.feeTx,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </p>
                      )}
                      <p>
                        Rp
                        {intl.formatNumber(
                          userData?.feeTx?.haveFreeTx > 0
                            ? 0
                            : userData?.feeTx?.feeTx -
                                userData?.feeTx?.feeTxVAT,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </p>
                    </div>
                  </div>
                  {selectedPricing && userData?.alreadyReceived ? (
                    <>
                      {' '}
                      <div className="d-flex  justify-content-between">
                        <p>Biaya pembayaran instan</p>
                        <div className="d-flex justify-content-around gap-2">
                          <p>
                            Rp
                            {intl.formatNumber(selectedPricing?.fee, {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="d-flex justify-content-between ">
                    <div>
                      <span>PPN</span>{' '}
                      <AlertCircle
                        style={{ color: '#667085' }}
                        size={20}
                        id="ppn"
                      />
                      <UncontrolledTooltip
                        className="text-secondary"
                        placement="bottom"
                        target="ppn"
                      >
                        PPN akan dikenakan sesuai dengan ketentuan
                        perundang-undangan yang berlaku di Indonesia
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex justify-content-around gap-2">
                      {userData?.feeTx?.haveFreeTx > 0 && (
                        <p className="text-secondary text-decoration-line-through">
                          Rp
                          {intl.formatNumber(
                            userData?.vat?.totalVAT + userData?.feeTx?.feeTxVAT,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </p>
                      )}
                      <p>
                        Rp{' '}
                        {intl.formatNumber(
                          userData?.feeTx?.haveFreeTx > 0
                            ? userData?.vat?.totalVAT
                            : userData?.vat?.totalVAT +
                                userData?.feeTx?.feeTxVAT,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p style={{ fontWeight: 'bold' }}>Total Transaksi</p>
                    <p style={{ fontWeight: 'bold' }}>
                      Rp
                      {intl.formatNumber(
                        selectedPricing
                          ? (userData?.repo?.useAutorepo
                              ? userData?.totalAmount +
                                (userData?.feeTx?.haveFreeTx > 0
                                  ? 0
                                  : userData?.feeTx?.feeTx)
                              : userData?.finalTotalAmount) +
                              selectedPricing?.fee
                          : userData?.repo?.useAutorepo
                            ? userData?.totalAmount +
                              (userData?.feeTx?.haveFreeTx > 0
                                ? 0
                                : userData?.feeTx?.feeTx)
                            : userData?.finalTotalAmount,
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="mt-3">
              <h6 style={{ fontWeight: 'bold' }}>Metode Pembayaran</h6>
              <div className="d-flex flex-row gap-3">
                {userData?.useTalangin && (
                  <div
                    style={{
                      backgroundColor: '#FFFAEB',
                    }}
                    className="d-flex flex-row justify-content-between align-items-center p-3 rounded flex-grow-1"
                  >
                    <div className="d-flex flex-row gap-2">
                      <img
                        style={{ height: '32px', width: '32px' }}
                        src={uangUsaha}
                        alt="talangin"
                      />
                      <div className="d-flex flex-column">
                        <span style={{ fontWeight: 'bold' }}>Talangin</span>
                        <span>
                          Rp
                          {intl.formatNumber(userData?.maxCreditLimit ?? 0, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: '#ECFAFF',
                  }}
                  className="d-flex flex-row justify-content-between align-items-center p-3 rounded flex-grow-1"
                >
                  <div className="d-flex flex-row gap-2">
                    <img
                      style={{ height: '32px', width: '32px' }}
                      src={uangBelanja}
                      alt="talangin"
                    />
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 'bold' }}>Uang Belanja</span>
                      {(userData?.repo?.useAutorepo
                        ? userData?.totalAmount +
                          (userData?.feeTx?.haveFreeTx > 0
                            ? 0
                            : userData?.feeTx?.feeTx)
                        : userData?.finalTotalAmount) +
                        (selectedPricing?.fee ?? 0) -
                        (userData?.useTalangin ? userData?.maxCreditLimit : 0) >
                      balance?.uangBelanja ? (
                        <span className="text-danger">
                          Kurang Rp
                          {intl.formatNumber(
                            userData?.useTalangin
                              ? (userData?.repo?.useAutorepo
                                  ? userData?.totalAmount +
                                    (userData?.feeTx?.haveFreeTx > 0
                                      ? 0
                                      : userData?.feeTx?.feeTx)
                                  : userData?.finalTotalAmount) +
                                  (selectedPricing?.fee ?? 0) -
                                  userData?.maxCreditLimit -
                                  balance?.uangBelanja
                              : (userData?.repo?.useAutorepo
                                  ? userData?.totalAmount +
                                    (userData?.feeTx?.haveFreeTx > 0
                                      ? 0
                                      : userData?.feeTx?.feeTx)
                                  : userData?.finalTotalAmount) +
                                  (selectedPricing?.fee ?? 0) -
                                  balance?.uangBelanja,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </span>
                      ) : (
                        <span>
                          Rp
                          {intl.formatNumber(
                            userData?.useTalangin
                              ? (userData?.repo?.useAutorepo
                                  ? userData?.totalAmount +
                                    (userData?.feeTx?.haveFreeTx > 0
                                      ? 0
                                      : userData?.feeTx?.feeTx)
                                  : userData?.finalTotalAmount) +
                                  (selectedPricing?.fee ?? 0) -
                                  userData?.maxCreditLimit
                              : (userData?.repo?.useAutorepo
                                  ? userData?.totalAmount +
                                    (userData?.feeTx?.haveFreeTx > 0
                                      ? 0
                                      : userData?.feeTx?.feeTx)
                                  : userData?.finalTotalAmount) +
                                  (selectedPricing?.fee ?? 0),
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  {(userData?.repo?.useAutorepo
                    ? userData?.totalAmount +
                      (userData?.feeTx?.haveFreeTx > 0
                        ? 0
                        : userData?.feeTx?.feeTx)
                    : userData?.finalTotalAmount) +
                    (selectedPricing?.fee ?? 0) -
                    (userData?.useTalangin ? userData?.maxCreditLimit : 0) >
                  balance?.uangBelanja ? (
                    <div>
                      <CustomButton
                        onClick={() =>
                          navigate('/topup', { state: { payment: true } })
                        }
                        outline
                        color="primary"
                      >
                        Tambah Uang Belanja
                      </CustomButton>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Total
        text="Bayar Sekarang"
        isDisable={
          (userData?.repo?.useAutorepo
            ? userData?.totalAmount +
              (userData?.feeTx?.haveFreeTx > 0 ? 0 : userData?.feeTx?.feeTx)
            : userData?.finalTotalAmount) +
            (selectedPricing?.fee ?? 0) -
            (userData?.useTalangin ? userData?.maxCreditLimit : 0) >
          balance?.uangBelanja
        }
        handleClick={() => {
          if (userData?.alreadyReceived) {
            if (localStorage?.getItem('PMB_modalReceived')) {
              handleSubmit()
            } else {
              toggleModalReceive()
            }
          } else {
            handleSubmit()
          }
        }}
      />
    </div>
  )
}

export default ConfirmPayment
