import * as FileSaver from 'file-saver'
import moment from 'moment-timezone'

// FUNCTION TO CSV
export const handleDownloadCSV = (
  data,
  setIsDialogShow,
  setFirstRows,
  setTotalRows,
) => {
  setIsDialogShow(true)
  setFirstRows(0)
  const totalItems = data?.totalItems || 0
  setTotalRows(totalItems)

  const separator = ','

  // HEADER CSV
  let csvString = `Lama Trip${separator}Keberangkatan${separator}Koordinat Keberangkatan${separator}WPP${separator}Nama Kapal${separator}Kapten${separator}Nomor Kapal${separator}Total Tangkapan${separator}Total Alih Muat${separator}Berat Bersih${separator}FAO Code${separator}Jumlah Ikan${separator}Waktu Tangkapan${separator}Koordinat Penangkapan\n`

  // START EXPORTING
  exportFile(
    0,
    data,
    totalItems,
    setFirstRows,
    (chunk) => {
      chunk.forEach((row) => {
        const {
          startFishing,
          endFishing,
          vessel,
          fishes,
          moveReceipment,
          totalAmount,
        } = row?.data || {}

        const formattedStartFishing =
          moment(startFishing).format('DD MMMM YYYY HH:mm')
        const formattedEndFishing =
          moment(endFishing).format('DD MMMM YYYY HH:mm')

        const departureCoordinates =
          fishes?.[0]?.latitude && fishes?.[0]?.longitude
            ? `${fishes[0].latitude} - ${fishes[0].longitude}`
            : '-'

        const tripDuration = moment
          .duration(moment(endFishing).diff(moment(startFishing)))
          .humanize()

        const catchAreas =
          row?.data?.fishes?.length > 0
            ? [
                ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
              ].join(', ')
            : '-'

        const totalTransferredAmount = moveReceipment?.length
          ? moveReceipment?.reduce((total, receip) => {
              return (
                total +
                receip.fishes.reduce(
                  (subTotal, fish) => subTotal + fish.amount,
                  0,
                )
              )
            }, 0)
          : 0

        const totalNetWeight = moveReceipment?.length
          ? totalAmount + totalTransferredAmount
          : totalAmount

        // Add fishes to CSV
        fishes?.forEach((fish) => {
          const { attribute } = fish?.selectedFish || {}
          const faoCode = attribute?.code ?? '-'
          const fishAmount = fish.amount ?? 0
          const formattedFishingDate = moment(fish?.createdAt).format(
            'DD MMMM YYYY HH:mm',
          )
          const fishCoordinates =
            fish?.latitude && fish?.longitude
              ? `${fish.latitude} - ${fish.longitude}`
              : '-'

          csvString += `${tripDuration}${separator}${formattedStartFishing} - ${formattedEndFishing}${separator}${departureCoordinates}${separator}${catchAreas}${separator}${vessel?.shipName ?? '-'}${separator}${vessel?.captain ?? '-'}${separator}${vessel?.shipNumber ?? '-'}${separator}${totalAmount}${separator}${totalTransferredAmount}${separator}${totalNetWeight}${separator}${faoCode}${separator}${fishAmount}${separator}${formattedFishingDate}${separator}${fishCoordinates}\n`
        })
      })
    },
    () => {
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
      FileSaver.saveAs(
        blob,
        `Data_Tangkapan_Report_${moment().format('YYYY-MM-DD')}.csv`,
      )
      setIsDialogShow(false)
    },
    (error) => {
      console.error('Export error:', error)
      setIsDialogShow(false)
    },
  )
}

// FUNCTION TO JSON
export const handleDownloadJSON = (
  data,
  setIsDialogShow,
  setFirstRows,
  setTotalRows,
) => {
  setIsDialogShow(true)
  setFirstRows(0)
  const totalItems = data?.totalItems || 0
  setTotalRows(totalItems)

  const jsonOutput = []

  // START EXPORTING
  exportFile(
    0,
    data,
    totalItems,
    setFirstRows,
    (chunk) => {
      chunk.forEach((row) => {
        const { startFishing, vessel, fishes, moveReceipment } = row?.data || {}

        // LOOP THROUGH EACH FISH TO CREATE A SEPARATE EVENT FOR EACH FISH
        fishes?.forEach((fish) => {
          // EVENT FOR FISHING (PENANGKAPAN)
          const fishingEvent = {
            eventType: 'ObjectEvent',
            eventTime: moment(startFishing).format('YYYY-MM-DDTHH:mm:ssZ'),
            eventTimeZoneOffset: '+00:00',
            epcList: [
              `urn:epc:id:sgtin:${fish.selectedFish?.attribute?.code}.${fish.amount}`,
            ],
            action: 'OBSERVE',
            bizStep: 'urn:epcglobal:cbv:bizstep:fishing',
            disposition: 'urn:epcglobal:cbv:disp:active',
            readPoint: {
              id: `urn:epc:id:sgln:${vessel?.shipNumber}`,
            },
            bizLocation: {
              id: `urn:epc:id:sgln:${vessel?.shipName}`,
            },
            ilmd: {
              fishSpecies: fish?.selectedFish?.nameItem,
              catchMethod: '-',
              vesselID: vessel?.shipNumber,
              catchDate: moment(startFishing).format('YYYY-MM-DDTHH:mm:ssZ'),
              catchLocation: {
                latitude: fish?.latitude,
                longitude: fish?.longitude,
              },
              fishWeight: {
                value: fish?.amount,
                unit: 'kg',
              },
            },
          }

          jsonOutput.push(fishingEvent)
        })

        // ADDITIONAL EVENT FOR TRANSSHIPMENT (PEMINDHANAN)
        if (moveReceipment && moveReceipment.length > 0) {
          moveReceipment.forEach((receipt) => {
            receipt.fishes?.forEach((fish) => {
              const transshipEvent = {
                eventType: 'ObjectEvent',
                eventTime: moment(receipt.createdAt).format(
                  'YYYY-MM-DDTHH:mm:ssZ',
                ),
                eventTimeZoneOffset: '+00:00',
                epcList: [
                  `urn:epc:id:sgtin:${fish.selectedFish?.attribute?.code}.${fish.weight}`,
                ],
                action: 'OBSERVE',
                bizStep: 'urn:epcglobal:cbv:bizstep:transshipment',
                disposition: 'urn:epcglobal:cbv:disp:active',
                readPoint: {
                  id: `urn:epc:id:sgln:${receipt.shipName}`,
                },
                bizLocation: {
                  id: `urn:epc:id:sgln:Location_for_Transshipment`,
                },
                ilmd: {
                  fishSpecies: fish?.selectedFish?.nameItem,
                  transshipMethod: 'Transfer',
                  vesselID: receipt.shipName,
                  transshipDate: moment(receipt.createdAt).format(
                    'YYYY-MM-DDTHH:mm:ssZ',
                  ),
                  transshipLocation: {
                    latitude: receipt.latitude,
                    longitude: receipt.longitude,
                  },
                  fishWeight: {
                    value: fish?.weight ?? 0,
                    unit: 'kg',
                  },
                },
              }

              jsonOutput.push(transshipEvent)
            })
          })
        }
      })
    },
    () => {
      const blob = new Blob(
        [JSON.stringify({ epcisBody: { eventList: jsonOutput } }, null, 2)],
        { type: 'application/json;charset=utf-8;' },
      )
      FileSaver.saveAs(
        blob,
        `Data_Tangkapan_Report_GDST_${moment().format('YYYY-MM-DD')}.json`,
      )
      setIsDialogShow(false)
    },
    (error) => {
      console.error('Export error:', error)
      setIsDialogShow(false)
    },
  )
}

// FUNCTION EXPORT FILE
const exportFile = (
  currentPage,
  data,
  totalRows,
  setFirstRows,
  onReceiveChunk,
  onFinished,
  onError,
) => {
  const perPage = 10
  const totalPages = Math.ceil(totalRows / perPage)

  // SIMULATE FETCHING DATA IN CHUNKS
  setTimeout(() => {
    const chunk = data?.items.slice(
      currentPage * perPage,
      (currentPage + 1) * perPage,
    )
    setFirstRows((currentPage + 1) * perPage)
    onReceiveChunk(chunk)

    if (currentPage < totalPages - 1) {
      exportFile(
        currentPage + 1,
        data,
        totalRows,
        setFirstRows,
        onReceiveChunk,
        onFinished,
        onError,
      )
    } else {
      onFinished()
    }
  }, 3000)
}
