import PmgLogo from 'assets/images/pmg-logo-beta.png'
import SidebarDetails from 'components/SidebarDetails'
import { Can } from 'context/Acl'
import { useFetchApprovalCountQuery } from 'features/private-views/approval/approvalApiSlice'
import { setCountTag } from 'features/private-views/approval/approvalSlice'
import { toggleNavbar } from 'features/private-views/dashboard/dashboardSlice'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'
import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Container,
  Nav,
  NavItem,
  UncontrolledTooltip,
} from 'reactstrap'
import { BUSINESS_MYAPPROVAL } from 'utils/subjectActions'
import { menus } from './menus'
import { menusV3 } from './menusV3'

const NavbarSide = () => {
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess } = useFetchApprovalCountQuery()
  const { isOpenNavbar } = useSelector((s) => s.dashboard)
  const profile = useGetMyProfileAvaQuery({ skip: false })
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { isV3 } = useSelector((s) => s.dashboard)

  const menu = isV3 ? menusV3 : menus

  useEffect(() => {
    if (isSuccess) dispatch(setCountTag(data?.data))
  }, [dispatch, data, isSuccess])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  return (
    <>
      {screenWidth < 1100 ? (
        <SidebarDetails
          open={isOpenNavbar}
          headerClassName="mb-1"
          contentClassName="pt-0"
          isNavbar
          toggleSidebar={() => dispatch(toggleNavbar())}
          title={
            <div className="d-flex flex-column">
              <img
                alt="PasarMIKRO logo"
                src={PmgLogo}
                style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
              />
            </div>
          }
          noClose
        >
          <Nav vertical className="list-unstyled pb-3">
            {menu?.map((e, i) =>
              e.action ? (
                <Can key={`menu-${i}`} I={e.action} this={e.subject}>
                  <div className="d-flex flex-column gap-3 my-4">
                    <small className="text-secondary fw-semibold">
                      {e.headTitle}
                    </small>
                    {e?.items?.map((item, j) => {
                      if (
                        item?.path === '/manage-stock' &&
                        profile?.data?.makerData?.status !== 'VERIFIED'
                      ) {
                        return null
                      }
                      return item.action ? (
                        <Can
                          I={item.action}
                          this={item.subject}
                          key={`item-${i}-${j}`}
                        >
                          <NavItem className="hover rounded">
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? !isOpenNavbar
                                    ? 'nav-active justify-content-center'
                                    : 'nav-active'
                                  : !isActive && !isOpenNavbar
                                    ? 'nav-unactive justify-content-center'
                                    : 'nav-unactive'
                              }
                              to={item?.path}
                              style={{
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {item?.icon}
                              {!isOpenNavbar && (
                                <UncontrolledTooltip
                                  placement="right"
                                  target={item?.title
                                    .toLowerCase()
                                    ?.replace(' ', '-')}
                                >
                                  {item?.title}
                                </UncontrolledTooltip>
                              )}
                              {isOpenNavbar && <small>{item?.title}</small>}
                              {item.subject === BUSINESS_MYAPPROVAL &&
                                isOpenNavbar && (
                                  <span
                                    style={{
                                      height: 22,
                                      width: 'auto',
                                      textDecoration: 'none',
                                    }}
                                    className="rounded-circle badge-cancel flex-centered text-sm"
                                  >
                                    {isSuccess
                                      ? data?.data
                                          ?.map((e) => e.total)
                                          ?.reduce((sum, e) => sum + e, 0)
                                      : isLoading
                                        ? 0
                                        : 0}
                                  </span>
                                )}
                            </NavLink>
                          </NavItem>
                        </Can>
                      ) : (
                        <NavItem
                          className="hover rounded"
                          key={`item-${i}-${j}`}
                        >
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? !isOpenNavbar
                                  ? 'nav-active justify-content-center'
                                  : 'nav-active'
                                : !isActive && !isOpenNavbar
                                  ? 'nav-unactive justify-content-center'
                                  : 'nav-unactive'
                            }
                            to={item?.path}
                            style={{
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            }}
                          >
                            {item?.icon}
                            {!isOpenNavbar && (
                              <UncontrolledTooltip
                                placement="right"
                                target={item?.title
                                  .toLowerCase()
                                  ?.replace(' ', '-')}
                              >
                                {item?.title}
                              </UncontrolledTooltip>
                            )}
                            {isOpenNavbar && <small>{item?.title}</small>}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </div>
                </Can>
              ) : (
                <div
                  key={`menu-${i}`}
                  className="d-flex flex-column gap-3 my-4"
                >
                  <small className="text-secondary fw-semibold">
                    {e.headTitle}
                  </small>
                  {e?.items?.map((item, j) => (
                    <NavItem className="hover rounded" key={`item-${i}-${j}`}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? !isOpenNavbar
                              ? 'nav-active justify-content-center'
                              : 'nav-active'
                            : !isActive && !isOpenNavbar
                              ? 'nav-unactive justify-content-center'
                              : 'nav-unactive'
                        }
                        to={item?.path}
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item?.icon}
                        {!isOpenNavbar && (
                          <UncontrolledTooltip
                            placement="right"
                            target={item?.title
                              .toLowerCase()
                              ?.replace(' ', '-')}
                          >
                            {item?.title}
                          </UncontrolledTooltip>
                        )}
                        {isOpenNavbar && <small>{item?.title}</small>}
                      </NavLink>
                    </NavItem>
                  ))}
                </div>
              ),
            )}
            <div className="d-flex justify-content-center">
              <Button
                size="sm"
                style={{
                  width: '25px',
                  height: '25px',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                }}
                outline
                className="rounded-circle d-flex flex-column align-items-center border-0"
                color="secondary"
                onClick={() => dispatch(toggleNavbar())}
              >
                {isOpenNavbar ? (
                  <ChevronLeft size={25} />
                ) : (
                  <ChevronRight size={25} />
                )}
              </Button>
            </div>
          </Nav>
        </SidebarDetails>
      ) : (
        <div
          style={{
            width: isOpenNavbar ? '280px' : '80px',
            height: 'auto',
            overflow: 'auto',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
          className="bg-white border border-top-0 d-flex flex-column"
        >
          <Nav vertical className="list-unstyled pb-3">
            <Container>
              {menu?.map((e, i) =>
                e.action ? (
                  <Can key={`menu-${i}`} I={e.action} this={e.subject}>
                    <div className="d-flex flex-column gap-3 my-4">
                      <small className="text-secondary fw-semibold">
                        {e.headTitle}
                      </small>
                      {e?.items?.map((item, j) => {
                        if (
                          item?.path === '/manage-stock' &&
                          profile?.data?.makerData?.status !== 'VERIFIED'
                        ) {
                          return null
                        }
                        return item.action ? (
                          <Can
                            I={item.action}
                            this={item.subject}
                            key={`item-${i}-${j}`}
                          >
                            <NavItem className="hover rounded">
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? !isOpenNavbar
                                      ? 'nav-active justify-content-center'
                                      : 'nav-active'
                                    : !isActive && !isOpenNavbar
                                      ? 'nav-unactive justify-content-center'
                                      : 'nav-unactive'
                                }
                                to={item?.path}
                                style={{
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {item?.icon}
                                {!isOpenNavbar && (
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={item?.title
                                      .toLowerCase()
                                      ?.replace(' ', '-')}
                                  >
                                    {item?.title}
                                  </UncontrolledTooltip>
                                )}
                                {isOpenNavbar && <small>{item?.title}</small>}
                                {item.subject === BUSINESS_MYAPPROVAL &&
                                  isOpenNavbar && (
                                    <span
                                      style={{
                                        height: 22,
                                        width: 'auto',
                                        textDecoration: 'none',
                                      }}
                                      className="rounded-circle badge-cancel flex-centered text-sm"
                                    >
                                      {isSuccess
                                        ? data?.data
                                            ?.map((e) => e.total)
                                            ?.reduce((sum, e) => sum + e, 0)
                                        : isLoading
                                          ? 0
                                          : 0}
                                    </span>
                                  )}
                              </NavLink>
                            </NavItem>
                          </Can>
                        ) : (
                          <NavItem
                            className="hover rounded"
                            key={`item-${i}-${j}`}
                          >
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? !isOpenNavbar
                                    ? 'nav-active justify-content-center'
                                    : 'nav-active'
                                  : !isActive && !isOpenNavbar
                                    ? 'nav-unactive justify-content-center'
                                    : 'nav-unactive'
                              }
                              to={item?.path}
                              style={{
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {item?.icon}
                              {!isOpenNavbar && (
                                <UncontrolledTooltip
                                  placement="right"
                                  target={item?.title
                                    .toLowerCase()
                                    ?.replace(' ', '-')}
                                >
                                  {item?.title}
                                </UncontrolledTooltip>
                              )}
                              {isOpenNavbar && <small>{item?.title}</small>}
                            </NavLink>
                          </NavItem>
                        )
                      })}
                    </div>
                  </Can>
                ) : (
                  <div
                    key={`menu-${i}`}
                    className="d-flex flex-column gap-3 my-4"
                  >
                    <small className="text-secondary fw-semibold">
                      {e.headTitle}
                    </small>
                    {e?.items?.map((item, j) => (
                      <NavItem className="hover rounded" key={`item-${i}-${j}`}>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? !isOpenNavbar
                                ? 'nav-active justify-content-center'
                                : 'nav-active'
                              : !isActive && !isOpenNavbar
                                ? 'nav-unactive justify-content-center'
                                : 'nav-unactive'
                          }
                          to={item?.path}
                          style={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                          }}
                        >
                          {item?.icon}
                          {!isOpenNavbar && (
                            <UncontrolledTooltip
                              placement="right"
                              target={item?.title
                                .toLowerCase()
                                ?.replace(' ', '-')}
                            >
                              {item?.title}
                            </UncontrolledTooltip>
                          )}
                          {isOpenNavbar && <small>{item?.title}</small>}
                        </NavLink>
                      </NavItem>
                    ))}
                  </div>
                ),
              )}
            </Container>
          </Nav>
          <div className="d-flex justify-content-center">
            <Button
              size="sm"
              style={{
                width: '25px',
                height: '25px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
              outline
              className="rounded-circle d-flex flex-column align-items-center border-0"
              color="secondary"
              onClick={() => dispatch(toggleNavbar())}
            >
              {isOpenNavbar ? (
                <ChevronLeft size={25} />
              ) : (
                <ChevronRight size={25} />
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default NavbarSide
