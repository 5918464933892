// ** Third Party Components
import DataTable from 'react-data-table-component'
import LoadingAnimation from './LoadingAnimation'
import { ChevronDown } from 'react-feather'

const customStyles = {
  headCells: {
    style: {
      color: '#667085',
      backgroundColor: '#F9FAFB',
    },
  },
  cells: {
    style: {},
  },
  rows: {
    style: {
      '&:hover': {
        backgroundColor: '#F9FAFB',
      },
    },
  },
}

export const TableComponent = ({ ...props }) => {
  return (
    <DataTable
      {...props}
      persistTableHead
      responsive
      customStyles={customStyles}
      progressComponent={<LoadingAnimation />}
      className="border p-0 border-1 rounded-top"
      sortIcon={<ChevronDown />}
      sortServer
      noDataComponent={
        <span className="my-1">
          Belum ada data. Buat transaksi untuk memulai.
        </span>
      }
    />
  )
}
