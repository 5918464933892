import CustomAlertMessage from 'components/CustomAlertMessage'
import PMBSelect from 'components/PMBSelect'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import IconUpdateVessel from '../../../../assets/icons/icon-edit-vessel.png'
import { useCreationVesselUsersMutation } from '../forms/formApiSlice'
import {
  useFetchFarmersQuery,
  useFetchVesselsQuery,
} from './vesselsListApiSlice'
import {
  setAlert,
  setDetailVessel,
  setIsSuccess,
  toggleModalPemetaan,
} from './vesselsListSlice'

const ModalPemetaan = ({ row }) => {
  const idVessel = row?.id

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isOpenModalPemetaan, tag, detailVessel, alertType, alertMessage } =
    useSelector((s) => s.vesselsList)

  const [creationVesselUsers, { isLoading: isLoadingCreateVesselUsers }] =
    useCreationVesselUsersMutation()

  let [page, setPage] = useState({ page: tag.page ?? 0 })

  const { data: dataUsers, isLoading } = useFetchFarmersQuery({
    page: page?.page,
    status: 'ALLMEMBER',
    size: 999,
  })

  // MAPPING DATA USER NELAYAN
  const nelayanOptions = dataUsers?.data?.items.map((user) => ({
    value: user.userId,
    label: user.name,
  }))

  const [errMsg, setErrMsg] = useState('')

  const [selectedNelayan, setSelectedNelayan] = useState([])

  const { refetch } = useFetchVesselsQuery({
    page: tag?.page ?? 0,
    size: 10,
    searchvalue: '',
  })

  const resetState = () => {
    setSelectedNelayan([])
    setErrMsg('')
  }

  // Handle Submit
  const handleSubmit = async () => {
    try {
      const payload = {
        ap2himastervesselsId: detailVessel?.id || idVessel,
        userIds: selectedNelayan?.map((nelayan) => nelayan?.value),
      }

      const res = await creationVesselUsers(payload)

      if (res?.error?.data) {
        dispatch(
          setAlert({
            type: 'danger',
            message: res?.error?.data?.meta?.message,
          }),
        )
        setErrMsg(res?.error?.data?.message)
      } else if (typeof res?.data?.id === 'number') {
        dispatch(toggleModalPemetaan())
        dispatch(setDetailVessel({}))
        dispatch(setIsSuccess())
        resetState()

        dispatch(
          setAlert({
            type: 'success',
            message: `Pemetaan nelayan berhasil  ${idVessel ? 'dirubah' : 'ditambahkan'}!`,
          }),
        )

        refetch()

        navigate('/vessels')
      }
    } catch (error) {
      console.warn(error)
      dispatch(
        setAlert({
          type: 'danger',
          message: `Pemetaan nelayan gagal  ${idVessel ? 'dirubah' : 'ditambahkan'}!`,
        }),
      )
    }
  }

  useEffect(() => {
    if (errMsg) {
      setTimeout(() => {
        setErrMsg('')
      }, 3000)
    }
  }, [errMsg])

  // HANDLE EDIT
  useMemo(() => {
    if (row) {
      const nelayanData = row?.uservessels?.map((vessel) => ({
        value: vessel?.userId,
        label: vessel?.verifiedprofile?.name,
      }))
      setSelectedNelayan(nelayanData)
    }
  }, [row])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModalPemetaan}
      toggle={() => {
        dispatch(setDetailVessel({}))
        dispatch(toggleModalPemetaan())
        resetState()
      }}
      autoFocus={false}
    >
      <ModalBody>
        {/* SHOW ALERT */}
        {alertMessage && (
          <CustomAlertMessage type={alertType} message={alertMessage} />
        )}

        <img
          src={IconUpdateVessel}
          loading="lazy"
          style={{ marginBottom: '14px ' }}
        />
        <h5>Pemetaan Nelayan</h5>
        <p className="text-muted">Meliputi pemetaan ke nelayan</p>

        <FormGroup>
          <Label>Nelayan</Label>
          <PMBSelect
            options={nelayanOptions}
            value={selectedNelayan}
            onChange={(selectedOptions) => setSelectedNelayan(selectedOptions)}
            isMulti
            placeholder="Pilih Nelayan"
          />
          {/* <Select
            options={nelayanOptions}
            isMulti
            value={selectedNelayan}
            onChange={(selected) => setSelectedNelayan(selected)}
            placeholder="Pilih Nelayan"
          /> */}
        </FormGroup>

        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              dispatch(setDetailVessel({}))
              dispatch(toggleModalPemetaan())
              resetState()
            }}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={
              isLoadingCreateVesselUsers || selectedNelayan.length === 0
            }
            color="primary"
            block
            onClick={handleSubmit}
          >
            {idVessel ? 'Ubah' : 'Tambah'} Pemetaan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalPemetaan
