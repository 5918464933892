import LogoPasarMikro from 'assets/images/pmg-logo-beta.png'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Input } from 'reactstrap'
import LoadingAnimation from '../../components/LoadingAnimation'
import { apiEndpoint } from '../../utility/Utils'

const TncView = () => {
  const location = useLocation()
  const dataState = location.state
  const [htmlContent, setHtmlContent] = useState('')
  const [link, setLink] = useState('')
  const [email, setEmail] = useState('')
  const [agree, setAgree] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      const url = dataState?.isNewLogin
        ? `${apiEndpoint}/api/v1/public/tnc`
        : `${apiEndpoint}/api/v1/public/tnc?link=${link}&email=${encodeURIComponent(email)}`
      const dataRes = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'User-Agent': 'PasarMIKRO',
          ...(dataState?.isNewLogin && {
            Authorization: 'd2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94',
          }),
        },
      })
      setHtmlContent(dataRes.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleVerif = async ({ userConsent }) => {
    try {
      setLoading(true)
      const resp = await axios.get(
        `${apiEndpoint}/api/v1/bussines/email-verification?link=${link}&userconsent=${userConsent}`,
      )
      if (resp.status === 200) {
        if (resp?.data?.status === 'VERIFIED') {
          navigate('/sukses-verifikasi')
        } else {
          fetchData()
        }
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const linkValue = params.get('link')
    const emailValue = params.get('email')

    if (linkValue) {
      setLink(linkValue)
    }
    if (emailValue) {
      setEmail(emailValue)
    }
  }, [])

  useEffect(() => {
    if (dataState?.isNewLogin) {
      fetchData()
    } else if (link && email && !dataState?.isNewLogin) {
      handleVerif({ userConsent: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, email, dataState])

  return (
    <>
      <div
        className="flex-centered flex-column m-0 m-auto"
        style={{
          overflow: 'auto',
          paddingLeft: '15px',
          paddingRight: '15px',
          height: '100vh',
        }}
      >
        <div className="d-flex justify-content-center align-items-center mt-4">
          <img
            width="177px"
            height="30px"
            src={LogoPasarMikro}
            // LogoPasarMikro
            alt="PasarMIKRO logo"
          />
        </div>
        <Container
          className="w-75 overflow-auto my-2"
          style={{ maxHeight: '50vh' }}
        >
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Container>
        {htmlContent ? (
          <Container className="w-75">
            <div className="d-flex justify-content-between">
              {!dataState?.isNewLogin ? (
                <>
                  <div className="d-flex align-items-center my-auto flex-row gap-2 mb-3">
                    <Input
                      type="checkbox"
                      onChange={() => {
                        setAgree(!agree)
                      }}
                    />{' '}
                    <small>
                      Saya{' '}
                      <span style={{ fontWeight: 'bold' }}>({email}) </span>
                      menyetujui syarat dan ketentuan yang berlaku.
                    </small>
                  </div>
                  <div className="d-flex align-items-center justify-content-start flex-row">
                    <CustomButton
                      color="primary"
                      onClick={() => {
                        handleVerif({ userConsent: 1 })
                      }}
                      disabled={!agree || loading}
                    >
                      VERIFIKASI
                    </CustomButton>
                    {loading ? <LoadingAnimation size="sm" /> : null}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Container>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default TncView
