import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  isOpenModalDetailMovement: false,
  dataMovement: [],
}

export const detailCatchesSlice = createAppSlice({
  name: 'detailCatches',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setIsOpenModalDetailMovement: create.reducer((state, { payload }) => {
      state.isOpenModalDetailMovement = !state.isOpenModalDetailMovement
    }),
    setDataMovement: create.reducer((state, { payload }) => {
      state.dataMovement = payload
    }),
  }),
})

export const { setFetchOption, setIsOpenModalDetailMovement, setDataMovement } =
  detailCatchesSlice.actions

export default detailCatchesSlice.reducer
