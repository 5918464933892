import LogoPasmik from 'assets/icons/PasarMIKRO-Primary-Logogram-3.svg'
import BcaLogo from 'assets/images/BCA-01.png'
import BriLogo from 'assets/images/BRI-01.png'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { apiEndpoint } from 'configs'
import { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  AlertCircle,
  AlertTriangle,
  ChevronDown,
  ChevronUp,
} from 'react-feather'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap'
import ModalPendingTopup from './ModalPending'

const Instruction = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const dataState = location.state

  const [dataState2, setDataState2] = useState(dataState)
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [isOpenModalPending, setIsOpenModalPending] = useState(false)
  const toggleModalPending = () => setIsOpenModalPending(!isOpenModalPending)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const toggleModalConfirm = () => setIsOpenModalConfirm(!isOpenModalConfirm)
  const instructionsBca = [
    {
      title: 'ATM BCA',
      content: [
        'Masukkan kartu ATM dan PIN BCA kamu.',
        'Pilih menu "Transaksi Lainnya".',
        'Pilih menu "Transfer".',
        'Pilih "Ke Rek BCA".',
        'Masukkan nomor rekening tujuan.',
        'Masukkan nominal transfer.',
        'Konfirmasi transfer.',
      ],
    },
    {
      title: 'BCA Mobile',
      content: [
        'Login ke akun BCA mobile kamu.',
        'Pilih menu "m-Transfer".',
        'Pilih "Transfer Antar Rekening"',
        `Jika belum terdaftar, silakan daftarkan nomor rekeningnya terlebih dahulu.`,
        'Masukkan nominal transfer.',
        'Klik "Lanjut" dan selesaikan transfer.',
      ],
    },
    {
      title: 'myBCA',
      content: [
        'Login ke akun myBCA kamu.',
        'Pilih menu "Transaksi".',
        'Pilih submenu "Transfer".',
        'Masukkan nomor rekening tujuan.',
        'Masukkan nominal transfer.',
        'Klik "Lanjut".',
        'Pastikan data transfer sudah benar, lalu pilih "Ya" untuk memverifikasi transaksi.',
        'Tunggu notifikasi transaksi berhasil.',
      ],
    },
    {
      title: 'KlikBCA',
      content: [
        'Kunjungi website ini KlikBCA.',
        'Login ke akun KlikBCA kamu.',
        'Pilih menu "Transfer Dana".',
        'Pilih "Transfer ke Rekening BCA".',
        'Pilih nomor rekening dari daftar, daftarkan jika belum.',
        'Masukkan nominal transfer.',
        'Aktifkan KeyBCA, masukkan pin dan tekan angka 2.',
        'Masukkan 8 digit dari Respon KeyBCA APPLI 2.',
        'Klik Lanjutkan.',
        'Tunggu informasi rekening tujuan.',
        'Nyalakan KeyBCA kembali.',
        'Klik angka 1.',
        'Tunggu 8 digit di kotak Respon KeyBCA APPLI 1.',
        'Pilih "Kirim".',
      ],
    },
  ]
  const instructionsBri = [
    {
      title: 'ATM BRI',
      content: [
        'Masukkan Kartu Debit Anda ke dalam mesin ATM.',
        'Pilih Bahasa. ',
        'Masukkan PIN. ',
        'PIlih "TRANSAKSI LAIN". ',
        'Pilih "PEMBAYARAN". ',
        'Pilih "LAINNYA". ',
        'Pilih "BRIVA". ',
        'Masukkan nomor Virtual Account Anda (10441 + No Handphone Anda, contoh: 104410812*****), kemudian tekan "BENAR". ',
        'Masukkan jumlah top up yang diinginkan. ',
        'Muncul layar konfirmasi transfer yang berisi\n* NO. BRIVA\n* INSTANSI\n* NAMA\n* KETERANGAN\n* JML PEMBAYARAN\n* PEMBAYARAN\nJika sudah benar, tekan "YA". ',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'Mobile Banking BRI',
      content: [
        'Masukkan ke dalam Aplikasi BRI Mobile & Pilih Mobile Banking BRI.',
        'Pilih menu "Info".',
        'Pilih menu "Info BRIVA".',
        'Masukkan nomor BRIVA Anda (10441 + No Handphone Anda, contoh: 104410812*****), kemudian tekan "BENAR".',
        'Masukkan jumlah top up yang diinginkan',
        'Masukkan PIN.',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'Internet Banking BRI',
      content: [
        'Login pada alamat Internet Banking BRI.',
        'Pilih menu "PEMBAYARAN & PEMBELIAN".',
        'Pilih menu "BRIVA".',
        'Isi kode bayar dengan nomor Virtual Account Anda (10441 + No Handphone Anda, contoh: 104410812*****), kemudian tekan "Kirim".',
        'Masukkan jumlah top up yang diinginkan.',
        'Muncul layar konfirmasi pembayaran BRIVA\nJika sudah benar, masukkan Password dan mToken',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'Mini ATM BRI',
      content: [
        'Pilih menu "Mini ATM".',
        'Pilih menu "Pembayaran".',
        'Pilih menu "BRIVA".',
        'Swipe kartu ATM.',
        'Masukkan nomor BRIVA Anda (10441 + No Handphone Anda, contoh: 104410812*****).',
        'Masukkan PIN ATM.',
        'Muncul konfirmasi pembayaran.',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
    {
      title: 'ATM Bank Lain',
      content: [
        'Masukkan kartu debit Anda ke dalam mesin ATM.',
        'Masukkan PIN.',
        'Pilih menu "TRANSAKSI LAINNYA"',
        'Pilih menu "TRANSFER".',
        'Pilih menu "KE REK BANK LAIN".',
        'Masukkan kode Bank BRI (002) kemudian tekan "BENAR".',
        'Masukkan jumlah topup yang diinginkan.',
        'Masukkan nomor BRIVA Anda (10441 + No Handphone Anda, contoh: 104410812*****).',
        'Muncul layar konfirmasi transfer yang berisi:\n* Nomor Virtual Account Anda\n* Nama Anda\n* Jumlah yang akan ditopup\nJika sudah benar, tekan "BENAR".',
        'Transaksi sudah selesai, silakan melakukan konfirmasi di aplikasi',
      ],
    },
  ]

  const instructions =
    dataState?.bankCodeReceiver === 'BCA' ? instructionsBca : instructionsBri

  const [isOpen, setIsOpen] = useState(Array(instructions.length).fill(false))

  const toggle = (index) => {
    const newIsOpen = [...isOpen]
    newIsOpen[index] = !newIsOpen[index]
    setIsOpen(newIsOpen)
  }

  const formattedUniqueCode = () => {
    const uniqueCode = String(
      dataState?.amountPlusUniqueCode ?? dataState?.totalAmount,
    ).slice(-3)
    return uniqueCode
  }

  const confirmBri = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topups/confirm`,
        {
          customerCode: dataState?.customerCode,
        },
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (data) {
        navigate('/public-payment-success', { replace: true })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const confirmDpDisbursement = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/topuppg/disbursetopup/${dataState?.id}/confirm`,
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (data?.status?.toLowerCase() === 'processing') {
        toggleModalPending()
      } else if (data?.status?.toLowerCase() === 'failed') {
        navigate('/public-payment-failed', {
          state: {
            ...dataState,
          },
          replace: true,
        })
      } else {
        navigate('/public-payment-success', { replace: true })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const cancelBriVa = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topups/cancel`,
        {
          customerCode: dataState?.customerCode,
        },
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (
        data?.status?.toLowerCase() === 'canceled' ||
        data?.message === 'success'
      ) {
        navigate('/public-payment-failed', {
          state: {
            ...dataState,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const cancelBcaUniqueCode = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topupuniquecode/cancel`,
        {
          customerCode: dataState?.customerCode,
        },
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (
        data?.status?.toLowerCase() === 'canceled' ||
        data?.message === 'success'
      ) {
        navigate('/public-payment-failed', {
          state: {
            ...dataState,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const cancelBcaVa = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topupbca/cancel`,
        {
          customerCode: dataState?.customerCode,
        },
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (
        data?.status?.toLowerCase() === 'canceled' ||
        data?.message === 'success'
      ) {
        navigate('/public-payment-failed', {
          state: {
            ...dataState,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const cancelDpDisbursement = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/topuppg/disbursetopup/${dataState?.id}/cancel`,
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (data?.status?.toLowerCase() === 'done') {
        navigate('/public-payment-success', {
          replace: true,
        })
      } else if (
        data?.status?.toLowerCase() === 'canceled' ||
        data?.status?.toLowerCase() === 'failed' ||
        data?.message === 'success'
      ) {
        navigate('/public-payment-failed', {
          state: {
            ...dataState,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchContext = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/onepaymentpage/context`,
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      setDataState2({ ...dataState, ...data?.methodData })
    } catch (error) {
      navigate('/public-payment-cancel')
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = async () => {
    try {
      if (dataState?.isVa) {
        if (dataState?.bankCodeReceiver === 'BCA') {
          await cancelBcaVa()
        } else {
          await cancelBriVa()
        }
      } else {
        if (dataState?.transferTo) {
          await cancelDpDisbursement()
        } else {
          await cancelBcaUniqueCode()
        }
      }
    } catch (error) {
      setErrMsg(error?.response?.data?.message ?? error.message)
    }
  }

  const handleDoneTransfer = async () => {
    if (dataState?.isVa && dataState?.bankCodeReceiver === 'BRI') {
      await confirmBri()
    } else if (dataState?.transferTo) {
      await confirmDpDisbursement()
    } else {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/onepaymentpage/context`,
        {
          headers: {
            OnepaymentKey: `${dataState?.token}`,
          },
        },
      )
      if (data?.methodData) {
        toggleModalPending()
      } else {
        navigate('/public-payment-success', {
          replace: true,
          state: {
            ...dataState,
          },
        })
      }
    }
  }

  useEffect(() => {
    fetchContext()
  }, [])

  useEffect(() => {
    if (
      dataState2?.status?.toLowerCase() === 'done' ||
      dataState2?.status?.toLowerCase() === 'confirmed'
    ) {
      navigate('/public-payment-success', { replace: true })
    } else if (
      dataState2?.status?.toLowerCase() === 'failed' ||
      dataState2?.status?.toLowerCase() === 'canceled'
    ) {
      navigate('/public-payment-failed', { replace: true })
    }
  }, [dataState2])

  return (
    <div className="flex-centered" style={{ minHeight: '100vh' }}>
      <Container className="bg-light custom-container rounded">
        <ModalPendingTopup
          isOpen={isOpenModalPending}
          toggle={toggleModalPending}
        />
        <Modal
          centered
          size="sm"
          isOpen={isOpenModalConfirm}
          toggle={toggleModalConfirm}
        >
          <ModalBody className="d-flex flex-column">
            <p className="fw-semibold">Batalkan pembayaran</p>
            <p className="small">
              Anda yakin untuk membatalkan pembayaran ini?
            </p>
            <div className="d-flex gap-2">
              <CustomButton
                block
                outline
                disabled={loading}
                color="primary"
                onClick={() => toggleModalConfirm()}
              >
                Kembali
              </CustomButton>
              <CustomButton
                disabled={loading}
                block
                color="primary"
                onClick={() => handleCancel()}
              >
                Ya, Batalkan
              </CustomButton>
            </div>
          </ModalBody>
        </Modal>
        <div className="d-flex flex-column gap-3 py-3">
          <div className="flex-centered py-3">
            {' '}
            <img
              style={{ width: '100%', maxWidth: '80px', height: 'auto' }}
              src={LogoPasmik}
              alt="PasarMIKRO logo"
            />{' '}
          </div>
          <div>
            <Card body>
              <CardTitle className="fw-bold">
                Transfer ke {dataState?.isVa ? 'Virtual Account' : 'rekening'}
              </CardTitle>
              <div className="d-flex flex-row gap-2 mb-3">
                <Card
                  style={{
                    width: '5rem',
                    padding: '8px',
                  }}
                  className="bg-light"
                >
                  <img
                    alt={
                      dataState?.bankCodeReceiver
                        ? dataState?.bankCodeReceiver === 'BCA'
                          ? 'bca'
                          : 'bri'
                        : dataState?.transferTo?.bank_code
                    }
                    src={
                      dataState?.bankCodeReceiver
                        ? dataState?.bankCodeReceiver === 'BCA'
                          ? BcaLogo
                          : BriLogo
                        : dataState?.transferTo?.bank_logo
                    }
                  />
                </Card>
                <div className="d-flex flex-column justify-content-center">
                  <span className="fw-bold" style={{ color: '#2B7D9A' }}>
                    {dataState?.transferTo?.bank_name
                      ? dataState?.transferTo?.bank_name
                      : `Bank ${dataState?.bankCodeReceiver}`}
                  </span>
                  <span className="text-secondary">
                    {dataState?.bankAccountNameReceiver
                      ? dataState?.bankAccountNameReceiver
                      : dataState?.transferTo?.bank_holder_name}
                  </span>
                </div>
              </div>
              <div>
                <FormGroup>
                  <Label>
                    Nomor {dataState?.isVa ? 'Virtual Account' : 'rekening'}
                  </Label>
                  <div
                    className="bg-light rounded d-flex justify-content-between align-items-center px-2 border"
                    style={{ height: '60px' }}
                  >
                    <span style={{ color: '#2B7D9A', fontWeight: '600' }}>
                      {!dataState?.isVa
                        ? dataState?.bankAccountNumberReceiver
                          ? dataState?.bankAccountNumberReceiver
                          : dataState?.transferTo?.bank_account_number
                        : dataState?.bankCodeReceiver === 'BCA'
                          ? `14359${dataState?.customerCode}`
                          : `10441${dataState?.customerCode}`}
                    </span>
                    <CopyToClipboard
                      onCopy={() => {}}
                      text={
                        !dataState?.isVa
                          ? dataState?.bankAccountNumberReceiver
                            ? dataState?.bankAccountNumberReceiver
                            : dataState?.transferTo?.bank_account_number
                          : dataState?.bankCodeReceiver === 'BCA'
                            ? `14359${dataState?.customerCode}`
                            : `10441${dataState?.customerCode}`
                      }
                    >
                      <CustomButton
                        style={{ backgroundColor: '#FFFFFF', color: '#344054' }}
                      >
                        Salin
                      </CustomButton>
                    </CopyToClipboard>
                  </div>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <Label>Jumlah transfer</Label>
                  <div
                    className="bg-light rounded d-flex justify-content-between align-items-center px-2 border"
                    style={{ height: '60px' }}
                  >
                    <span style={{ color: '#2B7D9A', fontWeight: '600' }}>
                      Rp
                      {dataState?.amountPlusUniqueCode ||
                      dataState?.totalAmount ? (
                        <>
                          {intl.formatNumber(
                            Number(dataState?.amount?.toString().slice(0, -3)),
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                          <span style={{ color: '#DC6803' }}>
                            .{formattedUniqueCode()}
                          </span>
                        </>
                      ) : (
                        intl.formatNumber(Number(dataState?.amount), {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      )}
                    </span>

                    <CopyToClipboard
                      onCopy={() => {}}
                      text={
                        dataState?.amountPlusUniqueCode
                          ? dataState?.amountPlusUniqueCode
                          : dataState?.totalAmount
                            ? dataState?.totalAmount
                            : dataState?.amount
                      }
                    >
                      <CustomButton
                        style={{ backgroundColor: '#FFFFFF', color: '#344054' }}
                      >
                        Salin
                      </CustomButton>
                    </CopyToClipboard>
                  </div>
                  {!dataState?.isVa && (
                    <span style={{ color: '#DC6803' }}>
                      <AlertTriangle size={20} />{' '}
                      <small>
                        Pastikan nilai transfer sesuai jumlah tertera (termasuk
                        tiga digit terakhir)
                      </small>
                    </span>
                  )}
                </FormGroup>
              </div>
            </Card>
          </div>
          <div>
            {/* <Card body> */}
            {!dataState?.transferTo ? (
              <Card body>
                <CardTitle className="fw-bold">Petunjuk pembayaran</CardTitle>
                <div className="d-flex flex-column gap-2">
                  {instructions?.map((e, i) => {
                    return (
                      <Card key={i}>
                        <CardBody>
                          <div
                            onClick={() => {
                              toggle(i)
                            }}
                            className="d-flex justify-content-between"
                          >
                            <span>{e?.title}</span>
                            {isOpen[i] ? <ChevronUp /> : <ChevronDown />}
                          </div>
                        </CardBody>
                        <Collapse isOpen={isOpen[i]}>
                          <CardBody>
                            <div>
                              {e?.content?.map((z, i) => {
                                return (
                                  <div key={i} className="d-flex my-2 gap-1">
                                    <div>{`${i + 1}.`} </div>
                                    <div> {z}</div>
                                  </div>
                                )
                              })}
                            </div>
                          </CardBody>
                        </Collapse>
                      </Card>
                    )
                  })}
                </div>
              </Card>
            ) : (
              <></>
            )}

            {dataState?.isVa ? (
              <div
                className="py-3 px-3 rounded mt-3"
                style={{ backgroundColor: '#F3FEFF' }}
              >
                Setelah melakukan pembayaran, klik Konfirmasi untuk melanjutkan
                proses.
              </div>
            ) : (
              <></>
            )}
            {errMsg ? (
              <div className=" mt-3">
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </div>
            ) : (
              <></>
            )}
            {/* </Card> */}
          </div>
          <div>
            <CustomButton
              disabled={loading}
              onClick={() => {
                handleDoneTransfer()
              }}
              color="primary"
              block
            >
              Konfirmasi
            </CustomButton>
          </div>
          <div>
            <CustomButton
              disabled={loading}
              onClick={toggleModalConfirm}
              color="primary"
              outline
              block
            >
              Batal
            </CustomButton>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Instruction
