import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  CheckCircle,
  ChevronDown,
  MoreVertical,
  Search,
  User,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { useFetchWhistleBlowingsQuery } from './whistleBlowingsListApiSlice'
import {
  clearAlert,
  setFetchOption,
  setIsOpenModalAssign,
} from './whistleBlowingsListSlice'

import CustomAlertMessage from 'components/CustomAlertMessage'
import LoadingV2 from 'components/LoadingV2'
import PMBModalImagePreview from 'components/PMBModalImagePreview'
import { STATUS_TYPE } from 'constants'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import {
  BUSINESS_VESSELS,
  BUSINESS_WHISTLEBLOWING,
  MANAGE,
  READ,
} from 'utils/subjectActions'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import ModalAssign from './ModalAssign'

// HANDLE BADGE BASED ON STATUS AND HANDLE LABEL
const StatusColumn = ({ row }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case STATUS_TYPE.RESOLVED:
        return 'success'
      default:
        return 'primary'
    }
  }

  const getStatusLabel = (status) => {
    return status === STATUS_TYPE.RESOLVED ? STATUS_TYPE.RESOLVED : 'OPEN'
  }

  return (
    <Badge color={getStatusColor(row?.status)}>
      {getStatusLabel(row?.status) ?? '-'}
    </Badge>
  )
}

// HANDLE NOTE BASED ON STATUS TYPE
const NoteColumn = ({ row }) => {
  const getNote = (row) => {
    switch (row?.status) {
      case STATUS_TYPE.ASSIGNED:
        return row?.assignNotes ?? '-'
      case STATUS_TYPE.RESOLVED:
        return row?.resolveNotes ?? '-'
      case STATUS_TYPE.UNRESOLVED:
        return row?.unresolveNotes ?? '-'
      default:
        return '-'
    }
  }

  return <span>{getNote(row)}</span>
}

const WhistleBlowingsTable = () => {
  const { tag, isOpenModalAssign, alertMessage, alertType } = useSelector(
    (s) => s.whistleBlowingsList,
  )
  const { abilityRole } = useSelector((s) => s.auth)
  const { profile } = useSelector((s) => s.dashboard)

  const isHaveRoleWhistleBlowing = abilityRole?.some(
    (role) => role?.subject === BUSINESS_WHISTLEBLOWING,
  )

  const manageWhistleBlowingActions = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_WHISTLEBLOWING,
  })

  const manageVesselActions = useAbilityAccess({
    action: READ,
    subject: BUSINESS_VESSELS,
  })

  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const debounceTimer = useRef(0)
  const [modalOpenImage, setModalOpenImage] = useState(false)
  const [imageItems, setImagesItems] = useState([])
  const [dropdownMenus, setDropdownMenus] = useState({})
  const [detailRow, setDetailRow] = useState(null)
  // const [isClick, setIsClick] = useState(false)
  // const [openLogs, setOpenLogs] = useState(false)
  const [statusType, setStatusType] = useState(STATUS_TYPE.ASSIGNED)
  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch } = useFetchWhistleBlowingsQuery({
    page: page?.page,
    size: 10,
    searchvalue: searchTemp,
    assigneduserid: isHaveRoleWhistleBlowing ? undefined : profile?.userId,
    sortby: sortColumn,
    sortdir: sortDirection,
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const onHandleOpenImageModal = (imageUrls, index) => {
    setImagesItems(imageUrls)
    setModalOpenImage(true)
  }

  const toggleDropdown = (id) => {
    setDropdownMenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  // const onClickDetailLogs = (row = null) => {
  //   console.log({ row })
  //   setDetailRow(row)
  //   setOpenLogs((prev) => !prev)
  // }

  const onClickAssign = (row = null) => {
    setDetailRow(row)
    dispatch(setIsOpenModalAssign())
  }

  // HANDLE MENU ITEMS BASED ON isHaveRoleWhistleBlowing
  const menuItems = isHaveRoleWhistleBlowing
    ? [
        {
          label: 'Assign',
          icon: <User size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.ASSIGNED)
            onClickAssign(row)
          },
        },
        {
          label: 'Resolve',
          icon: <CheckCircle size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.RESOLVED)
            onClickAssign(row)
          },
        },
      ]
    : [
        {
          label: 'Re-assign',
          icon: <User size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.UNRESOLVED)
            onClickAssign(row)
          },
        },
        {
          label: 'Resolve',
          icon: <CheckCircle size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.RESOLVED)
            onClickAssign(row)
          },
        },
      ]

  const isMenuItemDisabled = (row, item) => {
    if (row?.status === STATUS_TYPE.RESOLVED) {
      return true
    }

    if (
      isHaveRoleWhistleBlowing &&
      (item?.label?.toLocaleLowerCase() === 'assign' ||
        item?.label?.toLocaleLowerCase() === 're-assign') &&
      row?.assignedUserId !== null
    ) {
      return false
    } else if (
      isHaveRoleWhistleBlowing &&
      item?.label?.toLocaleLowerCase() === 'resolve' &&
      row?.assignedUserId !== null &&
      row?.assignedUserId !== profile?.userId
    ) {
      return true
    }

    return false
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      width: '100px',
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: 'Judul Laporan',
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: 'Deskripsi',
      selector: 'description',
      sortable: true,
      cell: (row) => {
        return <span>{row?.description ?? '-'}</span>
      },
    },
    {
      name: 'Pelapor',
      selector: 'reportername',
      sortable: true,
      cell: (row) => {
        return <span>{row?.user?.name ?? '-'}</span>
      },
    },
    {
      name: 'Bukti Kejadian',
      width: '100px',
      cell: (row) => {
        const imageUrls = row?.linksImage
          ? row?.linksImage?.split(',')
          : [VesselNotFound]
        const displayedImage = imageUrls[0]
        const extraImagesCount = imageUrls?.length - 1

        return (
          <div
            style={{
              position: 'relative',
              width: '50px',
              height: '50px',
            }}
          >
            <img
              src={displayedImage}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                border: '2px solid #f0f0f0',
                transition: 'transform 0.2s',
                cursor: 'pointer',
              }}
              alt="pengaduan-image"
              loading="lazy"
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = 'scale(1.1)')
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              onClick={() => onHandleOpenImageModal(imageUrls, 0)}
            />

            {extraImagesCount > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  transform: 'translate(25%, -25%)',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  border: '1px solid #fff',
                  cursor: 'pointer',
                }}
                onClick={() => onHandleOpenImageModal(imageUrls, 1)}
              >
                +{extraImagesCount}
              </div>
            )}
          </div>
        )
      },
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => <StatusColumn row={row} />,
    },
    {
      name: 'Petugas',
      selector: 'assignedname',
      sortable: true,
      cell: (row) => {
        return <span>{row?.assigned?.name ?? '-'}</span>
      },
    },
    {
      name: 'Catatan',
      cell: (row) => <NoteColumn row={row} />,
    },
    {
      name: 'Laporan Diterima',
      selector: 'createdAt',
      sortable: true,
      cell: (row) => {
        return <span>{moment(row?.createdAt).format('DD MMM YYYY HH:mm')}</span>
      },
    },
    {
      name: 'Laporan Selesai',
      selector: 'updatedAt',
      sortable: true,
      cell: (row) => {
        const isResolved = row?.status === STATUS_TYPE?.RESOLVED
        return (
          <span>
            {isResolved
              ? moment(row?.updatedAt).format('DD MMM YYYY HH:mm')
              : '-'}
          </span>
        )
      },
    },
    {
      name: '',
      width: '80px',
      cell: (row) => {
        return (
          <div
            style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
          >
            {(manageWhistleBlowingActions || manageVesselActions) && (
              <Dropdown
                isOpen={dropdownMenus[row.id]}
                toggle={() => toggleDropdown(row.id)}
                direction="down"
              >
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownMenus[row.id]}
                  style={{ cursor: 'pointer' }}
                >
                  <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu className="p-0" container="body">
                  {menuItems.map((item, index) => (
                    <DropdownItem
                      key={index}
                      disabled={isMenuItemDisabled(row, item)}
                      className="my-1"
                      onClick={() => item.action(row)}
                    >
                      {item.icon}
                      <span className="ms-2 text-sm">{item.label}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const onHandleSearchChange = (text) => {
    setPage({ page: 0 })
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        setSearchTemp(text)
        refetch()
      } else {
        setSearch('')
        setSearchTemp('')
        refetch()
      }
    }, 3000)
  }

  // CLEAR ALERT MESSAGE
  useEffect(() => {
    if (alertMessage) {
      scrollToTop()
      setTimeout(() => {
        dispatch(clearAlert())
      }, 3000)
    }
  }, [alertMessage, dispatch])

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Daftar Laporan Pengaduan" />
      </div>

      {/* SHOW ALERT */}
      {alertMessage && (
        <CustomAlertMessage type={alertType} message={alertMessage} />
      )}

      {/* SIDEBAR LOGS */}
      {/* <SidebarLogs
        isOpen={openLogs}
        row={detailRow}
        toggle={onClickDetailLogs}
      /> */}

      <Card className="mb-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">Belum ada daftar pengaduan</p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${page.page + 1} dari ${Math.ceil(
                      props.rowCount / props.rowsPerPage,
                    )}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page - 1 }))
                          dispatch(setFetchOption({ page: page.page - 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page + 1 }))
                          dispatch(setFetchOption({ page: page.page + 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Laporan Pengaduan</h5>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari judul pengaduan..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      {/* MODAL IMAGE PREVIEW */}
      <PMBModalImagePreview
        isOpen={modalOpenImage}
        toggle={() => setModalOpenImage(!modalOpenImage)}
        images={imageItems}
      />

      {/* MODAL */}
      <ModalAssign
        open={isOpenModalAssign}
        row={detailRow}
        toggle={onClickAssign}
        statusType={statusType}
      />
    </div>
  )
}

export default WhistleBlowingsTable
