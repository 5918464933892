import LoadingAnimation from 'components/LoadingAnimation'
import { useEffect, useState } from 'react'
import { ArrowLeft, Check, Tv } from 'react-feather'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Navbar, NavbarBrand } from 'reactstrap'
import { URL_FINISH_KURSUS } from 'utils/pathUrl'
import { usePostProgressMutation } from './learningApiSlice'

const DetailLearning = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dataState = location?.state
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [currentTime, setCurrentTime] = useState(0)
  const { profile } = useSelector((s) => s.dashboard)
  const [selectedVideo, setSelectedVideo] = useState(
    dataState?.tutorialcontent[0] ?? dataState?.tutorialcontent[0],
  )
  const [videoDurations, setVideoDurations] = useState(
    dataState?.tutorialcontent?.map((video) => video?.contentDuration || 0),
  )
  const [watchedVideos, setWatchedVideos] = useState([])
  const [postProgress] = usePostProgressMutation()

  const markCourseAsFinished = (courseId) => {
    const finishedCourses =
      JSON.parse(localStorage.getItem(`courseFinished${profile?.userId}`)) || []

    if (!finishedCourses.includes(courseId)) {
      finishedCourses.push(courseId)

      localStorage.setItem(
        `courseFinished${profile?.userId}`,
        JSON.stringify(finishedCourses),
      )
      navigate(URL_FINISH_KURSUS)
    }
  }

  const hasFinishedCourse = (courseId) => {
    const finishedCourses =
      JSON.parse(localStorage.getItem(`courseFinished${profile?.userId}`)) || []
    return finishedCourses.includes(courseId)
  }

  const handlePostProgress = async (finished) => {
    try {
      await postProgress({
        tutorialContentId: selectedVideo?.id,
        duration: Number(currentTime.toFixed(0)),
        contentDuration: videoDurations.find(
          (e) => e?.id === selectedVideo?.id,
        ),
        finishedAt: finished,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleTimeUpdate = (time) => {
    setCurrentTime(time)

    if (
      Math.floor(time) % 10 === 0 &&
      time > 0 &&
      !selectedVideo?.progress?.finishedAt
    ) {
      handlePostProgress()
    }
  }

  const handleVideoEnd = () => {
    const updatedWatchedVideos = [...watchedVideos, selectedVideo?.id]
    setWatchedVideos(updatedWatchedVideos)

    if (
      updatedWatchedVideos.length === dataState?.tutorialcontent?.length &&
      !hasFinishedCourse()
    ) {
      markCourseAsFinished(selectedVideo?.id)
    }

    handlePostProgress(new Date())
  }

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    if (hours > 0) {
      return `${hours} jam ${minutes > 0 ? minutes + ' menit' : ''}`
    } else if (minutes > 0) {
      return `${minutes} menit`
    } else {
      return `${remainingSeconds} detik`
    }
  }

  const handleDuration = (duration, index) => {
    const updatedDurations = [...videoDurations]
    updatedDurations[index] = duration
    setVideoDurations(updatedDurations)
  }

  useEffect(() => {
    if (dataState && videoDurations.every((e) => e > 0)) {
      dataState?.tutorialcontent.forEach(async (video, index) => {
        if (!video?.contentDuration && !video?.progress?.finishedAt) {
          await postProgress({
            tutorialContentId: video?.id,
            duration: 0,
            contentDuration: videoDurations[index] || 0,
          })
        }
      })
    }
  }, [dataState, postProgress, videoDurations])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Navbar className="border" color="white" expand="md">
        <NavbarBrand>
          <div className="d-flex gap-3 align-items-center my-2">
            <ArrowLeft
              onClick={() => navigate(-1)}
              size={24}
              className="text-muted"
            />
            <div
              className="text-scroll"
              style={{
                maxWidth: `${screenWidth * 0.8}px`,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              {dataState?.title}: {dataState?.description}
            </div>
          </div>
        </NavbarBrand>
      </Navbar>

      <div className="ratio ratio-16x9">
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${selectedVideo?.content ?? selectedVideo?.videoId}`}
          onProgress={({ playedSeconds }) => handleTimeUpdate(playedSeconds)}
          onEnded={handleVideoEnd}
          width="100%"
          height="100%"
          controls
          playing
          onReady={(player) => {
            if (selectedVideo?.progress?.duration > 0) {
              player.seekTo(selectedVideo?.progress?.duration, 'seconds')
            }
          }}
        />
      </div>

      <div>
        {dataState?.tutorialcontent?.map((e, i) => {
          return (
            <div
              className={`${
                e?.content === selectedVideo?.content ? 'bg-gray-100' : ''
              }`}
              key={i}
              onClick={() => setSelectedVideo(e)}
            >
              <Container
                className={`d-flex gap-2 p-4 justify-content-between align-items-center`}
              >
                <div className="d-flex gap-2">
                  <div
                    className={
                      e?.content === selectedVideo?.content ? `fw-semibold` : ''
                    }
                  >
                    {i + 1}.{' '}
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      className={
                        e?.content === selectedVideo?.content ||
                        e?.videoId === selectedVideo?.videoId
                          ? `fw-semibold`
                          : ''
                      }
                    >
                      {e?.title}
                    </span>
                    <div className="text-muted d-flex align-items-center gap-2">
                      <div>
                        <Tv size={16} />{' '}
                      </div>
                      <div>
                        {videoDurations[i] > 0 ? (
                          formatDuration(videoDurations[i])
                        ) : (
                          <LoadingAnimation size="sm" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {watchedVideos.includes(e?.id) ? (
                  <div className="badge rounded-pill bg-success-50 text-success-700">
                    <Check size={12} /> Selesai
                  </div>
                ) : (
                  <></>
                )}
              </Container>
              {!e?.contentDuration && (
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${e?.content ?? e?.videoId}`}
                  onDuration={(duration) => handleDuration(duration, i)}
                  style={{ display: 'none' }}
                />
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default DetailLearning
