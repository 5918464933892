import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import IconUpdateVessel from '../../../../assets/icons/icon-edit-vessel.png'
import FormAddVessel from '../forms'
import { toggleModalChangeProfile } from './detailSlice'

const ModalChangeProfil = ({ row }) => {
  const dispatch = useDispatch()
  const { isOpenModalChangeProfile } = useSelector((s) => s.vesselsDetail)

  return (
    <Modal
      centered
      isOpen={isOpenModalChangeProfile}
      toggle={() => dispatch(toggleModalChangeProfile())}
    >
      <ModalBody>
        <img
          src={IconUpdateVessel}
          loading="lazy"
          style={{ marginBottom: '14px ' }}
        />
        <h6>Ubah data Kapal</h6>
        <FormAddVessel row={row} />
      </ModalBody>
    </Modal>
  )
}

export default ModalChangeProfil
