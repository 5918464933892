import arusKasIcon from 'assets/icons/arus-kas.svg'
import piutangIcon from 'assets/icons/piutang.svg'
import utangIcon from 'assets/icons/utang.svg'
import { Book } from 'react-feather'
import { ARUS_KAS, URL_KURSUS, URL_PIUTANG, URL_UTANG } from 'utils/pathUrl'

export const menusV3 = [
  {
    headTitle: 'CATATAN',
    items: [
      {
        title: 'Arus Kas',
        icon: (
          <img src={arusKasIcon} id="arus-kas" alt="Arus Kas" loading="lazy" />
        ),
        path: ARUS_KAS,
      },
      {
        title: 'Utang',
        icon: <img src={utangIcon} id="utang" alt="Utang" loading="lazy" />,
        path: URL_UTANG,
      },
      {
        title: 'Piutang',
        icon: (
          <img src={piutangIcon} id="piutang" alt="Piutang" loading="lazy" />
        ),
        path: URL_PIUTANG,
      },
      {
        title: 'Kursus',
        icon: <Book size={22} id="kursus" />,
        path: URL_KURSUS,
      },
    ],
  },
]
