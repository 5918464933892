import { createApiSlice } from 'app/createApiSlice'
import { API_BUSINESS_CATCHES } from 'utils/apiList'

export const catchesListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'catchesList/api',
  endpoints(builder) {
    return {
      fetchCatchesList: builder.query({
        query({
          page,
          s,
          size = 10,
          from,
          to,
          filterwpp,
          filterapprove,
          filterdistrict,
          sortby = '',
          sortdir = '',
        }) {
          return {
            url: API_BUSINESS_CATCHES,
            params: {
              page,
              s,
              size,
              from,
              to,
              filterwpp,
              filterapprove,
              filterdistrict,
              sortby,
              sortdir,
            },
          }
        },
      }),
      fetchUserCatchDetailById: builder.query({
        query({ id }) {
          return {
            url: `${API_BUSINESS_CATCHES}/${id}`,
          }
        },
      }),
      updateCatchApproveById: builder.mutation({
        query({ id, notes }) {
          return {
            url: `${API_BUSINESS_CATCHES}/approve/${id}`,
            method: 'POST',
            body: { notes },
          }
        },
      }),
    }
  },
})

export const {
  useFetchCatchesListQuery,
  useLazyFetchCatchesListQuery,
  useFetchUserCatchDetailByIdQuery,
  useUpdateCatchApproveByIdMutation,
} = catchesListApiSlice
