import BreadCrumbs from 'components/breadcrumbs'
import { monthsOptions, wppOptions } from 'constants'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { Card } from 'reactstrap'
import { selectThemeColors } from 'utility/Utils'
import { getInsight } from '../API/ap2hiActions'
import '../style.css'

export default function Insight({ warning }) {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState({})
  const [previousMonth, setPreviousMonth] = useState({})
  const [selectedWpp, setSelectedWpp] = useState(wppOptions[0])
  const [selectedMonthData, setSelectedMonthData] = useState([])
  const [previousMonthData, setPreviousMonthData] = useState([])

  useEffect(() => {
    const currentDate = new Date()
    const currentMonthValue = String(currentDate.getMonth() + 1).padStart(
      2,
      '0',
    )
    const currentMonthLabel = monthsOptions.find(
      (month) => month.value === currentMonthValue,
    )

    setSelectedMonth(currentMonthLabel)
    setLoading(true)

    fetchInsight(currentMonthLabel)

    const previousMonthIndex = currentDate.getMonth() - 1
    const previousMonthValue =
      previousMonthIndex >= 0
        ? String(previousMonthIndex + 1).padStart(2, '0')
        : '12'
    const previousMonthLabel = monthsOptions.find(
      (month) => month.value === previousMonthValue,
    )

    setPreviousMonth(previousMonthLabel)
  }, [])

  const fetchInsight = (selectedOption, wpp) => {
    const monthValue = selectedOption.value
    const currentYear = moment().year()

    const selectedFrom = moment(`${currentYear}-${monthValue}-01`)
      .startOf('month')
      .toISOString()
    const selectedTo = moment(`${currentYear}-${monthValue}-01`)
      .endOf('month')
      .toISOString()

    const previousFrom = moment(`${currentYear}-${monthValue}-01`)
      .subtract(1, 'month')
      .startOf('month')
      .toISOString()
    const previousTo = moment(`${currentYear}-${monthValue}-01`)
      .subtract(1, 'month')
      .endOf('month')
      .toISOString()

    setLoading(true)

    Promise.all([
      dispatch(
        getInsight({ from: selectedFrom, to: selectedTo, wpp: wpp?.value }),
      ),
      dispatch(
        getInsight({ from: previousFrom, to: previousTo, wpp: wpp?.value }),
      ),
    ])
      .then(([selectedRes, previousRes]) => {
        const selectedData = selectedRes.payload.chart1.map((item) => ({
          x: item.name,
          y: item?.totalAmount,
        }))

        const previousData = previousRes.payload.chart1.map((item) => ({
          x: item.name,
          y: item?.totalAmount,
        }))

        setSelectedMonthData(selectedData)
        setPreviousMonthData(previousData)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption)
    fetchInsight(selectedOption, selectedWpp)
    const selectedIndex = monthsOptions.findIndex(
      (month) => month.value === selectedOption.value,
    )

    const previousIndex = selectedIndex > 0 ? selectedIndex - 1 : 11
    const previousMonthLabel = monthsOptions[previousIndex]

    setPreviousMonth(previousMonthLabel)
  }

  const handleWppChange = (type) => {
    fetchInsight(selectedMonth, type)
    setSelectedWpp(type)
  }

  const options = {
    chart: {
      width: '100%',
      height: 500,
      zoom: {
        enabled: false,
      },
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 1,
      strokeOpacity: 0,
      strokeColors: ['#fff'],
      colors: [warning],
    },
    dataLabels: {
      enabled: true,
      offsetY: -10,
      style: {
        fontSize: '12px',
        colors: ['#000'],
        background: 'transparent',
        fontWeight: 'normal',
      },
      background: {
        enabled: false,
      },
      formatter: function (val) {
        return val === 0 ? '' : val.toString().replace('.', ',')
      },
    },
    stroke: {
      width: 0,
    },
    colors: ['#8ABBCC', '#006386'],
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          return y
        },
      },
      formatter: function (series) {
        return series
          .map((item) => {
            return `<strong>${item.seriesName}</strong>: ${item.dataPointIndex >= 0 ? monthsOptions[item.dataPointIndex] : ''}: ${item.y}<br>`
          })
          .join('')
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
        formatter: function (value) {
          return value
            ? value.length > 10
              ? value.substring(0, 10) + '...'
              : value
            : ''
        },
        style: {
          fontSize: '10px',
        },
      },
    },
    title: {
      text: 'Volume Tangkapan (kg)',
      offsetY: 5,
      style: {
        fontSize: '12px',
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value).toString()
        },
        style: {
          fontSize: '10px',
        },
      },
    },
  }

  const series = [
    {
      name: previousMonth?.label,
      type: 'column',
      data: previousMonthData,
    },
    {
      name: selectedMonth?.label,
      type: 'column',
      data: selectedMonthData,
    },
  ]

  return (
    <div className="container-fluid px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Tangkapan Overview" />
      </div>

      <Card className="mb-3 p-3" style={{ border: 'none' }}>
        <div className="d-flex flex-column flex-sm-row align-items-start justify-content-md-between">
          <h5 className="mb-0">Tangkapan Overview</h5>
          <div
            style={{ width: 300 }}
            className="d-flex flex-column flex-sm-row mt-2 mt-sm-0 mb-2"
          >
            <Select
              options={monthsOptions}
              theme={selectThemeColors}
              onChange={handleMonthChange}
              className="react-select mb-2"
              classNamePrefix="select"
              value={selectedMonth}
              styles={{
                container: (base) => ({
                  ...base,
                  width: '100%',
                }),
              }}
            />
            <Select
              options={wppOptions}
              theme={selectThemeColors}
              className="react-select ms-sm-2"
              classNamePrefix="select"
              onChange={handleWppChange}
              value={selectedWpp}
              styles={{
                container: (base) => ({
                  ...base,
                  width: '100%',
                }),
              }}
            />
          </div>
        </div>
        <div
          style={{ overflowX: 'auto', width: '100%' }}
          className="no-scrollbar"
        >
          <div style={{ minWidth: '1500px' }}>
            <Chart options={options} height={400} series={series} />
          </div>
        </div>
      </Card>
    </div>
  )
}
