import { PMBFormatDate } from 'helpers'
import DataTable from 'react-data-table-component'
import { ChevronDown, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { setDataMovement, setIsOpenModalDetailMovement } from './detailSlice'

const ModalMovement = () => {
  const dispatch = useDispatch()
  const { isOpenModalDetailMovement, dataMovement } = useSelector(
    (s) => s.catchesDetail,
  )

  const columns = [
    {
      name: 'Name Spesies',
      cell: (row) => <span>{row?.selectedFish?.nameItem}</span>,
    },
    {
      name: 'Transhipment Code',
      cell: (row) => <span>{row?.id}</span>,
    },
    {
      name: 'Berat Bersih',
      cell: (row) => (
        <span>
          {newFormatThousandSeparator(row?.weight)}{' '}
          {row?.selectedFish?.unit || 'kg'}
        </span>
      ),
    },
    {
      name: 'Waktu Alih Muat',
      cell: (row) => <span>{PMBFormatDate(row?.createdAt)}</span>,
    },
    {
      name: 'Lokasi Alih Muat/WPP',
      cell: (row) => <span>{row?.fishingArea?.name}</span>,
    },
    {
      name: 'Kordinat Alih Muatan',
      cell: (row) => (
        <span>
          {row?.latitude}, {row?.longitude}
        </span>
      ),
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
    rows: {
      style: {
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
  }

  const onHandleCloseModal = () => {
    dispatch(setIsOpenModalDetailMovement())
    dispatch(setDataMovement([]))
  }

  return (
    <Modal
      centered
      style={{ maxWidth: '75vw' }}
      keyboard={false}
      isOpen={isOpenModalDetailMovement}
      toggle={() => onHandleCloseModal()}
    >
      <ModalBody>
        <div className="d-flex justify-content-between my-3">
          <h5>Rincian alih Muatan</h5>
          <X
            size={20}
            onClick={() => onHandleCloseModal()}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {/* <Card>
          <CardBody className="d-flex flex-column gap-2"> */}
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={dataMovement || []}
          progressPending={dataMovement?.length === 0}
          responsive
          persistTableHead
          paginationTotalRows={0}
          progressComponent={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '100px 0px',
              }}
            >
              {/* <Spinner /> */}
              <p className="my-3 fw-semibold">
                Belum ada daftar rincian alih muatan
              </p>
            </div>
          }
          sortIcon={<ChevronDown />}
          sortServer
          className="border p-0 border-1 rounded-top"
          noDataComponent={
            <p className="my-3 fw-semibold">
              Belum ada daftar rincian alih muatan
            </p>
          }
          // pagination
        />
      </ModalBody>
    </Modal>
  )
}

export default ModalMovement
