import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'
import {
  API_FARMERS_BANK_LIST,
  API_FARMERS_CITY_LIST,
  API_FARMERS_DISTRICT_LIST,
  API_FARMERS_POSTALCODE_LIST,
  API_FARMERS_PROVINCE_LIST,
  API_FARMERS_SUBDISTRICT_LIST,
  API_FARMERS_UPLOAD_FILE,
} from 'utils/apiList'

const getListBanks = createAsyncThunk(
  'formFarmers/banks',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_BANK_LIST}`,
        config,
      )
      const newData = data?.data?.map((e) => {
        return {
          value: e.name,
          label: e.name,
          code: e.code,
        }
      })
      return newData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getListProvinces = createAsyncThunk(
  'formFarmers/province',
  async (_, { rejectWithValue, getState }) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_PROVINCE_LIST}`,
        config,
      )

      const newData = data?.map((e) => {
        return {
          value: e.nama,
          label: e.nama,
          id: e.id,
        }
      })
      return newData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getListCity = createAsyncThunk(
  'formFarmers/city',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_CITY_LIST}/${id}`,
        config,
      )

      const newData = data?.map((e) => {
        return {
          value: e.nama,
          label: e.nama,
          id: e.id,
        }
      })
      return newData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getListDistrict = createAsyncThunk(
  'formFarmers/district',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_DISTRICT_LIST}/${id}`,
        config,
      )
      const newData = data?.map((e) => {
        return {
          value: e.nama,
          label: e.nama,
          id: e.id,
        }
      })
      return newData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getListSubdistrict = createAsyncThunk(
  'formFarmers/subDistrict',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_SUBDISTRICT_LIST}/${id}`,
        config,
      )
      const newData = data?.map((e) => {
        return {
          value: e.nama,
          label: e.nama,
          id: e.id,
        }
      })
      return newData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getListPostalCode = createAsyncThunk(
  'formFarmers/postalCode',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_FARMERS_POSTALCODE_LIST}/${id}`,
        config,
      )
      const newData = data?.map((e) => {
        return {
          value: e.kodePos,
          label: e.kodePos,
        }
      })
      return newData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const uploadImage = createAsyncThunk(
  'formFarmers/upload',
  async ({ img, fileType }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const image = new FormData()
      image.append('file', img)
      const { data } = await axios.post(
        `${apiEndpoint}/${API_FARMERS_UPLOAD_FILE}`,
        { file: img, fileType },
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export {
  getListBanks,
  getListProvinces,
  getListCity,
  getListDistrict,
  getListSubdistrict,
  getListPostalCode,
  uploadImage,
}
