import { FormattedMessage } from 'react-intl'
import { Button, Modal, ModalBody, ModalFooter, Progress } from 'reactstrap'

const PMBProgressBarExportFile = (props) => {
  const { isDialogShow, firstRows = 0, totalRows = 0, onCancel } = props
  return (
    <Modal
      isOpen={isDialogShow}
      className="modal-lg modal-dialog-centered"
      placement="top"
      target="center"
      size="md"
    >
      <ModalBody>
        {isDialogShow && (
          <div className="mt-2">
            <div className="text-center">
              {firstRows === 0 && totalRows === 0
                ? 0
                : Math.floor((firstRows / totalRows) * 100)}
              %
            </div>
            <div>
              <Progress
                value={Math.floor((firstRows / totalRows) * 100)}
                animated
              />
            </div>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={() => onCancel()}>
          <FormattedMessage id="Cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PMBProgressBarExportFile
