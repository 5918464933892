import CustomButton from 'components/Buttons/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { URL_CONNECT_ACCOUNT } from 'utils/pathUrl'
import { toggleModal } from './loginSlice'

const ModalConnectAccount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isOpenModal } = useSelector((e) => e.auth)

  const handleSubmit = async () => {
    navigate(URL_CONNECT_ACCOUNT)
    dispatch(toggleModal())
  }

  return (
    <>
      <Modal
        className="p-5"
        isOpen={isOpenModal}
        toggle={() => {
          dispatch(toggleModal())
        }}
        centered
      >
        <ModalBody className="p-4">
          <div>
            <h6 className="fw-bold">Anda menggunakan nomor handphone</h6>
            <p>
              Untuk melanjutkan, sambungkan akun aplikasi Anda ke PasarMIKRO
              Website. Data di aplikasi akan tersambung otomatis.
            </p>
          </div>

          <div className="d-flex flex-row gap-3">
            <CustomButton
              onClick={() => {
                dispatch(toggleModal())
              }}
              block
              outline
              color="primary"
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleSubmit()
              }}
              block
              color="primary"
            >
              Lanjut
            </CustomButton>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalConnectAccount
