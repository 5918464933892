import { createApiSlice } from 'app/createApiSlice'
import { API_GEARS } from 'utils/apiList'

export const gearsListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'gearsList/api',
  endpoints(builder) {
    return {
      fetchGears: builder.query({
        query({ page, size = 10, searchvalue, sortby = '', sortdir = '' }) {
          return {
            url: API_GEARS,
            params: {
              page,
              size,
              searchvalue,
              sortby,
              sortdir,
            },
          }
        },
      }),
    }
  },
})

export const { useFetchGearsQuery, useLazyFetchGearsQuery } = gearsListApiSlice
