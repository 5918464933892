import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useAuthAccess } from 'hooks/useAuthAccess'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { usePostValidateKeyMutation } from './connectAccountApiSlice'

const ConnectAccount = () => {
  const navigate = useNavigate()
  const { token, alreadychangepass, userNotPartnership } = useAuthAccess()
  const [email, setEmail] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [validateKey, res] = usePostValidateKeyMutation()
  const { data } = useSelector((s) => s.connectAccount)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await validateKey({ email, key: data?.key }).unwrap()
      if (res?.link) {
        navigate('/sukses-verifikasi', { replace: true })
      } else {
        throw res
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
    }
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    setErrMsg('')
  }

  useEffect(() => {
    if (
      token !== null &&
      (userNotPartnership || alreadychangepass === 1) &&
      !data
    ) {
      navigate('/', { replace: true })
    } else if (token === null && !data) {
      navigate('/login', { replace: true })
    }
    if (data) {
      setEmail(data?.email)
    }
  }, [data])

  return (
    <Container fluid>
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: '600px', width: '100%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Sambungkan akun
            </CardTitle>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  value={email}
                  onChange={(e) => handleChangeEmail(e)}
                  type="email"
                  autoComplete="off"
                  placeholder="Masukkan email"
                />
              </FormGroup>

              {errMsg && (
                <FormGroup>
                  <small className="text-danger">
                    <AlertCircle size={15} /> {errMsg}
                  </small>
                </FormGroup>
              )}

              <FormGroup className="mt-4">
                <CustomButton
                  disabled={res?.isLoading || !email}
                  block
                  color="primary"
                  type="submit"
                >
                  Sambungkan Akun
                </CustomButton>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default ConnectAccount
