import Select from 'react-select'

const PMBSelect = ({
  value,
  onChange,
  options,
  isMulti = false,
  isInvalid = false,
  placeholder = 'Select...',
  onInputChange,
  onMenuScrollToBottom,
  title,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: isInvalid ? '#dc3545' : '#d1d5db',
      boxShadow: 'none',
      '&:hover': {
        borderColor: isInvalid ? '#dc3545' : '#d1d5db',
      },
      borderRadius: '8px',
      padding: '2px',
      minWidth: props.style?.minWidth || '100%',
      maxWidth: props.style?.maxWidth || '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e0f2ff',
      borderRadius: '12px',
      padding: '2px 6px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#0369a1',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#0369a1',
      '&:hover': {
        backgroundColor: '#b3e5fc',
        color: '#005f8e',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#6b7280',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#6b7280',
    }),
  }

  return (
    <div>
      {/* Conditionally render the title */}
      {title && <label className="d-block mb-1">{title}</label>}
      <Select
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        classNamePrefix="pmb-select"
        onInputChange={onInputChange}
        onMenuScrollToBottom={onMenuScrollToBottom}
        {...props}
      />
    </div>
  )
}

export default PMBSelect
