import CustomAlertMessage from 'components/CustomAlertMessage'
import PMBSelect from 'components/PMBSelect'
import { STATUS_TYPE } from 'constants'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Info } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { BUSINESS_WHISTLEBLOWING } from 'utils/subjectActions'
import {
  useFetchUserTimsBlowingsQuery,
  useFetchWhistleBlowingsQuery,
  useUpdateWhistleBlowingStatusMutation,
} from './whistleBlowingsListApiSlice'
import {
  clearAlert,
  setAlert,
  setIsOpenModalAssign,
} from './whistleBlowingsListSlice'

const ModalAssign = ({ open, row, toggle, statusType }) => {
  const idWhistleBlowing = row?.id

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { tag, alertType, alertMessage } = useSelector(
    (s) => s.whistleBlowingsList,
  )
  const { abilityRole } = useSelector((s) => s.auth)
  const { profile } = useSelector((s) => s.dashboard)

  const isHaveRoleWhistleBlowing = abilityRole?.some(
    (role) => role?.subject === BUSINESS_WHISTLEBLOWING,
  )

  const [updateWhistleBlowingStatus, { isLoading: isLoadingStatusMutation }] =
    useUpdateWhistleBlowingStatusMutation()

  let [page] = useState({ page: tag.page ?? 0 })

  const { data: dataUsers } = useFetchUserTimsBlowingsQuery({
    page: page?.page,
    size: 999,
  })

  const usersTimOptions =
    dataUsers?.items?.length > 0
      ? dataUsers?.items
          ?.filter((user) => {
            if (isHaveRoleWhistleBlowing) {
              return true
            }
            return (
              user?.userId !== profile?.userId &&
              user?.userId !== row?.assignedUserId
            )
          })
          .map((user) => ({
            value: user.userId,
            label: user.name,
          }))
      : []

  const inputRef = useRef(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [notes, setNotes] = useState('')

  const { refetch } = useFetchWhistleBlowingsQuery({
    page: tag?.page ?? 0,
    size: 10,
    searchvalue: '',
    assigneduserid: isHaveRoleWhistleBlowing ? undefined : profile?.userId,
  })

  const resetState = () => {
    setSelectedUser(null)
    setNotes('')
  }

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    try {
      let assignedUserId = null

      if (statusType !== STATUS_TYPE.RESOLVED) {
        assignedUserId = selectedUser?.value
      }

      const res = await updateWhistleBlowingStatus({
        id: idWhistleBlowing,
        statusType,
        notes,
        assignedUserId,
      }).unwrap()

      if (!res.error) {
        dispatch(setIsOpenModalAssign())
        resetState()

        dispatch(
          setAlert({
            type: 'success',
            message: `Berhasil simpan!`,
          }),
        )

        refetch()

        navigate('/whistle-blowings')
      } else {
        dispatch(
          setAlert({
            type: 'danger',
            message: `Gagal simpan!`,
          }),
        )
      }
    } catch (error) {
      console.warn(error)
      dispatch(
        setAlert({
          type: 'danger',
          message: `Gagal simpan!`,
        }),
      )
    }
  }

  useEffect(() => {
    if (!alertMessage) {
      setTimeout(() => {
        dispatch(clearAlert())
      }, 3000)
    }
  }, [alertMessage, dispatch])

  // HANDLE EDIT
  useMemo(() => {
    if (row?.assignedUserId) {
      const userDataMapping = {
        value: row?.assignedUserId,
        label: row?.assigned?.name,
      }
      setSelectedUser(userDataMapping)
    }
  }, [row])

  // HANDLE AUTO FOCUS IN TEXT AREA
  useEffect(() => {
    if (statusType !== STATUS_TYPE.ASSIGNED && open) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 100)
    }
  }, [statusType, open])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={open}
      toggle={() => {
        toggle()
      }}
      autoFocus={false}
    >
      <ModalBody>
        {/* SHOW ALERT */}
        {alertMessage && (
          <CustomAlertMessage type={alertType} message={alertMessage} />
        )}

        {statusType !== STATUS_TYPE.RESOLVED && (
          <h6>Pilih user yang akan menindaklanjuti</h6>
        )}

        {statusType !== STATUS_TYPE.RESOLVED && (
          <FormGroup>
            <Label className="text-sm">Users</Label>
            <PMBSelect
              options={usersTimOptions}
              value={selectedUser}
              onChange={(selectedOptions) => setSelectedUser(selectedOptions)}
              placeholder="Pilih users"
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label className="text-sm">Note</Label>

          <div
            style={{
              width: '100%',
              border: '1px solid #d1d5db',
              borderRadius: '4px',
            }}
          >
            <Input
              innerRef={inputRef}
              style={{
                border: 'none',
                minHeight: 200,
                borderRadius: '4px',
                padding: '8px',
                boxSizing: 'border-box',
              }}
              value={notes}
              className="border-input"
              type="textarea"
              placeholder="Masukkan catatan..."
              onChange={(e) => {
                const value = e.target.value
                if (value.trim() !== '') {
                  setNotes(value)
                } else {
                  setNotes('')
                }
              }}
            />
          </div>
        </FormGroup>

        {statusType === STATUS_TYPE.RESOLVED && (
          <div className="mb-2 d-flex gap-2">
            <Info className="text-primary" size={30} />
            <span className="text-xs">
              Setelah disimpan, Anda tidak dapat melakukan assignment ulang.
              Status ini menandakan bahwa masalah telah terselesaikan
              sepenuhnya.
            </span>
          </div>
        )}

        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              toggle()
              resetState()
            }}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={
              isLoadingStatusMutation ||
              (statusType === STATUS_TYPE.RESOLVED && !notes) ||
              (statusType !== STATUS_TYPE.RESOLVED && (!selectedUser || !notes))
            }
            color="primary"
            block
            onClick={() => {
              handleSubmit()
            }}
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalAssign
