import ICUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import ICTalangin from 'assets/icons/uang-usaha.png'
import SidebarDetails from 'components/SidebarDetails'
import { useState } from 'react'
import Select from 'react-select'
import { Button, FormGroup, Label } from 'reactstrap'
import { setFetchOption } from '../dashboardSlice'
import { useDispatch } from 'react-redux'

const SidebarFilter = ({ open, toggle, handleFilter }) => {
  const dispatch = useDispatch()

  const paymentOptions = [
    {
      value: '',
      label: 'Semua',
    },
    {
      value: 'direct',
      label: (
        <div className="d-flex align-items-center gap-2">
          <img width={20} height={20} src={ICUangBelanja} alt="Uang Belanja" />{' '}
          Uang Belanja
        </div>
      ),
    },
    {
      value: 'talangan',
      label: (
        <div className="d-flex align-items-center gap-2">
          <img width={20} height={20} src={ICTalangin} alt="Talangin" />{' '}
          Talangin
        </div>
      ),
    },
  ]

  const statusOptions = [
    {
      value: '',
      label: 'Semua',
    },
    {
      value: 'Dibatalkan',
      label: 'Dibatalkan',
    },
    {
      value: 'Diproses',
      label: 'Diproses',
    },
    {
      value: 'Menunggu Approval Admin',
      label: 'Menunggu Approval Admin',
    },
    {
      value: 'Menunggu Proses Penjual',
      label: 'Menunggu Proses Penjual',
    },
    {
      value: 'Menunggu Pembayaran Pembeli',
      label: 'Menunggu Pembayaran Pembeli',
    },
    {
      value: 'Selesai',
      label: 'Selesai',
    },
  ]

  const [selectedPayment, setSelectedPayment] = useState(paymentOptions[0])
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0])

  const handleReset = () => {
    setSelectedPayment(paymentOptions[0])
    setSelectedStatus(statusOptions[0])
    dispatch(
      setFetchOption({
        page: 0,
        status: null,
        payment: null
      }),
    )
    handleFilter()
    toggle()
  }

  const handleSubmit = () => {
    handleFilter()
    dispatch(
      setFetchOption({
        ...(selectedPayment?.value && {
          payment: selectedPayment?.value,
        }),
        ...(selectedStatus?.value && {
          status: selectedStatus?.value,
        }),
        page: 0,
      }),
    )
    toggle()
  }

  return (
    <>
      <SidebarDetails
        open={open}
        title="Filter"
        size="lg"
        headerClassName="mb-1"
        contentClassName="py-0"
        toggleSidebar={toggle}
      >
        <div
          style={{ minHeight: '85vh' }}
          className="d-flex flex-column justify-content-between"
        >
          <div>
            <FormGroup>
              <Label>Status</Label>
              <Select
                options={statusOptions}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Metode Pembayaran</Label>
              <Select
                options={paymentOptions}
                value={selectedPayment}
                onChange={(e) => setSelectedPayment(e)}
              />
            </FormGroup>
          </div>
          <div className="d-flex gap-2">
            <Button
              block
              outline
              color="secondary"
              onClick={() => {
                handleReset()
              }}
            >
              Reset
            </Button>
            <Button
              block
              color="primary"
              onClick={() => {
                handleSubmit()
              }}
            >
              Terapkan
            </Button>
          </div>
        </div>
      </SidebarDetails>
    </>
  )
}

export default SidebarFilter
