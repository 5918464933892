import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  payload: {},
  urlImageVessel: '',
  isOpenModalDeleteFile: false,
}

export const formFisheriesSlice = createAppSlice({
  name: 'fisheriesForm',
  initialState,
  reducers: (create) => ({
    setPayloadSlice: create.reducer((state, { payload }) => {
      state.payload = payload
    }),
    clearState: create.reducer((state) => {
      state.payload = {}
    }),
  }),
})

export const { setPayloadSlice, clearState } = formFisheriesSlice.actions

export default formFisheriesSlice.reducer
