import warningRed from 'assets/icons/warning-red.svg'
import { Button, CardText, CardTitle, Modal } from 'reactstrap'

const ModalRemove = ({ isOpen, onClose, onRemoveData, data, isLoading }) => {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="regular-padding-horizontal small-scrollable-container small-padding-vertical">
        <img
          src={warningRed}
          style={{ width: 50, height: 50, marginTop: 10, marginBottom: 10 }}
          className="button small-margin-right"
        />
        <CardTitle className="fw-semibold">
          Apakah Anda yakin ingin menghapus perusahaan {data?.name}?
        </CardTitle>
        <CardText className="mt-2" style={{ color: '#475467' }}>
          Data yang sudah dihapus tidak dapat dikembalikan.
        </CardText>
      </div>

      <div className="d-flex gap-3 m-4">
        <Button onClick={onClose} color="primary" outline block>
          Batal
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => onRemoveData(data?.id)}
          color="primary"
          block
        >
          Hapus
        </Button>
      </div>
    </Modal>
  )
}

export default ModalRemove
