import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  isOpenSidebarFilter: false,
  isOpenModalApprove: false,
  isOpenModalEdit: false,
}

export const catchesListSlice = createAppSlice({
  name: 'catchesList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size ?? 10
      state.tag.page = payload.page
    }),
    setToggleSidebarFilter: create.reducer((state, { payload }) => {
      state.isOpenSidebarFilter = !state.isOpenSidebarFilter
    }),
    setIsOpenModalApprove: create.reducer((state, { payload }) => {
      state.isOpenModalApprove = !state.isOpenModalApprove
    }),
    setIsOpenModalEdit: create.reducer((state, { payload }) => {
      state.isOpenModalEdit = !state.isOpenModalEdit
    }),
  }),
})

export const {
  setFetchOption,
  setToggleSidebarFilter,
  setIsOpenModalApprove,
  setIsOpenModalEdit,
} = catchesListSlice.actions

export default catchesListSlice.reducer
