import moment from 'moment-timezone'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { useFetchCatchesListQuery } from '../list-catches/catchesListApiSlice'
import {
  setFetchOption,
  setIsOpenModalEdit,
} from '../list-catches/catchesListSlice'
import { useUpdateFishByIdMutation } from './detailCatchesApiSlice'

const ModalEdit = ({ open, row, toggle, id }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [updateFishItem, resUpdateFishItem] = useUpdateFishByIdMutation()

  const inputRef = useRef(null)

  const [notes, setNotes] = useState('')
  const [payload, setPayload] = useState({})

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)
  const [selectedDate] = useState([startDateDefault, endDateDefault])

  const { refetch } = useFetchCatchesListQuery({
    page: 0,
    size: 10,
    s: undefined,
    from: moment(selectedDate[0]).format('YYYY-MM-DD') ?? '',
    to: moment(selectedDate[1])?.format('YYYY-MM-DD') ?? '',
    filterwpp: undefined,
    filterapprove: undefined,
    filterdistrict: undefined,
  })

  const resetState = () => {
    setPayload({
      id: row?.id,
      amount: Number(row?.amount ?? 0),
      notes: row?.notes ?? '',
    })
  }

  const { enqueueSnackbar } = useSnackbar()

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    try {
      const { data } = await updateFishItem({ payload, id }).unwrap()

      if (data) {
        dispatch(setFetchOption({ page: 0, size: 10 }))
        dispatch(setIsOpenModalEdit())

        resetState()

        enqueueSnackbar('Berhasil ubah data!', { variant: 'success' })

        setTimeout(() => {
          refetch()
        }, 0)

        navigate('/catches')
      } else {
        enqueueSnackbar('Gagah ubah data!', { variant: 'error' })
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar('Gagal ubah data!', { variant: 'error' })
    }
  }

  // HANDLE AUTO FOCUS IN TEXT AREA
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 100)
    }
  }, [open])

  useMemo(() => {
    if (row) {
      setPayload(() => ({
        id: row?.id,
        amount: Number(row?.amount) ?? 0,
        notes: '',
      }))
    }
  }, [row])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={open}
      toggle={() => {
        toggle()
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h6>Ubah Informasi Tangkapan</h6>
        <FormGroup>
          <Label className="text-sm mt-2">Kode Tangkapan</Label>
          <Input disabled value={row?.id ?? null} />
        </FormGroup>

        <FormGroup>
          <Label className="text-sm">Berat Tangkapan</Label>
          <Input
            min={0}
            placeholder="Masukkan berat tangkapan..."
            autoComplete="off"
            name="amount"
            id="amount"
            onChange={(e) => {
              const value = e.target.value
              if (!isNaN(value)) {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: value,
                }))
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '+' ||
                e.key === '-'
              ) {
                e.preventDefault()
              }
            }}
            value={payload?.amount ?? 0}
            invalid={!payload?.amount || Number(payload?.amount) === 0}
          />
        </FormGroup>

        <FormGroup>
          <Label className="text-sm mt-2">Note (opsional)</Label>

          <div
            style={{
              width: '100%',
              border: '1px solid #d1d5db',
              borderRadius: '4px',
            }}
          >
            <Input
              innerRef={inputRef}
              style={{
                border: 'none',
                minHeight: 128,
                borderRadius: '4px',
                padding: '8px',
                boxSizing: 'border-box',
              }}
              name="notes"
              id="notes"
              className="border-input"
              type="textarea"
              placeholder="Masukkan catatan perubahan..."
              onChange={(e) => {
                const value = e.target.value
                if (value.trim() !== '') {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: value,
                  }))
                } else {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: value,
                  }))
                }
              }}
            />
          </div>
        </FormGroup>

        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              toggle()
              resetState()
            }}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={
              resUpdateFishItem?.isLoading ||
              !payload?.amount ||
              Number(payload?.amount) === 0 ||
              Number(payload?.amount) === Number(row?.amount)
            }
            color="primary"
            block
            onClick={handleSubmit}
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalEdit
