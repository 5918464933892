import AmountInput from 'components/AmountInput'
import { useEffect, useState } from 'react'
import { AlertCircle, ArrowLeft, Edit2, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import { ARUS_KAS } from 'utils/pathUrl'

import BreadCrumbs from 'components/breadcrumbs'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { getComodities, getUnitList } from './cashFlowAction'
import {
  useGetCategoriesCashFlowQuery,
  usePostCashFlowMutation,
} from './cashFlowApiSlice'
import {
  toggleBanner,
  toggleModalCategory,
  toggleModalDelete,
} from './cashFlowSlice'
import ModalCategory from './ModalCategory'
import ModalDelete from './ModalDelete'

const FormCashFlow = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const d = new Date()
  const today = new Date().toISOString().split('T')[0]

  const [payload, setPayload] = useState({
    unit: { value: 'Kg', label: 'Kg' },
    selectedDate: today,
  })
  const [amount, setAmount] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [price, setPrice] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [category, setCategory] = useState(null)
  const [isKomoditas, setIsKomoditas] = useState(false)

  const {
    units,
    comodities: listComodities,
    isOpenModalDelete,
    isOpenModalCategory,
  } = useSelector((s) => s.cashflow)
  const { ownerData } = useSelector((s) => s.dashboard)

  const [postCashFlow, result] = usePostCashFlowMutation()
  const { data: dataCategory, isLoading } = useGetCategoriesCashFlowQuery(
    {
      type: state?.income ? 'income' : 'expense',
      active: 1,
      userId: ownerData?.userId,
    },
    { skip: !ownerData },
  )

  const handleSubmit = async () => {
    try {
      let body = {
        amount:
          payload?.category?.label?.toLowerCase() === 'penjualan' ||
          payload?.category?.label?.toLowerCase() === 'pembelian'
            ? payload?.totalPrice
            : Number(
                amount
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/,/g, '.')
                  ?.slice?.(0, 15),
              ),
        label:
          payload?.category?.label?.toLowerCase() === 'penjualan' && isKomoditas
            ? `Penjualan ${payload?.comodity?.label}`
            : payload?.category?.label?.toLowerCase() === 'pembelian' &&
                isKomoditas
              ? `Pembelian ${payload?.comodity?.label}`
              : payload?.category?.label?.toLowerCase() === 'pembelian' &&
                  !isKomoditas
                ? `Pembelian ${payload?.label}`
                : payload?.category?.label?.toLowerCase() === 'penjualan' &&
                    !isKomoditas
                  ? `Pembelian ${payload?.label}`
                  : payload?.category?.label,
        notes: payload?.notes,
        picname: payload?.picname,
        offlineCustomTypeId: payload?.category?.id,
        incomeorexpense: state?.income ? 'income' : 'expense',
        type: payload?.category?.label,
        ...((payload?.category?.label?.toLowerCase() === 'penjualan' ||
          payload?.category?.label?.toLowerCase() === 'pembelian') && {
          items: [
            {
              ...(isKomoditas && {
                name: payload?.comodity?.label,
              }),
              qty: `${quantity} ${payload?.unit?.value}`,
            },
          ],
        }),
      }

      const resp = await postCashFlow(body).unwrap()
      if (!resp?.data?.error) {
        dispatch(toggleBanner())
        if (state?.income) {
          navigate(ARUS_KAS, { state: { income: true } })
        } else {
          navigate(ARUS_KAS, { state: { expense: true } })
        }
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  const handleEditCategory = () => {
    if (!isEdit) {
      setIsEdit(true)
      setPayload((prev) => {
        return {
          ...prev,
          category: null,
        }
      })
    } else {
      setIsEdit(false)
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [amount])

  useEffect(() => {
    if (quantity || price) {
      setPayload((prev) => {
        return {
          ...prev,
          totalPrice:
            quantity
              ?.replace?.(/\./g, '')
              ?.replace?.(/,/g, '.')
              ?.slice?.(0, 15) *
            price?.replace?.(/\./g, '')?.replace?.(/,/g, '.')?.slice?.(0, 15),
        }
      })
    }
  }, [quantity, price])

  useEffect(() => {
    if (
      payload?.category?.label?.toLowerCase() === 'penjualan' ||
      payload?.category?.label?.toLowerCase() === 'pembelian'
    ) {
      dispatch(getUnitList())
    }
    if (isKomoditas) {
      dispatch(getComodities())
    }
  }, [payload?.category])

  return (
    <>
      <ModalDelete
        isOpen={isOpenModalDelete}
        toggle={() => dispatch(toggleModalDelete())}
        row={category}
        isCategory
        isIncome={state?.income ?? false}
      />
      <ModalCategory
        isOpen={isOpenModalCategory}
        toggle={() => dispatch(toggleModalCategory())}
        row={category}
        isIncome={state?.income ?? false}
      />
      <Container className="custom-container d-flex flex-column gap-3 py-3">
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs
            breadCrumbActive={'Catat Arus Kas'}
            breadCrumbParentLink={'/catatan-arus-kas'}
            breadCrumbParent={'Arus Kas'}
          />
        </div>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">
              <ArrowLeft
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(ARUS_KAS)}
              />{' '}
              Catat {state?.income ? 'Pemasukan' : 'Pengeluaran'}
            </h5>

            <Label className="fw-500 d-flex justify-content-between mt-4">
              <span>
                Kategori <span className="text-danger">*</span>
              </span>
              <span
                onClick={() => handleEditCategory()}
                className={isEdit ? 'text-success' : 'text-primary'}
                style={{ cursor: 'pointer' }}
              >
                {isEdit ? 'Selesai' : 'Atur Kategori'}
              </span>
            </Label>
            {isLoading ? (
              <div className="flex-centered">
                {' '}
                <Spinner />{' '}
              </div>
            ) : (
              dataCategory?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="py-2"
                    onClick={() => {
                      if (!isEdit) {
                        setPayload((prev) => {
                          return {
                            ...prev,
                            category: e,
                          }
                        })
                      }
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          payload?.category?.id === e?.id ? '#ECFAFF' : '',
                      }}
                      className={`border rounded p-3 d-flex ${isEdit ? 'justify-content-between' : 'gap-2'} ${payload?.category?.id === e?.id ? 'border-primary' : ''}`}
                    >
                      {isEdit ? (
                        <></>
                      ) : (
                        <Input
                          type="radio"
                          id="category"
                          name="category"
                          value={payload?.category?.id ?? ''}
                          onChange={() => {
                            if (!isEdit) {
                              setPayload((prev) => {
                                return {
                                  ...prev,
                                  category: e,
                                }
                              })
                            }
                          }}
                          checked={payload?.category?.id === e?.id}
                          autoComplete="off"
                        />
                      )}
                      {e?.label}
                      {isEdit ? (
                        <div className="d-flex gap-3">
                          <Edit2
                            onClick={() => {
                              setCategory(e)
                              dispatch(toggleModalCategory())
                            }}
                            style={{ cursor: 'pointer' }}
                            size={16}
                          />
                          <Trash2
                            onClick={() => {
                              setCategory(e)
                              dispatch(toggleModalDelete())
                            }}
                            style={{ cursor: 'pointer' }}
                            size={16}
                            className="text-danger"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )
              })
            )}

            <hr></hr>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <FormGroup>
              <Label className="fw-500">
                {payload?.category?.label?.toLowerCase() === 'penjualan' ||
                payload?.category?.label?.toLowerCase() === 'pembelian'
                  ? state?.income
                    ? 'Nama pembeli'
                    : 'Nama penjual'
                  : state?.income
                    ? 'Pemasukan dari'
                    : 'Pengeluaran ke'}
              </Label>
              <Input
                id="picname"
                name="picname"
                placeholder={`Masukkan nama ${
                  payload?.category?.label?.toLowerCase() === 'penjualan' ||
                  payload?.category?.label?.toLowerCase() === 'pembelian'
                    ? state?.income
                      ? 'pembeli'
                      : 'penjual'
                    : ''
                }`}
                value={payload?.picname ?? ''}
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target?.value,
                  }))
                  setErrMsg('')
                }}
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Label className="fw-500">Tanggal</Label>
              <Input
                id="selectedDate"
                type="date"
                name="selectedDate"
                placeholder="Pilih tanggal"
                value={payload?.selectedDate ?? ''}
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target?.value,
                  }))
                  setErrMsg('')
                }}
                autoComplete="off"
              />
            </FormGroup>

            {payload?.category?.label?.toLowerCase() === 'penjualan' ||
            payload?.category?.label?.toLowerCase() === 'pembelian' ? (
              <>
                <hr></hr>
                <FormGroup>
                  <Input
                    id="isKomoditas"
                    type="checkbox"
                    name="isKomoditas"
                    value={isKomoditas ?? ''}
                    onChange={(e) => {
                      setIsKomoditas(!isKomoditas)
                      setErrMsg('')
                    }}
                  />{' '}
                  {state?.income ? 'Penjualan' : 'Pembelian'} komoditas
                </FormGroup>

                {isKomoditas ? (
                  <FormGroup>
                    <Label className="fw-500">Komoditas</Label>
                    <Select
                      name="comodity"
                      placeholder="Pilih komoditas"
                      options={listComodities}
                      onChange={(e) => {
                        setPayload((prev) => ({
                          ...prev,
                          comodity: e,
                        }))
                        setErrMsg('')
                      }}
                      value={payload?.comodity ?? ''}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label className="fw-500">
                      Barang yang {state?.income ? 'dijual' : 'dibeli'}
                    </Label>
                    <Input
                      name="label"
                      onChange={(e) => {
                        setPayload((prev) => ({
                          ...prev,
                          [e.target.name]: e.target?.value,
                        }))
                      }}
                      value={payload?.label}
                      placeholder="Masukkan nama barang"
                    />
                  </FormGroup>
                )}

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="fw-500">Jumlah</Label>
                      <AmountInput
                        name="quantity"
                        value={quantity ?? ''}
                        cb={(res) => {
                          setQuantity(res)
                        }}
                        placeholder="0"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="fw-500">Satuan</Label>
                      <Select
                        options={units}
                        name="unit"
                        onChange={(e) => {
                          setPayload((prev) => ({
                            ...prev,
                            unit: e,
                          }))
                        }}
                        placeholder="Pilih satuan"
                        value={payload?.unit ?? ''}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="fw-500">Harga per satuan</Label>
                      <InputGroup>
                        <InputGroupText className="bg-transparent">
                          Rp
                        </InputGroupText>
                        <AmountInput
                          name="price"
                          value={price ?? ''}
                          cb={(res) => {
                            setPrice(res)
                          }}
                          placeholder="0"
                          dotOnly
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="fw-500">Harga Total</Label>
                      <InputGroup>
                        <InputGroupText className="bg-transparent">
                          Rp
                        </InputGroupText>
                        <Input
                          disabled={true}
                          type="text"
                          value={intl.formatNumber(
                            isNaN(payload?.totalPrice)
                              ? 0
                              : (payload?.totalPrice ?? 0),
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 3,
                            },
                          )}
                          onChange={() => {}}
                          name="totalPrice"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            {payload?.category?.label?.toLowerCase() !== 'penjualan' &&
            payload?.category?.label?.toLowerCase() !== 'pembelian' ? (
              <FormGroup>
                <Label className="fw-500">
                  Jumlah uang {state?.income ? 'masuk' : 'keluar'}
                </Label>

                <InputGroup>
                  <InputGroupText className="bg-transparent">Rp</InputGroupText>
                  <AmountInput
                    cb={(res) => {
                      setAmount(res)
                    }}
                    placeholder="0"
                  />
                </InputGroup>
              </FormGroup>
            ) : (
              <></>
            )}
            <FormGroup>
              <Label className="fw-500">Catatan (opsional)</Label>
              <Input
                type="textarea"
                style={{ minHeight: '128px' }}
                name="notes"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target?.value,
                  }))
                }}
                value={payload?.notes}
                placeholder="Tambah catatan jika ada..."
              />
            </FormGroup>
            {errMsg && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </FormGroup>
            )}

            <div className="d-flex gap-3">
              <Button
                onClick={() => {
                  if (state?.income) {
                    navigate(ARUS_KAS, { state: { income: true } })
                  } else {
                    navigate(ARUS_KAS, { state: { expense: true } })
                  }
                }}
                outline
                color="primary"
                block
              >
                Batal
              </Button>
              <Button
                disabled={
                  payload?.category?.label?.toLowerCase() === 'penjualan' ||
                  payload?.category?.label?.toLowerCase() === 'pembelian'
                    ? !payload ||
                      !payload?.category ||
                      !payload?.picname ||
                      !payload?.totalPrice ||
                      !price ||
                      !quantity ||
                      result?.isLoading
                    : !payload ||
                      !payload?.category ||
                      !payload?.picname ||
                      !amount ||
                      result?.isLoading
                }
                onClick={() => handleSubmit()}
                color="primary"
                block
              >
                Simpan
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default FormCashFlow
