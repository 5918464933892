import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'
import { API_GROUP_COUNT, API_GROUP_LIST } from 'utils/apiList'

const getGroupList = createAsyncThunk(
  'grouplist/list',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_GROUP_LIST}`,
        config,
      )
      return data.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getGroupCount = createAsyncThunk(
  'grouplist/count',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/${API_GROUP_COUNT}`,
        config,
      )
      return data.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getGroupList, getGroupCount }
