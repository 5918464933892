import {
  logout,
  setAlertMsg,
  toggleAlert,
} from 'features/public-views/login/loginSlice'
import PropTypes from 'prop-types'
import { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkSession } from 'utils/setSession'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const {
    token,
    session: s,
    alreadychangepass,
    userNotPartnership,
    serviceProvision,
  } = useSelector((s) => s.auth)
  const { isV3 } = useSelector((s) => s.dashboard)
  const dispatch = useDispatch()
  let session = checkSession(s)

  useEffect(() => {
    if (!session) {
      dispatch(logout())
    }
    if (token !== null && !session) {
      dispatch(logout())
      dispatch(toggleAlert(true))
      dispatch(
        setAlertMsg(
          'Sesi otomatis berakhir karena sudah melebihi batas waktu. Masuk kembali ke akun Anda untuk melanjutkan.',
        ),
      )
    }
  }, [session, dispatch, token])

  return (
    <AuthContext.Provider
      value={{
        token,
        session,
        alreadychangepass,
        userNotPartnership,
        serviceProvision,
        isV3,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
