import ICTopup from 'assets/icons/icon-topup.png'
import iconCard from 'assets/icons/red-wallet.png'
import CardAmount from 'assets/images/Card.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Container,
  Input,
} from 'reactstrap'
import { URL_406 } from 'utils/pathUrl'
import { useFetchSaldoQuery } from '../dashboard/dashboardApiSlice'
import ModalConfirmation from './ModalConfirmation'
import SectionPindahUangUsaha from './SectionPindahUangUsaha'
import {
  useFetchDisbursmentPricingQuery,
  useFetchWithdrawalQuery,
} from './withdrawApiSlice'
import { setSelectedPricing } from './withdrawSlice'
import BreadCrumbs from 'components/breadcrumbs'

const Withdraw = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const intl = useIntl()
  const dispatch = useDispatch()
  const { data } = useFetchDisbursmentPricingQuery()
  const { data: dataSaldo, isLoading: dataSaldoLoading } = useFetchSaldoQuery()
  const { data: dataWithdraw } = useFetchWithdrawalQuery()
  const { selectedPricing } = useSelector((e) => e.withdraw)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <>
      <ModalConfirmation isOpen={isOpenModal} toggle={toggleModal} />
      <Container
        className="custom-container pb-3"
        style={{ minHeight: '100vh' }}
      >
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs breadCrumbActive={'Uang usaha'} />
        </div>
        <div className="d-flex flex-column gap-3">
          {/* Card 1 */}
          <Card body style={{ position: 'relative' }}>
            <CardTitle tag="h6" className="fw-bold">
              <p>Uang Usaha</p>
            </CardTitle>
            <div
              style={{ position: 'relative', width: '100%', height: 'auto' }}
            >
              <img
                src={CardAmount}
                alt="Card Nominal"
                style={{ width: '100%', height: 'auto' }}
              />
              {dataSaldo && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                    textAlign: 'center',
                    color: 'white',
                    textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                  }}
                >
                  <small>Nominal</small>
                  <p className="fw-bold">
                    Rp
                    {intl.formatNumber(dataSaldo?.wallet2 ?? 0, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
              )}
            </div>
            <CardTitle tag="h6" className="fw-bold mt-4">
              <p>Pilih metode penarikan</p>
            </CardTitle>
            {data && (
              <div className="d-flex flex-column gap-3">
                {/* eslint-disable-next-line array-callback-return */}
                {data?.items?.map((e, i) => {
                  if (e?.fee > 0) {
                    return (
                      <div key={i}>
                        <Card
                          style={{
                            backgroundColor:
                              selectedPricing === e?.id
                                ? '#ECFAFF'
                                : e?.disable
                                  ? '#F9FAFB'
                                  : '',
                          }}
                          className={
                            selectedPricing === e?.id ? 'border-primary' : ''
                          }
                          onClick={() => {
                            if (!e?.disable) {
                              dispatch(setSelectedPricing(e?.id))
                            }
                          }}
                        >
                          <CardBody>
                            <div className="d-flex justify-content-between flex-row gap-2">
                              <div>
                                <span className="fw-bold">
                                  Penarikan {e?.title}
                                </span>
                                <p className="text-secondary fw-bold">
                                  Rp
                                  {intl.formatNumber(e?.fee, {
                                    useGrouping: 'always',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                                </p>
                                <p
                                  style={{
                                    color:
                                      selectedPricing === e?.id
                                        ? '#006386'
                                        : '',
                                  }}
                                >
                                  {e?.disable ? (
                                    <span style={{ color: '#B42318' }}>
                                      {e?.disableReason}
                                    </span>
                                  ) : (
                                    <span>{e.description}</span>
                                  )}
                                </p>
                              </div>
                              <Input
                                type="radio"
                                checked={selectedPricing === e?.id}
                                onChange={() => {
                                  if (!e?.disable) {
                                    dispatch(setSelectedPricing(e?.id))
                                  }
                                }}
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    )
                  }
                  return <></>
                })}
                <CustomButton
                  onClick={() => {
                    toggleModal()
                  }}
                  block
                  color="primary"
                  disabled={!selectedPricing}
                >
                  Ajukan Penarikan
                </CustomButton>
              </div>
            )}
          </Card>
          {/* End Card 1 */}
          {/* Card 2 */}
          <Card>
            <CardBody>
              <div
                onClick={() => {
                  toggle()
                }}
                className="d-flex justify-content-between"
              >
                <span className="fw-bold">Riwayat Penarikan</span>
                {isOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
            </CardBody>
            <Collapse isOpen={isOpen}>
              {dataWithdraw?.items.length > 1 ? (
                dataWithdraw?.items.map((e, i) => {
                  return (
                    <CardBody key={i}>
                      <p className="text-secondary fw-medium">
                        {moment(e?.createdAt).format('LL')}
                      </p>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: '16px' }}
                      >
                        <img
                          src={iconCard}
                          style={{ width: '39px', height: '39px' }}
                          alt="Wallet"
                        />
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex flex-column">
                            <span className="fw-bold">
                              Penarikan {e?.title}
                            </span>
                            <span className="text-secondary">{e?.number}</span>
                            <span className="text-secondary">
                              {moment(e?.createdAt).format('hh:mm')}
                            </span>
                          </div>
                          <div className="d-flex flex-column ">
                            <span className="text-danger fw-bold">
                              -
                              {intl.formatNumber(e?.amount, {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                            </span>
                            <Badge
                              size="sm"
                              className={`${e?.status === 'Selesai' ? 'badge-verify' : e?.status === 'Dalam Proses' ? 'badge-warning' : 'badge-cancel'}`}
                            >
                              {e?.status}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  )
                })
              ) : (
                <CardBody>
                  <div className="flex-centered flex-column gap-2">
                    <img
                      className="img-fluid"
                      style={{ width: '64px', height: '64px' }}
                      src={ICTopup}
                      alt="Wallet"
                    />
                    <p>Belum ada penarikan yang dilakukan</p>
                  </div>
                </CardBody>
              )}
            </Collapse>
          </Card>
          {/* End Card 2 */}
          {/* Card 3 */}
          <SectionPindahUangUsaha
            wallet2={dataSaldo?.wallet2}
            isLoading={dataSaldoLoading}
          />
          {/* End Card 3 */}
        </div>
      </Container>
    </>
  )
}

export default Withdraw
