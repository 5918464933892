import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { setBannerMsg, toggleBanner, toggleModal } from './groupsListSlice'
import { useCreateGroupMutation } from './groupListApiSlice'
import { getGroupCount, getGroupList } from './groupListAction'

const ModalCreateGroup = () => {
  const dispatch = useDispatch()
  const { isOpenModal } = useSelector((s) => s.groupsList)
  const [name, setName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [createGroup, result] = useCreateGroupMutation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await createGroup({ name: name }).unwrap()
      if (data?.data?.meta?.error) {
        throw data
      } else {
        dispatch(setBannerMsg('Grup berhasil dibuat.'))
        dispatch(toggleBanner())
        dispatch(toggleModal())
        dispatch(getGroupCount())
        dispatch(getGroupList())
      }
    } catch (error) {
      setErrMsg(error?.data?.meta?.message)
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      setName(null)
    }
  }, [isOpenModal])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal())
        setErrMsg('')
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h5>Buat grup</h5>
        <FormGroup>
          <Label>Nama grup</Label>
          <Input
            autoFocus={true}
            value={name ?? ''}
            onChange={(e) => {
              setName(e.target.value)
              setErrMsg('')
            }}
            placeholder="Contoh: Petani Jakarta Selatan"
          />
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              dispatch(toggleModal())
              setErrMsg('')
            }}
            disabled={result?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={result?.isLoading}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Buat Grup
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalCreateGroup
