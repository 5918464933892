import { createApiSlice } from 'app/createApiSlice'

export const learningApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'learning/hooks',
  endpoints(builder) {
    return {
      fetchLearning: builder.query({
        query() {
          return `/api/v1/bussines/tutorial`
        },
      }),
      fetchProgress: builder.query({
        query({ tutorialId, tutorialContentId }) {
          return {
            url: `/api/v1/bussines/tutorial-watch`,
            method: 'GET',
            params: {
              tutorialId,
              tutorialContentId,
            },
          }
        },
      }),
      postProgress: builder.mutation({
        query(payload) {
          return {
            url: `/api/v1/bussines/tutorial-watch`,
            method: 'POST',
            body: payload,
          }
        },
      }),
    }
  },
})

export const {
  useFetchLearningQuery,
  useFetchProgressQuery,
  useLazyFetchProgressQuery,
  usePostProgressMutation,
} = learningApiSlice
