import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { URL_FORGET_PASSWORD_STEP_2, URL_LOGIN } from 'utils/pathUrl'

import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { apiEndpoint, recaptchaKey } from 'configs'
import useRecaptchaV3 from 'hooks/useRecaptchaV3'
import { useState } from 'react'
import StepThreeForgetPassword from '../StepThree'

const StepOneForgetPassword = () => {
  const recaptcha = useRecaptchaV3(recaptchaKey)
  const nav = useNavigate()

  const [email, setEmail] = useState('')
  const [errorMessage, setErroMessage] = useState('')
  const [errorMessageApi, setErroMessageApi] = useState('')

  const [isInvalid, setIsInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { token } = useLoaderData()

  const handleSubmitCaptcha = async (e) => {
    e.preventDefault()
    if (!email) {
      setIsInvalid(true)
      return setErroMessage('Email belum diisi.')
    }

    try {
      setIsLoading(true)
      let keyRecaptha = await recaptcha()
      const res = await axios.post(
        `${apiEndpoint}/api/v1/bussines/public/forgotpassword`,
        {
          'g-recaptcha-response': keyRecaptha,
          email,
        },
      )
      if (res.status === 200) {
        nav(`${URL_FORGET_PASSWORD_STEP_2}`, { replace: true })
      }
    } catch (error) {
      setErroMessageApi(error.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (token) return <StepThreeForgetPassword token={token} />

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: '600px', width: '100%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Atur ulang password
            </CardTitle>
            <Form onSubmit={handleSubmitCaptcha}>
              {errorMessageApi && (
                <Alert color="danger" isOpen={errorMessageApi}>
                  <span className="text-xs">{errorMessageApi}</span>
                </Alert>
              )}

              <FormGroup>
                <Label>Email</Label>
                <Input
                  name="email"
                  type="email"
                  autoComplete="off"
                  value={email}
                  onChange={(e) => {
                    setErroMessage('')
                    setIsInvalid(false)
                    setErroMessageApi('')

                    setEmail(e.target.value)
                    if (e.target.value.length < 0) {
                      setIsInvalid(true)
                      setErroMessage('Email belum diisi.')
                    }
                  }}
                  invalid={isInvalid}
                />
              </FormGroup>

              {errorMessage && (
                <FormGroup>
                  <small className="text-danger">{errorMessage}</small>
                </FormGroup>
              )}
              <Link to={URL_LOGIN} style={{ textDecoration: 'none' }}>
                <h5 className="text-normal">Kembali Ke Halaman Login</h5>
              </Link>
              <CustomButton
                disabled={isLoading}
                block
                color="primary"
                type="submit"
                className="mb-2"
              >
                Lanjut
              </CustomButton>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default StepOneForgetPassword

export const loader = (props) => {
  const { request } = props
  let token =
    request.url?.split('?')[1]?.split('&')[0]?.split('=')[1] ?? undefined
  return { token }
}
