import { Input } from 'reactstrap'

const InputPhoneNumber = ({ placeholder, type, value, setValue, ...props }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value.trim()
    const num = inputValue.replace(/[^\d]/g, '')
    setValue(num)
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('Text').trim()

    const num = pastedData.replace(/[^\d]/g, '')

    setValue(num)
  }

  return (
    <Input
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={handleChange}
      onPaste={handlePaste}
      {...props}
    />
  )
}

export default InputPhoneNumber
