import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import LogoPasmik from 'assets/icons/PasarMIKRO-Primary-Logogram-3.svg'
import LogoNotFound from 'assets/icons/icons-expired.svg'

const Page404 = () => {
  return (
    <div
      className="flex-centered flex-column"
      style={{ height: '100vh', width: '100vw' }}
    >
      <img
        style={{ width: '100%', maxWidth: '80px', height: 'auto' }}
        src={LogoPasmik}
        alt="PasarMIKRO logo"
      />{' '}
      <div className="flex-centered gap-3 mt-4">
        <img
          style={{ width: '100%', maxWidth: '98px', height: 'auto' }}
          src={LogoNotFound}
          alt="PasarMIKRO logo"
        />{' '}
        <div className="d-flex flex-column w-75">
          <h2 className="fw-600">Halaman tidak bisa dibuka</h2>
          <span>
            Link halaman ini mungkin telah kedaluwarsa, dihapus, atau tidak
            disalin dengan benar.
          </span>
        </div>
      </div>
    </div>
  )
}
export default Page404
