import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { useLazyFetchFarmersDetailQuery } from '../farmers-list/farmersListApiSlice'
import ModalDeleteFile from './ModalDeleteFile'
import ModalUploadFile from './ModalUploadFile'
import { useEditDescriptionMutation } from './detailFarmersApiSlice'
import { toggleModalDeleteFile, toggleModalUpload } from './detailSlice'

const AboutFarmersCard = ({ row }) => {
  const dispatch = useDispatch()
  const { token } = useSelector((s) => s.auth)
  const [isLoading, setIsLoading] = useState(false)
  const [imageUrls, setImageUrls] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [desc, setDesc] = useState('')
  const [isFinishRemove, setIsFinishRemove] = useState(false)
  const [initialDesc, setInitialDesc] = useState(row?.description ?? '')
  const [fetchDetailFarmers] = useLazyFetchFarmersDetailQuery()
  const [editDesc, { isLoading: laodingEdit }] = useEditDescriptionMutation()

  const getSrc = async (data) => {
    try {
      setIsLoading(true)
      const multimediaArray = Array.isArray(data?.multimedia)
        ? data.multimedia
        : []

      const newImageUrls = []
      const newFileNames = []

      await Promise.all(
        multimediaArray.map(async (e) => {
          const response = await axios.get(`${e.link}?userId=${data?.userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
          })

          const fileExtension = e.link.split('.').pop().toLowerCase()
          const imageExtensions = ['jpg', 'jpeg', 'png']

          if (imageExtensions.includes(fileExtension)) {
            const imgUrl = URL.createObjectURL(response.data)
            newImageUrls.push({ link: imgUrl, id: e.id })
          } else {
            let fileName = 'unknown'
            const urlParts = e.link.split('/')
            fileName = urlParts[urlParts.length - 1]
            newFileNames.push({ name: fileName, id: e.id })
          }
        }),
      )

      setImageUrls(newImageUrls)
      setFileNames(newFileNames)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      setIsFinishRemove(false)
    }
  }

  const handleRemoveImage = (e) => {
    setSelectedFile(e)
    dispatch(toggleModalDeleteFile())
  }

  const handleEditDesc = async () => {
    if (desc !== initialDesc && desc) {
      try {
        const data = await editDesc({
          userId: row?.userId,
          description: desc,
        }).unwrap()
        if (!data?.error) {
          fetchDetailFarmers({ id: row?.userId })
        } else {
          throw data
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const closeButtonStyle = {
    position: 'absolute',
    top: '-8px',
    right: '-5px',
    zIndex: '10',
    backgroundColor: '#98A2B3',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }

  useEffect(() => {
    setInitialDesc(row?.description)
  }, [row])

  useEffect(() => {
    if (
      row?.multimedia?.length > 0 ||
      (isFinishRemove && row?.multimedia?.length > 0)
    ) {
      getSrc(row)
    } else {
      setImageUrls([])
      setFileNames([])
    }
  }, [row?.multimedia, isFinishRemove])

  return (
    <>
      <ModalUploadFile row={row} />
      <ModalDeleteFile
        row={row}
        selectedFile={selectedFile}
        setIsFinishRemove={setIsFinishRemove}
      />
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center">
            <h5>Tentang anggota ini</h5>
          </div>
          <div>
            <FormGroup>
              <Label className="fw-500">Deskripsi</Label>
              <Input
                type="textarea"
                style={{ height: '128px' }}
                placeholder="Cth: Anggota ini termasuk salah satu anggota unggulan dari desa Kintamani, Bali. Beliau berdedikasi tinggi dalam melakukan pekerjaannya untuk menghasilkan biji kopi yang berkualitas."
                defaultValue={initialDesc}
                onChange={(e) => setDesc(e.target.value)}
                onBlur={handleEditDesc}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <Label className="fw-500">Konten</Label>
                  <span>
                    Upload foto atau dokumen yang bisa menceritakan anggota ini
                  </span>
                </div>
                <CustomButton
                  onClick={() => dispatch(toggleModalUpload())}
                  color="primary"
                  outline
                >
                  Upload File
                </CustomButton>
              </div>
              <div className="d-flex flex-wrap gap-3 py-4">
                {imageUrls?.map((e, i) => (
                  <div key={i} style={{ position: 'relative', width: '210px' }}>
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {e?.link ? (
                          <>
                            {' '}
                            <CustomButton
                              style={closeButtonStyle}
                              onClick={() => handleRemoveImage(e)}
                            >
                              X
                            </CustomButton>
                          </>
                        ) : (
                          <></>
                        )}
                        <img
                          style={{ width: '100%', height: 'auto' }}
                          id={`img-${i}`}
                          className="img-fluid rounded"
                          src={e?.link}
                          alt={`Foto ${i + 1}`}
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="rounded border">
                {fileNames?.map((e, i) => (
                  <div
                    key={i}
                    className="border p-1 d-flex justify-content-between align-items-center"
                  >
                    <span>{e?.name}</span>{' '}
                    <X
                      onClick={() => handleRemoveImage(e)}
                      style={{ cursor: 'pointer' }}
                      size={16}
                      className="text-secondary"
                    />
                  </div>
                ))}
              </div>
            </FormGroup>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default AboutFarmersCard
