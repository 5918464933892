import CustomButton from 'components/Buttons/CustomButton'
import CustomPagination from 'components/Pagination/CustomPagination'
import { formatNumber } from 'helpers'
import { Box, MapPin } from 'react-feather'
import { Card, CardText } from 'reactstrap'
import RemoveStockCard from '../Card/RemoveStockCard'

export default function MergeStockModal({
  data,
  onSelectStock,
  currentPage,
  totalPage,
  onClickPagination,
  onShowHideModal,
  loading,
  selectedStock,
  selectedList,
}) {
  return (
    <>
      <Card className="mt-2">
        <div className="d-flex align-items-center regular-padding-horizontal mt-2 small-padding-bottom">
          <img
            src={selectedStock?.item?.linkFoto}
            style={{ width: 80, height: 80, borderRadius: 99 }}
          />
          <div className="pl-4">
            <CardText className="no-padding fw-semibold">
              {selectedStock?.item?.nameItem}
            </CardText>
            <div className="d-flex align-items-center mt-2">
              <Box size={15} />
              {selectedStock?.amount ? (
                <CardText
                  className="no-padding"
                  style={{ fontSize: 13, marginLeft: 10 }}
                >
                  {formatNumber(selectedStock?.amount)}{' '}
                  {selectedStock?.unit || selectedStock?.item?.unit}
                </CardText>
              ) : null}
            </div>
            {selectedStock?.city ||
            selectedStock?.district ||
            selectedStock?.province ? (
              <div className="d-flex align-items-center mt-2">
                <MapPin size={15} />
                <CardText className="pl-2" style={{ fontSize: 13 }}>
                  {selectedStock?.city ||
                    selectedStock?.district ||
                    selectedStock?.province}
                </CardText>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      <div>
        <CardText className="no-padding mt-3" style={{ fontSize: 14 }}>
          Gabungkan dengan
        </CardText>
      </div>
      {!selectedList?.length ? (
        <div>
          <CardText
            className="no-padding mt-3"
            style={{ fontSize: 14, color: '#B42318' }}
          >
            Pilih minimal 1 stok untuk digabungkan
          </CardText>
        </div>
      ) : null}

      <div className="mt-3">
        <div className="regular-scrollable-container">
          {data?.map((el, i) => {
            return (
              <div className={i > 0 ? 'mt-3' : null}>
                <RemoveStockCard
                  item={el}
                  onSelectStock={() => onSelectStock(el)}
                  selectedList={selectedList}
                />
              </div>
            )
          })}
        </div>
        <div className="mt-1 pt-1">
          <CustomPagination
            currentPage={currentPage}
            totalPage={totalPage}
            onClick={onClickPagination}
          />
        </div>

        <div className="mt-2">
          <CustomButton
            disabled={loading}
            onClick={() => {
              onShowHideModal('merge', false)
              onShowHideModal('addStock', true)
            }}
            style={{
              width: '100%',
              fontSize: 13,
              backgroundColor: '#006386',
              color: 'white',
            }}
            color="#F97066"
            className="fw-semibold"
          >
            Gabungkan Stok
          </CustomButton>
        </div>
      </div>
    </>
  )
}
