import moment from 'moment-timezone'
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'

const DetailModal = ({ row }) => {
  const {
    startFishing,
    endFishing,
    vessel,
    fishes,
    moveReceipment,
    isFishMove,
    totalAmount,
  } = row?.data || {}

  const formattedStartFishing = moment(startFishing).format(
    'DD MMMM YYYY, HH:mm',
  )
  const formattedEndFishing = moment(endFishing).format('DD MMMM YYYY, HH:mm')

  const departureCoordinates =
    fishes?.[0]?.latitude && fishes?.[0]?.longitude
      ? `${fishes[0].latitude}, ${fishes[0].longitude}`
      : '-'

  const tripDuration = moment
    .duration(moment(endFishing).diff(moment(startFishing)))
    .humanize()

  const catchAreas =
    row?.data?.fishes?.length > 0
      ? [...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea))].join(
          ', ',
        )
      : '-'

  const totalTransferredAmount = isFishMove
    ? moveReceipment?.reduce((total, receip) => {
        return (
          total +
          receip.fishes.reduce((subTotal, fish) => subTotal + fish.amount, 0)
        )
      }, 0)
    : 0

  const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'

  return (
    <>
      <div>
        {/* CARD INFO */}
        <Card
          className="basic-card p-3"
          style={{
            boxShadow: 'none',
          }}
        >
          <CardBody className="p-0">
            <CardText className="no-padding fw-semibold mb-3">
              Informasi perjalanan
            </CardText>

            <Row className="mb-2">
              <Col xs="6">Lama trip</Col>
              <Col xs="6" className="text-end text-muted">
                {tripDuration}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="6">Keberangkatan</Col>
              <Col xs="6" className="text-end text-muted">
                {formattedStartFishing}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="6">Berlabuh</Col>
              <Col xs="6" className="text-end text-muted">
                {formattedEndFishing}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="6">Koordinat Keberangkatan</Col>
              <Col xs="6" className="text-end text-muted">
                {departureCoordinates}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="6">Koordinat Berlabuh</Col>
              <Col xs="6" className="text-end text-muted">
                -
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="6">Port Berangkat</Col>
              <Col xs="6" className="text-end text-muted">
                -
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="6">Port Berlabuh</Col>
              <Col xs="6" className="text-end text-muted">
                -
              </Col>
            </Row>

            <Row>
              <Col xs="6">WPP</Col>
              <Col xs="6" className="text-end text-muted">
                {catchAreas}
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* CARD DATA KAPAL */}
        <Card
          className="basic-card mt-3"
          style={{
            boxShadow: 'none',
          }}
        >
          <CardText className="no-padding fw-semibold mb-3">
            Data Kapal
          </CardText>

          <Row className="mb-2">
            <Col xs="6">Nama Kapal</Col>
            <Col xs="6" className="text-end text-muted">
              {vessel?.shipName ?? '-'}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs="6">Kapten</Col>
            <Col xs="6" className="text-end text-muted">
              {vessel?.captain ?? '-'}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs="6">Nomor Kapal</Col>
            <Col xs="6" className="text-end text-muted">
              {vessel?.shipNumber ?? '-'}
            </Col>
          </Row>
        </Card>

        {/* RINCIAN HASIL TANGKAPAN */}
        <Card
          className="basic-card mt-3"
          style={{
            boxShadow: 'none',
          }}
        >
          <CardText className="no-padding fw-semibold mb-3">
            Rincian Hasil Tangkapan
          </CardText>

          <Row className="mb-2">
            <Col xs="6">Total Hasil Tangkapan</Col>
            <Col xs="6" className="text-end text-muted">
              {`${totalAmount ?? 0} ${unit}`}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs="6">Total Dialih Muatkan</Col>
            <Col xs="6" className="text-end text-muted">
              {`${totalTransferredAmount ?? 0} ${unit}`}
            </Col>
          </Row>

          <hr />

          <Row className="mb-2">
            <Col className="fw-bold" xs="6">
              Total Berat Bersih
            </Col>
            <Col xs="6" className="text-end fw-bold">
              {isFishMove ? totalAmount + totalTransferredAmount : totalAmount}{' '}
              {unit}
            </Col>
          </Row>

          {/* LOOPING CARD FISHED */}
          <div>
            {/* Fish Details Section */}
            {fishes?.map((fish, index) => {
              const formattedFishingDate = moment(fish?.createdAt).format(
                'DD MMMM YYYY, HH:mm',
              )
              const { nameItem, attribute } = fish?.selectedFish || {}
              return (
                <Card
                  key={index}
                  className="basic-card mt-3"
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  <CardText className="no-padding fw-semibold mb-3">
                    {nameItem ?? '-'}
                  </CardText>

                  <Row className="mb-2">
                    <Col xs="6">Kode</Col>
                    <Col xs="6" className="text-end text-muted">
                      {fish.id ?? '-'}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs="6">FAO Code</Col>
                    <Col xs="6" className="text-end text-muted">
                      {attribute?.code ?? '-'}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs="6">Berat Bersih Tangkapan</Col>
                    <Col xs="6" className="text-end text-muted">
                      -
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs="6">Berat Tangkapan</Col>
                    <Col xs="6" className="text-end text-muted">
                      {`${fish.amount ?? 0} ${unit}`}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs="6">Berat Tangkapan Dialihkan</Col>
                    <Col xs="6" className="text-end text-muted">
                      -
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs="6">Waktu Pengangkapan</Col>
                    <Col xs="6" className="text-end text-muted">
                      {formattedFishingDate}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs="6">Koordinat Penangkapan</Col>
                    <Col xs="6" className="text-end text-muted">
                      {fish?.latitude && fish?.longitude
                        ? `${fish.latitude}, ${fish.longitude}`
                        : '-'}
                    </Col>
                  </Row>
                </Card>
              )
            })}
          </div>
        </Card>
      </div>
    </>
  )
}

export default DetailModal
