import { HistoryLine } from 'components'
import CustomPagination from 'components/Pagination/CustomPagination'
import BreadCrumbs from 'components/breadcrumbs'
import { getActionDescription, parseExtraData } from 'helpers'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Eye } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { getLogs } from '../API/ap2hiActions'
import { SidebarLogs } from '../Components'

export default function Logs() {
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    detail: false,
  })
  const [logsList, setLogsList] = useState([])
  const [selectedLog, setSelectedLog] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const [containerHeight, setContainerHeight] = useState('73vh')

  useEffect(() => {
    const updateHeight = () => {
      const newHeight = '73vh'
      setContainerHeight(newHeight)
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])
  useEffect(() => {
    setLoading(true)
    fetchLogs(currentPage, 10)
  }, [])

  const fetchLogs = (page, size, search, sortBy, asc) => {
    setLoading(true)
    dispatch(
      getLogs({
        page: page,
        search: search,
        sortBy: sortBy,
        asc: asc,
        size: size,
      }),
    )
      .then((res) => {
        const logs = res?.payload?.items?.map((item) => {
          if (item.extraData) {
            item.parsedExtraData = parseExtraData(item?.extraData)
          }
          return item
        })
        setTotalItems(res?.payload?.totalItems)
        setCurrentPage(res?.payload?.currentPage)
        setTotalPages(res?.payload?.totalPages)
        setLogsList(logs)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  const columns = [
    {
      name: 'Tanggal',
      width: '200px',
      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-column">
              <span className="fw-light" style={{ color: '#667085' }}>
                {moment(row?.createdAt).format('DD-MM-YYYY')}
              </span>
            </div>
          </>
        )
      },
    },
    {
      name: 'User',
      width: '300px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span>{row?.user?.name}</span>
          </div>
        )
      },
    },
    {
      name: 'Keterangan',
      cell: (row) => {
        return <span>{getActionDescription(row)}</span>
      },
    },
    {
      name: '',
      maxWidth: '5px',
      cell: (row) => {
        return (
          <>
            <Eye
              color="#98A2B3"
              className="button"
              onClick={() => {
                setModal({ detail: true })
                console.log(row, 'liatlagi')

                setSelectedLog(row)
              }}
            />
          </>
        )
      },
    },
  ]

  const handleRowsPerPageChange = (page = 0, newPerPage = 10) => {
    fetchLogs(page, newPerPage)
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Log History" />
      </div>

      <Card className="mb-3">
        <CardBody>
          <div>
            <h5 className="fw-semibold mb-3">Log History</h5>
          </div>
          <div
            // className="container-fluid"
            style={{
              height: containerHeight,
              overflowY: 'auto',
            }}
          >
            <div
              className="overflow-auto"
              style={{ maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}
            >
              {logsList.length === 0 ? (
                <p className="my-3 fw-semibold">
                  Tidak terdapat catatan yang tersedia.
                </p>
              ) : (
                <div className="mt-2 container-fluid">
                  <HistoryLine logs={logsList} showBorder={false} />
                </div>
              )}
              {loading && (
                <div className="mt-4 mb-4">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
          <CustomPagination
            currentPage={currentPage}
            totalPage={totalPages}
            onClick={(type) => {
              console.log(type, 'typenye')
              if (type === 'next') {
                fetchLogs(currentPage + 1, 10)
              } else {
                fetchLogs(currentPage - 1, 10)
              }
            }}
          />
        </CardBody>
      </Card>
      {renderModal()}
    </div>
  )

  function renderModal() {
    return (
      <SidebarLogs
        isOpen={modal?.detail}
        toggle={() => setModal({ detail: false })}
        logs={[selectedLog]}
      />
    )
  }
}
