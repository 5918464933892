import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const getDownload = createAsyncThunk(
  'talangin/download',
  async (api, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(api, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getTenors = createAsyncThunk(
  'talangin/getTenors',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/talangin/detail?userpinjamanId=${id}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getDetailRollover = createAsyncThunk(
  'talangin/detail/rollover',
  async ({ userpinjamanId }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/talangin/detail?userpinjamanId=${userpinjamanId}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const postRollover = createAsyncThunk(
  'talangin/postRollover',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v2/bussines/talangin/rollover`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// Export the functions
export { getDownload, getTenors, getDetailRollover, postRollover }
