import { createApiSlice } from 'app/createApiSlice'

export const dashboardApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'dashboard/hooks',
  endpoints(builder) {
    return {
      fetchSaldo: builder.query({
        query() {
          return `/api/v1/bussines/saldo`
        },
        transformErrorResponse({ data, status }) {
          return {
            data,
            status,
          }
        },
      }),
      fetchTransaction: builder.query({
        query(args) {
          const { type, size, page, status, payment } = args
          return {
            url: '/api/v2/bussines/transaction',
            params: { type, size: 10, page, status, payment },
          }
        },
      }),
      fetchProfile: builder.query({
        query() {
          return `/api/v1/bussines/profile`
        },
      }),
      fetchNotif: builder.query({
        query(page = 0) {
          return `/api/v1/bussines/notification?page=${page}`
        },
      }),
    }
  },
})

export const {
  useFetchSaldoQuery,
  useFetchTransactionQuery,
  useFetchProfileQuery,
  useFetchNotifQuery,
} = dashboardApiSlice
