import { HistoryLine } from 'components'
import SidebarDetails from 'components/SidebarDetails'
import { useEffect } from 'react'
import '../style.css'

const SidebarLogs = ({ row: data, isOpen, toggle, logs }) => {
  useEffect(() => {
    const modalElement = document.querySelector('.SidebarDetails')
    if (modalElement) {
      modalElement.style.overflowY = 'hidden'
    }

    return () => {
      if (modalElement) {
        modalElement.style.overflowY = ''
      }
    }
  }, [isOpen])

  return (
    <div>
      <SidebarDetails
        open={isOpen}
        title={`History`}
        size="lg"
        headerClassName="mb-4 justify-content-md-between"
        contentClassName="pt-0"
        toggleSidebar={toggle}
      >
        <HistoryLine logs={logs} />
      </SidebarDetails>
    </div>
  )
}

export default SidebarLogs
