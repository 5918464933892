import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
// Persist
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Api Slice
import { approvalApiSlice } from 'features/private-views/approval/approvalApiSlice'
import { cashFlowApiSlice } from 'features/private-views/cash-flow/cashFlowApiSlice'
import { commodityRepoApiSlice } from 'features/private-views/commodity-repo/commodityRepoApiSlice'
import { dashboardApiSlice } from 'features/private-views/dashboard/dashboardApiSlice'
import { checkFarmersApiSlice } from 'features/private-views/farmers-web/check-farmers/checkFarmerApiSlice'
import { detailFarmersApiSlice } from 'features/private-views/farmers-web/detail/detailFarmersApiSlice'
import { farmersListApiSlice } from 'features/private-views/farmers-web/farmers-list/farmersListApiSlice'
import { farmersPendingListSlice } from 'features/private-views/farmers-web/farmers-pending-list/farmersPendingSlice'
import { formFarmersApiSlice } from 'features/private-views/farmers-web/forms/formApiSlice'
import { payableNotesApiSlice } from 'features/private-views/payable-notes/payableNotesApiSlice'
import { purchaseCommodityApiSlice } from 'features/private-views/purchase-commodity/purchaseCommodityApiSlice'
import { receivableApiSlice } from 'features/private-views/receivable-notes/receivableNotesApiSlice'
import { requestTransactionApiSlice } from 'features/private-views/request-transaction/requestTransactionApiSlice'
import { sellCommodityApiSlice } from 'features/private-views/sell-commodity/sellCommodityApiSlice'
import { settingApiSlice } from 'features/private-views/settings/settingApiSlice'
import { talanginInvoiceApiSlice } from 'features/private-views/talangin-invoice/talanginInvoiceApiSlice'
import { topupApiSlice } from 'features/private-views/topup/topupApiSlice'
import { withdrawApiSlice } from 'features/private-views/withdraw/withdrawApiSlice'
import {
  publicApiDevSlice,
  publicApiSlice,
} from 'features/public-api/publicApiSlice'
import { connectAccountApiSlice } from 'features/public-views/connect-account/connectAccountApiSlice'
import { loginApiSlice } from 'features/public-views/login/loginApiSlice'

import { formVesselsApiSlice } from 'features/private-views/vessels/forms/formApiSlice'
import { vesselsListApiSlice } from 'features/private-views/vessels/list-vessels/vesselsListApiSlice'

import { formCompaniesApiSlice } from 'features/private-views/companies/forms/formApiSlice'
import { companiesListApiSlice } from 'features/private-views/companies/list-companies/companiesListApiSlice'

import { groupListApiSlice } from 'features/private-views/farmers-web/groups-list/groupListApiSlice'
import { formFisheriesApiSlice } from 'features/private-views/fisheries/forms/formApiSlice'
import { fisheriesListApiSlice } from 'features/private-views/fisheries/list-fisheries/fisheriesListApiSlice'
import { learningApiSlice } from 'features/private-views/learning/learningApiSlice'
import { loginNewApiSlice } from 'features/public-views/login-new/loginNewApiSlice'

// Reducers
import approvalSlice from 'features/private-views/approval/approvalSlice'
import cashFlowSlice from 'features/private-views/cash-flow/cashFlowSlice'
import dashboardReducer from 'features/private-views/dashboard/dashboardSlice'
import detailFarmersSlice from 'features/private-views/farmers-web/detail/detailSlice'
import farmersListSlice from 'features/private-views/farmers-web/farmers-list/farmersListSlice'
import formFarmersSlice from 'features/private-views/farmers-web/forms/formSlice'
import groupsListSlice from 'features/private-views/farmers-web/groups-list/groupsListSlice'
import staffListSlice from 'features/private-views/farmers-web/staffList/staffListSlice'
import payableNotesSlice from 'features/private-views/payable-notes/payableNotesSlice'
import purchaseCommodityReducer from 'features/private-views/purchase-commodity/purchaseCommoditySlice'
import receivableNotesSlice from 'features/private-views/receivable-notes/receivableNotesSlice'
import sellCommodityReducer from 'features/private-views/sell-commodity/sellCommoditySlice'
import settingSlice from 'features/private-views/settings/settingSlice'
import talanginInvoiceSlice from 'features/private-views/talangin-invoice/talanginInvoiceSlice'
import talanginTable from 'features/private-views/talangin-invoice/talanginSlice'
import tncSlice from 'features/private-views/tnc/tncSlice'
import topupSlice from 'features/private-views/topup/topupSlice'
import withdrawSlice from 'features/private-views/withdraw/withdrawSlice'
import connectAccountSlice from 'features/public-views/connect-account/connectAccountSlice'
import loginReducer from 'features/public-views/login/loginSlice'
import requestTransactionReducer from 'features/public-views/order/requestTransactionSlice'
import commodityPurchaseSlice from 'private-views/transactions/commodityPurchaseSlice'

// VESSELS
import detailVesselsSlice from 'features/private-views/vessels/detail/detailSlice'
import formVesselsSlice from 'features/private-views/vessels/forms/formSlice'
import vesselsListSlice from 'features/private-views/vessels/list-vessels/vesselsListSlice'

// CATCHES
import detailCatchesSlice from 'features/private-views/catches/detail/detailSlice'
import { catchesListApiSlice } from 'features/private-views/catches/list-catches/catchesListApiSlice'
import catchesListSlice from 'features/private-views/catches/list-catches/catchesListSlice'

// COMPANIES
import formCompaniesSlice from 'features/private-views/companies/forms/formSlice'
import companiesListSlice from 'features/private-views/companies/list-companies/companiesListSlice'

// FISHERIES
import formFisheriesSlice from 'features/private-views/fisheries/forms/formSlice'
import fisheriesListSlice from 'features/private-views/fisheries/list-fisheries/fisheriesListSlice'

// WHISTLE BLOWINGS
import { whistleBlowingsListApiSlice } from 'features/private-views/whistle-blowings/list-whistle-blowings/whistleBlowingsListApiSlice'
import whistleBlowingsListSlice from 'features/private-views/whistle-blowings/list-whistle-blowings/whistleBlowingsListSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'dashboard'],
}

const rootReducer = combineReducers({
  auth: loginReducer,
  [loginApiSlice.reducerPath]: loginApiSlice.reducer,
  dashboard: dashboardReducer,
  [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
  withdraw: withdrawSlice,
  [withdrawApiSlice.reducerPath]: withdrawApiSlice.reducer,
  talanginTable: talanginTable,
  setting: settingSlice,
  topup: topupSlice,

  farmersList: farmersListSlice,
  [farmersListApiSlice.reducerPath]: farmersListApiSlice.reducer,
  farmersDetail: detailFarmersSlice,
  farmersForm: formFarmersSlice,
  [formFarmersApiSlice.reducerPath]: formFarmersApiSlice.reducer,
  [checkFarmersApiSlice.reducerPath]: checkFarmersApiSlice.reducer,
  farmersPending: farmersPendingListSlice,
  cashflow: cashFlowSlice,
  [detailFarmersApiSlice.reducerPath]: detailFarmersApiSlice.reducer,
  groupsList: groupsListSlice,
  staffList: staffListSlice,
  [groupListApiSlice.reducerPath]: groupListApiSlice.reducer,

  // VESSELS
  vesselsList: vesselsListSlice,
  [vesselsListApiSlice.reducerPath]: vesselsListApiSlice.reducer,
  vesselsForm: formVesselsSlice,
  [formVesselsApiSlice.reducerPath]: formVesselsApiSlice.reducer,
  vesselsDetail: detailVesselsSlice,

  // CATCHES
  catchesList: catchesListSlice,
  [catchesListApiSlice.reducerPath]: catchesListApiSlice.reducer,
  catchesDetail: detailCatchesSlice,

  // COMPANIES
  companiesList: companiesListSlice,
  [companiesListApiSlice.reducerPath]: companiesListApiSlice.reducer,
  companiesForm: formCompaniesSlice,
  [formCompaniesApiSlice.reducerPath]: formCompaniesApiSlice.reducer,

  // FISHERIES
  fisheriesList: fisheriesListSlice,
  [fisheriesListApiSlice.reducerPath]: fisheriesListApiSlice.reducer,
  fisheriesForm: formFisheriesSlice,
  [formFisheriesApiSlice.reducerPath]: formFisheriesApiSlice.reducer,

  // WHISTLE BLOWINGS
  whistleBlowingsList: whistleBlowingsListSlice,
  [whistleBlowingsListApiSlice.reducerPath]:
    whistleBlowingsListApiSlice.reducer,

  purchaseCommmodity: purchaseCommodityReducer,
  approval: approvalSlice,
  talanginInvoice: talanginInvoiceSlice,
  sellCommodity: sellCommodityReducer,
  reqTrxSell: requestTransactionReducer,
  comodityPurchase: commodityPurchaseSlice,
  payableNotes: payableNotesSlice,
  receivableNotes: receivableNotesSlice,
  [topupApiSlice.reducerPath]: topupApiSlice.reducer,
  [settingApiSlice.reducerPath]: settingApiSlice.reducer,
  [topupApiSlice.reducerPath]: topupApiSlice.reducer,
  [talanginInvoiceApiSlice.reducerPath]: talanginInvoiceApiSlice.reducer,
  [settingApiSlice.reducerPath]: settingApiSlice.reducer,
  [sellCommodityApiSlice.reducerPath]: sellCommodityApiSlice.reducer,
  [settingApiSlice.reducerPath]: settingApiSlice.reducer,
  [purchaseCommodityApiSlice.reducerPath]: purchaseCommodityApiSlice.reducer,
  [publicApiDevSlice.reducerPath]: publicApiDevSlice.reducer,
  [publicApiSlice.reducerPath]: publicApiSlice.reducer,
  [approvalApiSlice.reducerPath]: approvalApiSlice.reducer,
  [requestTransactionApiSlice.reducerPath]: requestTransactionApiSlice.reducer,
  [commodityRepoApiSlice.reducerPath]: commodityRepoApiSlice.reducer,
  [cashFlowApiSlice.reducerPath]: cashFlowApiSlice.reducer,
  [payableNotesApiSlice.reducerPath]: payableNotesApiSlice.reducer,
  [receivableApiSlice.reducerPath]: receivableApiSlice.reducer,
  connectAccount: connectAccountSlice,
  [connectAccountApiSlice.reducerPath]: connectAccountApiSlice.reducer,
  tnc: tncSlice,
  [loginNewApiSlice.reducerPath]: loginNewApiSlice.reducer,
  [learningApiSlice.reducerPath]: learningApiSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        topupApiSlice.middleware,
        settingApiSlice.middleware,
        purchaseCommodityApiSlice.middleware,
        publicApiDevSlice.middleware,
        publicApiSlice.middleware,
        approvalApiSlice.middleware,
        withdrawApiSlice.middleware,
        talanginInvoiceApiSlice.middleware,
        sellCommodityApiSlice.middleware,
        requestTransactionApiSlice.middleware,
        dashboardApiSlice.middleware,
        loginApiSlice.middleware,
        farmersListApiSlice.middleware,
        formFarmersApiSlice.middleware,
        checkFarmersApiSlice.middleware,
        commodityRepoApiSlice.middleware,
        cashFlowApiSlice.middleware,
        payableNotesApiSlice.middleware,
        receivableApiSlice.middleware,
        detailFarmersApiSlice.middleware,
        payableNotesApiSlice.middleware,
        receivableApiSlice.middleware,
        groupListApiSlice.middleware,
        vesselsListApiSlice.middleware,
        formVesselsApiSlice.middleware,
        loginNewApiSlice.middleware,
        catchesListApiSlice.middleware,
        companiesListApiSlice.middleware,
        formCompaniesApiSlice.middleware,
        fisheriesListApiSlice.middleware,
        formFisheriesApiSlice.middleware,
        learningApiSlice.middleware,
        whistleBlowingsListApiSlice.middleware,
        learningApiSlice.middleware,
        whistleBlowingsListApiSlice.middleware,
      )
    },
    preloadedState,
  })
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()
export let persistor = persistStore(store)
